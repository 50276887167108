import React  from 'react';
import {Column, Content, Grid, Row} from "@carbon/react";
import BaseComponent from "../../util/BaseComponent"


import {Link} from "react-router-dom";


class Unknown extends BaseComponent {

    constructor(props) {
        super(props);

    }


    componentDidMount() {

    }


    render() {


        return (
            <>
                <div className="contentHeader">
                    <h1>Unknown Page</h1>
                    <p>You've reached an unknown page. Try <Link to = "/matrix">Matrix</Link></p>
                </div>


                <Content>
                    <Grid>

                        <Row>
                            <Column>
                                <div className="contentInstruction">

                                </div>
                            </Column>
                        </Row>

                    </Grid>
               </Content>
            </>
        );
    }
}


export default function (props) {

 //   const [notification, setNotification] = useRecoilState(notificationDefinition);
    return <Unknown {...props} />;
}

