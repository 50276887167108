import React, { Component } from 'react'

const BaseContext = React.createContext({});

/**
 * Higher order component to retain our basic state, typically just error messages and the current form contents
 */
class ParamsProvider extends Component {

    state = {
        applicationFormId: null,
        targetCourse: null,
        hasFormLoaded: false,
        showCourseHeader: false,
        hasApplied: false,
        formData: {},
        }

        setApplicationFormId = (applicationFormId) => {
            this.setState({applicationFormId: applicationFormId})
        }

        setTargetCourse = (targetCourse) => {
            this.setState({targetCourse: targetCourse})
        }

         setFormData = (formData) => {
            this.setState({formData: formData});
        }

        setHasFormLoaded = (hasFormLoaded) => {
            this.setState({hasFormLoaded: hasFormLoaded});
        }

        setShowCourseHeader = (showCourseHeader) => {
            this.setState({showCourseHeader: showCourseHeader});
        }

        setHasApplied = (hasApplied) => {
            this.setState({hasApplied: hasApplied});
        }




    render() {
        const { children } = this.props

        return (
            <BaseContext.Provider
                value={{
                    applicationFormId: this.state.applicationFormId,
                    setApplicationFormId: this.setApplicationFormId,

                    targetCourse: this.state.targetCourse,
                    setTargetCourse: this.setTargetCourse,

                    formData: this.state.formData,
                    setFormData: this.setFormData,

                    hasFormLoaded: this.state.hasFormLoaded,
                    setHasFormLoaded: this.setHasFormLoaded,

                    showCourseHeader: this.state.showCourseHeader,
                    setShowCourseHeader: this.setShowCourseHeader,

                    hasApplied: this.state.hasApplied,
                    setHasApplied: this.setHasApplied,
                }}
            >
                {children}
            </BaseContext.Provider>
        )
    }
}

export default BaseContext

export { ParamsProvider }