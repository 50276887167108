import React from 'react';


import {useNavigate} from "react-router-dom";
import BaseComponent, {placementLocations} from "../../../../util/BaseComponent";
import {FieldArray, Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";


const validationSchema = {};


const placementDefaultSchema = {
    placementLocation: "",

    reqUpperLimb: false,
    reqLowerLimb: false,
    reqHip: false,
    reqSoftTissue: false,
    reqAbdominal: false,
    reqSuperficialStructures: false,
    reqFirstTrimester: false,
    reqSecondTrimester: false,
    reqGynaecology: false,
    reqGuidedInjections: false,
    reqNegotiatedSkill1: false,
    reqNegotiatedSkill2: false,
    negotiatedSkill1: "",
    negotiatedSkill2: "",


    selectedSkills: [],

    peTitle: "",
    peForename: "",
    peSurname: "",
    peEmail: "",
    peClinicName: "",
    peAddressLine1: "",
    peAddressLine2: "",
    peAddressLine3: "",
    peAddressLine4: "",
    pePostCode: "",
}


const defaultSchema = {
    clinicalPlacements: [
        placementDefaultSchema
    ]
}



class ClinicalPlacement extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        const initialValues = defaultSchema;

        this.state = {
            initialValues: initialValues,
            navigateHome: false,


            selectedKeys: [],


            isTransmitting: false,
        }
    }


    componentDidMount() {
        //Parse our formDate and retrieve the items we're interested in

        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }



    processFormData() {

        let data = this.context.formData;

        let initialValues = {
            clinicalPlacements: data.clinicalPlacements
        }

        if (data.clinicalPlacements.length < 1) {
            initialValues = defaultSchema;
        }



        //We need to determine the available options for our dropdown
        let units = data.courseDetails.units;
        let ultrasoundOptions = [];

        //Iterate around the units to generate our list
        for (let i = 0; i < units.length; i++) {
            if (units[i].placement === false) {
                continue;
            } else {
                let element = {
                    key: units[i].officialUnitName,
                    text: units[i].officialUnitName,
                }
                ultrasoundOptions.push(element);
            }
        }

        this.setState({
            ultrasoundOptions: ultrasoundOptions,
            initialValues: initialValues,
        })
    }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitPlacement(values, this.context.applicationFormId)
            .then(
                (response) => {
                    this.getApplicationFormData(this.context.applicationFormId)

                    toast.success(
                        "Success: Type 2 Details Parsed"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }


    render() {


        const {isTransmitting, initialValues, navigateHome, ultrasoundOptions} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }


        return (
            <>
                <Formik
                    initialValues={initialValues}
                    //        validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (


                        <Form>


                            <h3 className="questionGroupTitle">Clinical Placement</h3>

                            <p className="informationText dropDownPadding">On the MSc Medical ultrasound course all students are required to undertake a clinical placement. Please supply details of your placement intentions below. You can select multiple institutions</p>


                            <FieldArray name={"clinicalPlacements"}>
                                {({push, remove}) => (
                                    <div>
                                        {
                                            values.clinicalPlacements.length > 0 &&
                                            values.clinicalPlacements.map((clinicalPlacement, index) => (
                                                <>


                            <div className="formPanel">
                                <StandardInput
                                    type="radio"
                                    title="Where do you intend to undertake your clinical placement?"
                                    description=""
                                    placeholder=""
                                    helpText=""
                                    fieldId={`clinicalPlacements[${index}].placementLocation`}
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disable={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={0}
                                    options={placementLocations}
                                />
                            </div>


                            <Gated display={clinicalPlacement.placementLocation === 'nonAECC'}>

                                <p className="informationText dropDownPadding">Please tell us who you practice educator will be and details of your placement.</p>

                                <h3 className="questionGroupTitle"> Your practice educator</h3>

                                <div className="formPanel">
                                    <div className="formsFlex">
                                        <StandardInput
                                            type="text"
                                            title="Title"
                                            description=""
                                            placeholder="mr/miss/ms"
                                            helpText=""
                                            fieldId={`clinicalPlacements[${index}].peTitle`}
                                            requiredVisual={false}
                                            flexRow={true}
                                            flexGrow={false}
                                            disabled={hasApplied}
                                            additionalClass="formsWidth1"
                                            multiline={false}
                                            rowCount={1}
                                            width={0}
                                        />

                                        <StandardInput
                                            type="text"
                                            title="Forename"
                                            description=""
                                            placeholder="first name"
                                            helpText=""
                                            fieldId={`clinicalPlacements[${index}].peForename`}
                                            requiredVisual={false}
                                            flexRow={true}
                                            flexGrow={true}
                                            disabled={hasApplied}
                                            additionalClass="paddingRightTemp"
                                            multiline={false}
                                            rowCount={1}
                                            width={0}
                                        />

                                        <StandardInput
                                            type="text"
                                            title="Surname"
                                            description=""
                                            placeholder="surname"
                                            helpText=""
                                            fieldId={`clinicalPlacements[${index}].peSurname`}
                                            requiredVisual={false}
                                            flexRow={true}
                                            flexGrow={true}
                                            disabled={hasApplied}
                                            additionalClass=""
                                            multiline={false}
                                            rowCount={1}
                                            width={0}
                                        />
                                    </div>


                                    <StandardInput
                                        type="text"
                                        title="Email"
                                        description="Your practice educators work email"
                                        placeholder="email"
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peEmail`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                </div>

                                <h3 className="questionGroupTitle">Your placement facility</h3>

                                <div className="formPanel">
                                    <StandardInput
                                        type="text"
                                        title="Clinic Name"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peClinicName`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                    <StandardInput
                                        type="text"
                                        title="Address Line 1"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peAddressLine1`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                    <StandardInput
                                        type="text"
                                        title="Address Line 2"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peAddressLine2`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                    <StandardInput
                                        type="text"
                                        title="Address Line 3"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peAddressLine3`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                    <StandardInput
                                        type="text"
                                        title="Address Line 4"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peAddressLine4`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                    <StandardInput
                                        type="text"
                                        title="Post code"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].pePostCode`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                </div>
                            </Gated>

























<Gated display={values.clinicalPlacements[index].placementLocation === "AECC" || values.clinicalPlacements[index].placementLocation === "nonAECC"} >


    <p className="informationText dropDownPadding">Please list all clinical applications for which you will require a supervised clinical placement for your first year of MSc studies. Please refer to the <a href = "https://www.hsu.ac.uk/study/our-courses/postgraduate-courses/msc-medical-ultrasound/">MSc Medical ultrasound course page</a> for details of clinical application units.</p>


                            <div className="formPanel">

                                {/*

                                {checkboxOptions.map(function (option) {
                                        return (<>
                                            <StandardInput
                                                type="checkbox"
                                                title={option.text}
                                                description=""
                                                placeholder=""
                                                helpText=""
                                                fieldId={option.key}
                                                requiredVisual={false}
                                                flexRow={false}
                                                flexGrow={false}
                                                disabled={hasApplied}
                                                additionalClass=""
                                                multiline={false}
                                                rowCount={1}
                                                width={0}
                                            />
                                        </>)
                                    }
                                )}

*/}
                                <StandardInput
                                    type = "combomulti"
                                    title = "Placement Options"
                                    description = ""
                                    placeholder = "select option(s)"
                                    helpText = ""
                                    fieldId={`clinicalPlacements[${index}].selectedSkills`}
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}

                                    rowCount = {1}
                                    width = {3}
                                    options={ultrasoundOptions}

                                    selectedKeys={this.props.selectedKeys}
                                    setSelectedKeys={this.props.setSelectedKeys}

                                />




                                <Gated display={values.clinicalPlacements[index].selectedSkills !== undefined && values.clinicalPlacements[index].selectedSkills.includes("Negotiated Ultrasound Skills")}>
                                    <StandardInput
                                        type="text"
                                        title="Negotiated skill 1"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].negotiatedSkill1`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass="formsWidth3"
                                        multiline={false}
                                        rowCount={1}
                                        width={0}

                                    />
                                </Gated>

                                {/*
                                <Gated display={values.reqNegotiatedSkill2 === true}>

                                    <StandardInput
                                        type="text"
                                        title="Negotiated skill 2"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId={`clinicalPlacements[${index}].peAddressLine2`}
                                        requiredVisual={false}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass="formsWidth3"
                                        multiline={false}
                                        rowCount={1}
                                        width={0}

                                    />


                                </Gated>
                                */}
                            </div>
                        </Gated>



                            {!hasApplied && (
                                <ButtonSet className = "addRemoveButtons">
                                    <button type="button" className="addButton"
                                            onClick={() => push({
                                                defaultSchema
                                            })}>&#10010;
                                    </button>
                                    {index > 0 && (
                                        <button type="button" className="removeButton"
                                                onClick={() => remove(index)}>&#10006;
                                        </button>
                                    )}

                                </ButtonSet>
                            )}



                                            </>
                                            ))}
                                    </div>
                                )}

                            </FieldArray>


                            <Gated display={hasApplied === false}>

                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary">Save page</Button>
                            </ButtonSet>
                            </Gated>

                        </Form>
                    )}
                </Formik>


            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = React.useState([]);


    return <ClinicalPlacement navigate={navigate} {...props} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />;
}

