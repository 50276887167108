import React  from 'react';

import BaseComponent from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import {Warning} from "@carbon/icons-react";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import * as Yup from "yup";
import Gated from "../../../../components/Gated";




const refereeTemplate = {
    refereeTitle: "",
    refereeForename: "",
    refereeSurname: "",
    refereeRelationship: "",
    refereeOccupation: "",
    refereeContactNo: "",
    refereeEmail: "",
}


const validationSchema = Yup.object().shape({
    refereeTitle: Yup.string().required('Required').nullable(),
    refereeForename: Yup.string().required('Please let us know your referees name').nullable(),
    refereeSurname: Yup.string().required('Please let us know your referees name').nullable(),
    refereeRelationship: Yup.string().required('Please describe your relation to this person').nullable(),
    refereeOccupation: Yup.string().required('Please let us know your referee\'s job title').nullable(),
    refereeContactNo: Yup.string().required('Please let us know how to contact this referee').nullable(),
    refereeEmail: Yup.string().email("Please check your email address is correct").required('Please let us know how to contact this referee').nullable(),
    })



class Reference extends BaseComponent {

    static contextType = BaseContext;
    constructor(props) {
        super(props);

        const initialValues = refereeTemplate;

        this.state = {
            initialValues: initialValues,
            isTransmitting: false,
            navigateHome: false,

        }


    }



    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }



    //Reference comes back as an object rather than individual keys
    processFormData() {
                    let data = this.context.formData;

                    let {initialValues} = this.state;

                    let referenceData = data.reference;

                    if (referenceData  !== null && typeof(referenceData) !== "undefined"  ) {
                        initialValues = {
                            refereeTitle: referenceData.title,
                            refereeForename: referenceData.firstName,
                            refereeSurname: referenceData.surname,
                            refereeRelationship: referenceData.relationship,
                            refereeOccupation: referenceData.occupation,
                            refereeContactNo: referenceData.telNo,
                            refereeEmail: referenceData.emailAddress,
                        }
                    }

                    this.setState({
                        initialValues: initialValues
                    })
    }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitReferee(values,this.context.applicationFormId)
            .then(
                (response) => {

                    this.getApplicationFormData(this.context.applicationFormId)

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }



    render() {


        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                <h3 className="questionGroupTitle">Reference</h3>
                            <p className = "informationText dropDownPadding">
                                Your referee should know you well enough to write about you and to indicate whether you are suitable for the programme that you are applying for.
                            </p>

                            <p className = "informationText dropDownPadding">
                                The referee will be sent an e-mail containing instructions to complete the reference electronically when you are ready.
                            </p>


                            <div className = "formPanel">
                            <div className="formsFlex">
                                <StandardInput
                                    type = "text"
                                    title = "Title"
                                    description = ""
                                    placeholder = "mr/miss/ms"
                                    helpText = ""
                                    fieldId = "refereeTitle"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = "formsWidth1"
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Forename"
                                    description = ""
                                    placeholder = "first name"
                                    helpText = ""
                                    fieldId = "refereeForename"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {true}
                                    disabled = {hasApplied}
                                    additionalClass = "paddingRightTemp"
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}

                                />

                                <StandardInput
                                    type = "text"
                                    title = "Surname"
                                    description = ""
                                    placeholder = "surname"
                                    helpText = ""
                                    fieldId = "refereeSurname"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {true}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}

                                />
                            </div>

                                <div className="highlightedFormQuestionHints">
                                    <p><Warning className="warningPosition"/>We will not accept references from family, other relatives or friends.</p>
                                </div>



                                <StandardInput
                                    type = "text"
                                    title = "Relationship"
                                    description = "Please describe the relationship you have with this referee"
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "refereeRelationship"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Referee's occupation"
                                    description = "Please describe the referee's occupation"
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "refereeOccupation"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />
                                <StandardInput
                                    type = "text"
                                    title = "Referee's contact number"
                                    description = "Referees contact number"
                                    placeholder = "+44 123456"
                                    helpText = ""
                                    fieldId = "refereeContactNo"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Referee's contact email"
                                    description = "Referee's contact email preferably a work address"
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "refereeEmail"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <Gated display={hasApplied === false}>
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                               </Gated>

                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <Reference navigate={navigate} {...props} />;
}

