import React from 'react';
import {
    Button,
    ButtonSet,
    Column,
    Content,
    Grid,
    InlineNotification,
    Row,
    TableExpandedRow,
    TableExpandRow
} from "@carbon/react";
import {DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell,TableExpandHeader} from "@carbon/react";


import CourseService from "../../util/CourseService";
import { useNavigate} from "react-router-dom";


import BaseComponent from "../../util/BaseComponent";

const courseHeaders = [

    {key: "id", header: "id", isSortable: false, display: true},
    {key: "courseName", header: "Name", isSortable: false, display: true},
    {key: "recruiting", header: "Recruiting", isSortable: false, display: true},
    {key: "requireDBS", header: "DBS check", isSortable: false, display: true},
    {key: "units", header: "Unit Count", isSortable: false, display: true},
    {key: "intakes", header: "Intake Count", isSortable: false, display: true},
 //   {key: "targetLink", header: "Target Link", isSortable: false, display: true},


];

/*
const notificationDefinition = atom({
    key: 'notification',
    default: [],
})
*/
/**
 * Test view of our available courses
 *
 */
class Matrix extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            //Is a retrieve operation in progress
            retrievingCourses: false,

            //The list of Courses
            courses: [],



            isSending: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //We want to load in the current list of courses available for recruitment

        this.generateCourseList("all");

   //     this.displayError("error","try this", "parsedError.message",this.props.notification, this.props.setNotification )
    }


    /**
     * Pull in the course list for the given filter value,
     * valid values all, recruiting, closed
     *
     * @param filter expected values all, recruiting, closed
     */
    generateCourseList(filter) {

        this.setState({retrievingCourses: true})

        //We're generating a new course list we can overwrite any current lists

        let courses = [];

        CourseService.getFullCourseList()
            .then(
                response => {
                    /*
                    Process the received response into a format suitable for our fluid components,
                    */

                    let courses = response.data;
                    this.setState( {
                        courses:courses
                    })

               //     console.log(courses);
                  }
            )
            .catch(
                error => {
                 //   console.log(error);
                    let parsedError = this.parseError(error)
                    this.setState({
                            displayNotification: true,
                            notificationTitle: parsedError.title,
                            notificationSubtitle: parsedError.message,
                            notificationKind: "error",
                        }
                    )
                 //   this.displayError("error",parsedError.title, parsedError.message,this.props.notification, this.props.setNotification )
                }
            )
            .finally(
                () => {
                    //Update the state with our current knowledge, we're not in a request and we have a course List

                    this.setState({
                        retrievingCourses: false,


                    })
                }
            )


    }


    applyTo(courseName) {
        sessionStorage.setItem("course", courseName);
        this.props.history.push("/");
        window.location.reload();

    }






    render() {

        const {courses} = this.state;
        const {displayNotification,notificationKind,notificationTitle,notificationSubtitle} = this.state;


              return (
            <>
                <div className="contentHeader">

                    <div className="constrainContentWidth">
                        <h1>Course Matrix</h1>
                         <p>Current courses</p>
                    </div>
                </div>


                <Content>
                    <Grid>
                        <Row>
                            <Column>
                                <div className="contentInstruction">
                                    <p>Select a course below. Typically these links would be embedded in an appropriate website page or email</p>
                                </div>
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <div>

                                    {displayNotification && (
                                        <InlineNotification
                                            kind={notificationKind}
                                            title={notificationTitle}
                                            subtitle={notificationSubtitle}
                                            onCloseButtonClick={() => {
                                                this.setState({
                                                    displayNotification: false,
                                                    notificationTitle: "",
                                                    notificationSubtitle: ""
                                                })
                                            }}
                                        />
                                    )}

                                    <DataTable
                                        isSortable
                                        rows={courses}
                                        headers={courseHeaders}
                                        overflowMenuOnHover={false}
                                    >
                                        {({
                                              rows,
                                              headers,
                                              getHeaderProps,
                                              getRowProps,
                                              getTableProps,
                                              getTableContainerProps,
                                          }) => (
                                            <TableContainer
                                                // title="Active applications"
                                                // description="List of active applications"

                                                {...getTableContainerProps()}>

                                                <Table  {...getTableProps()}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableExpandHeader/>
                                                            {headers.map((header, i) => (
                                                                <>

                                                                    <TableHeader {...getHeaderProps({
                                                                        header,
                                                                        key: i,
                                                                        isSortable: header.isSortable,
                                                                        colSpan: header.colSpan
                                                                    })  }>
                                                                        {header.header}
                                                                    </TableHeader>


                                                                </>
                                                            ))}
                                                            <TableHeader>

                                                            </TableHeader>

                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        {rows.map((row,k) => (

                                                            <>
                                                                <TableExpandRow key={row.id}   {...getRowProps({row })}>
                                                                    {row.cells.map((cell, j) => (

                                                                                <TableCell id = {cell.id}>
                                                                                    {this.formatMatrixCell(cell)}
                                                                                </TableCell>
                                                                    ))}

                                                                </TableExpandRow>
                                                                <TableExpandedRow {...getRowProps({row})} colSpan={headers.length + 3} >

                                                                </TableExpandedRow>


                                                            </>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}
                                    </DataTable>








                                </div>
                            </Column>
                        </Row>
                    </Grid>
               </Content>
            </>
        );
    }

    /**
     * Formats our booleans into printable values and gives sizes for our arrays
     *
     */
    formatMatrixCell(cell) {

        let {courses} = this.state;

        switch (cell.info.header) {
            case "id":
                let url = "/apply/" + cell.value
                return (



<ButtonSet className = "buttonMargin">
                <Button type = "button" kind = "primary" onClick = {() => {
                        this.props.navigate(url)
                    }
                    }>Apply</Button>
</ButtonSet>
                )
            case "courseName":
                return cell.value;
            case "recruiting":
                if (cell.value === true) {return "Yes"} else {return "No"}
            case "requireDBS":
                if (cell.value === true) {return "Yes"} else {return "No"}
            case "units":
                return cell.value.length;
            case "intakes":
                return cell.value.length;
            // case "targetLink":
            //     return (<a href = "/apply/">link</a>);
            default:
                return "unknown value";
        }
    }
}

export default function (props) {

    const navigate = useNavigate();
//    const [notification, setNotification] = useRecoilState(notificationDefinition);
//    return <Matrix navigate = {navigate} notification = {notification} setNotification = {setNotification} {...props} />;
    return <Matrix navigate = {navigate} {...props} />;
}

