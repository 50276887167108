import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

/**
 * Utility methods for handling interaction with the courses api
 *
 */
class RefereeService {

  /**
   * Retrieve the list of all available courses
   * Unauthenticated Method
   */

  getInvite(token) {
    return axios.get(API_URL + "/referee/" + token + "/view", {
            headers: {
              'Content-Type': 'application/json'
            }
    })
  }


  // submitReference(freeText, token) {
  //     alert("submitting");
  //     alert("reference text" + freeText);
  //     alert("token" + token);
  //
  //
  //
  // }



    submitReference(freeText, token) {
        //If country selected is not UK then delete postcode data


        let data = {
            referenceFreeText: freeText
        }

        return axios.post(API_URL + "/referee/" + token + "/update", data ,{
            headers: {
                'Content-Type': 'application/json',
//                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }
}

export default new RefereeService();