import React  from 'react';
import BaseComponent from "../../util/BaseComponent"
import { Outlet} from "react-router-dom";

class Course extends BaseComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <p>Course content template</p>

                <Outlet/>
            </>
        );
    }
}


export default function (props) {
    return <Course {...props} />;
}

