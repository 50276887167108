import React, {useContext} from 'react';

import BaseComponent, {
 fundingOptions,
 fundingOptionsResearch
} from "../../../../util/BaseComponent";

import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonSet} from "@carbon/react";
import Gated from "../../../../components/Gated";
import ApplicationService from "../../../../util/ApplicationService";
import StandardInput from "../../../../components/StandardInput";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";

const validationSchema = Yup.object().shape({
    fundingType: Yup.string().required('Please indicate your source of funding').nullable(),
    fundingTypeOther: Yup.string().nullable().when(['fundingType'], {
    is: (value) => {
        if (value === 'Other') {
            return true;
        } else {
            return false;
        }
    },
    then: Yup.string().nullable().required("Please provide more information"),
    }),

});

const emptyValues = {
    fundingType: '',
    fundingTypeOther: '',
}


class Funding extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            fundingMethods: fundingOptions,
            initialValues: emptyValues,
            navigateHome: false,
            loading: false,
            isTransmitting: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information

         //   this.getApplicationFormData(this.context.applicationFormId)

            this.processFunding()
        }
    }



    componentDidUpdate(prevProps) {
        if (this.props.formData !== prevProps.formData) {
            this.processFunding()
        }
    }

    /**
     * Pull in the current values we hold for this form, We're returning a DB object with the sensitive fields removed
     * so refer to DB named fields
     */
    processFunding() {

                    let data = this.context.formData;
                    let selectedFundingOptions = fundingOptions;

                    if (data.applicationVariant !== undefined && data.applicationVariant !== null) {
                        let applicationVariant = data.applicationVariant;

                        if (applicationVariant === 2004) { //research degree
                            selectedFundingOptions = fundingOptionsResearch;
                        }
                    }


                    let initialValues = {
                        fundingType: data.fundingType,
                        fundingTypeOther: data.fundingTypeOther,
                    }

                    this.setState({
                        initialValues: initialValues,
                        fundingMethods: selectedFundingOptions
                    })

    }


    /* We're submitting to an interim object */
    submitForm(values) {




        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

                ApplicationService.submitFundingType(values, this.context.applicationFormId)
                    .then(
                        (response) => {

                            toast.success(
                                "Success: Details Stored"
                                , {
                                    autoClose: 5000,
                                    className: "toastSuccess",
                                });


                            this.getApplicationFormData(this.context.applicationFormId)
                        })
                    .catch(
                        (error) => {
                            let parsedError = this.parseError(error);

                            toast.error(
                                parsedError.message
                                , {
                                   // autoClose: 5000,
                                    className: "toastError",
                                });

                        })
                    .then(
                        () => {
                            this.setState({
                                isTransmitting: false
                            })
                        }
                    )

    }


    render() {


        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;


        const loadingBase = this.state;


        const {hasApplied} = this.context;


        //What funding methods are available
        const {fundingMethods} = this.state;


        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        ///Its important we check the async form load has occured here
        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                            <h3 className="questionGroupTitle">Funding source</h3>

                            <div className="formPanel">

                                <StandardInput
                                    type = "dropDown"
                                    title = "Please indicate how this course will be funded"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "fundingType"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {3}
                                    options={fundingMethods}
                                />

                                <Gated display={values.fundingType === "Other"}>
                                    <StandardInput
                                        type = "text"
                                        title = "Please specify"
                                        description = ""
                                        placeholder = ""
                                        helpText = ""
                                        fieldId = "fundingTypeOther"
                                        requiredVisual = {true}
                                        flexRow = {true}
                                        flexGrow = {false}
                                        disabled = {hasApplied}
                                        additionalClass = ""
                                        multiline = {false}
                                        rowCount = {1}
                                        width = {3}
                                    />
                                </Gated>
                            </div>


                            <Gated display={hasApplied === false}>
                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary">Save page</Button>
                            </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    const formData = useContext(BaseContext).formData;

    return <Funding formData={formData} navigate={navigate} {...props} />;
}

