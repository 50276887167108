import React  from 'react';

import BaseComponent from "../../util/BaseComponent"
import {Outlet} from "react-router-dom";
import BaseContext from "../../util/BaseContext";


class Application extends BaseComponent {


    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            navigateHome: false,
        }
    }

    componentDidMount() {
/*
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        }
*/
    }


    render() {


        //Formik data, are we transmitting what are our defaults
        const {isTransmitting, initialValues, navigateHome} = this.state;

        //Our notifications system
        if (navigateHome) {
            this.props.navigate("/apply/list");
        }



        let courseName = "";
 //       if (this.context.formData !== undefined && this.context.formData.courseDetails !== undefined) {
        if (this.context.formData !== undefined && this.context.formData.courseDetails !== undefined && this.context.showCourseHeader) {

    //
    //
    //
    // //        console.log("applicationsL", this.context.formData)
    //
    //         if (this.context.formData.courseDetails.courseName === "MSc Professional Practice") {
    //
    //             courseName = ": " + "Unit CPD7006 The Deteriorating patient"
    //         } else {
    //             courseName = ": " + this.context.formData.courseDetails.courseName
    //
    //         }
        }






        return (
            <>
                <div className="contentHeader">
                    <div className = "constrainContentWidth">



                            <h1>Postgraduate application{courseName}</h1>
                        <p>Complete your application form</p>
                    </div>
                </div>

                <Outlet />

            </>
        );
    }
}


export default function (props) {
    return <Application {...props} />;
}

