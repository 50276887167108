import React from 'react';
import {Link} from 'react-router-dom';
import {
    Header,
    HeaderContainer,
    HeaderName,
    HeaderNavigation,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderGlobalBar,
    HeaderGlobalAction,
    SkipToContent,
    SideNav,
    // Temporarily comment these out until they are needed again
    // SideNavHeader,
    // SideNavDetails,
    // SideNavSwitcher,
    Content,
    SideNavDivider,
    SideNavItems,
    SideNavLink,
    SideNavMenu,
    SideNavMenuItem,
    HeaderSideNavItems,
} from '@carbon/react';
import {Logout} from '@carbon/icons-react';


import AuthService from "../../util/AuthService";


const DisplayHeader = () => (


    <div className="headerContainer">
        <div className="constrainContentWidthHeader">


            <HeaderContainer
                render={({isSideNavExpanded, onClickSideNavExpand}) => (
                    <Header aria-label="Header">
                        <SkipToContent/>

                        <HeaderMenuButton
                            aria-label="Open menu"
                            onClick={onClickSideNavExpand}
                            isActive={isSideNavExpanded}
                        />

                        {
                            //Alter the home button link based on whether we're logged in or not
                            AuthService.getCurrentUser() ? (
                                <HeaderName element={Link} to="/apply/list" prefix="HSU">
                                    Applications
                                </HeaderName>
                            ) : (
                                <HeaderName element={Link} to="/" prefix="HSU">
                                    Applications
                                </HeaderName>
                            )}

                        <HeaderNavigation aria-label="Header">
                            {AuthService.getCurrentUser() && (
                                <>

                                </>
                            )}
                        </HeaderNavigation>

                        <SideNav
                            aria-label="Side Navigation"
                            expanded={isSideNavExpanded}
                            isPersistent={false}
                        >
                            <SideNavItems>
                                <HeaderSideNavItems>
                                    {AuthService.getCurrentUser() ? (
                                        <>

                                        </>
                                    ) : (
                                        <HeaderMenuItem element={Link} to="/">Login</HeaderMenuItem>
                                    )}
                                </HeaderSideNavItems>
                            </SideNavItems>
                        </SideNav>

                        <HeaderGlobalBar>
                            {AuthService.getCurrentUser() && (
                                <HeaderMenuItem
                                    element={Link}
                                    to="/logout"
                                    aria-label="Logout"
                                >
                                    <Logout size="20" aria-label="Logout" tabIndex="0"/>
                                </HeaderMenuItem>
                            )}
                        </HeaderGlobalBar>
                    </Header>
                )}
            />
        </div>
    </div>
);

export default DisplayHeader;
