import React  from 'react';

import BaseComponent from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import * as Yup from "yup";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import Gated from "../../../../components/Gated";

const validationSchema = Yup.object().shape({
    personalStatement: Yup.string().test(
        'Max400Words',
        'Your response must be below 400 words',
        function(item) {
            if (item && item.split(/[\s]+/).length > 400) {
                return false;
            } else {
                return true;
            }
        }
    ).test(
        'stringlength',
        'Your response must be below 4000 characters',
        function(item) {
            if (item !== undefined && (item.length === 0 || item.length > 4000 )) {
                return false;
            } else {
                return true;
            }
        }
    ).required("Please check your personal statement")
});



const validationSchemaResearch = Yup.object().shape({
    personalStatement: Yup.string().test(
        'Max400Words',
        'Your response must be below 1000 words',
        function(item) {
            if (item && item.split(/[\s]+/).length > 1000) {
                return false;
            } else {
                return true;
            }
        }
    ).test(
        'stringlength',
        'Your response must be below 10000 characters',
        function(item) {
            if (item !== undefined && (item.length === 0 || item.length > 10000 )) {
                return false;
            } else {
                return true;
            }
        }
    ).required("Please check your personal statement")
});

class PersonalStatement extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        const initialValues = {
            personalStatement: "",
        }


        this.state = {
            initialValues: initialValues,
            isTransmitting: false,
            navigateHome: false,

            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }




    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }

    processFormData() {

                    let data = this.context.formData;


                    let personalStatement = "";

                    if (data.personalStatement !== null) {
                        personalStatement = data.personalStatement;
                    }

                    this.setState({
                        initialValues: {
                            personalStatement: personalStatement
                        }
                    })
     }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitPersonalStatement(values,this.context.applicationFormId)
            .then(
                (response) => {

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });


                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);

                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });

                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }




    render() {


        const {isTransmitting, initialValues, navigateHome} = this.state;
//        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        let targetCourse=this.context.formData.courseDetails.id;



        let courseVariant = this.context.formData.applicationVariant;

        let computedValidationSchema = validationSchema;
        let maxWordCount = 400;

        if (courseVariant === 2004) {
            computedValidationSchema = validationSchemaResearch;
            maxWordCount = 1000;
        }





        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={computedValidationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>
                            <h3 className="questionGroupTitle">Personal statement</h3>

                            {/*{  targetCourse === 125 ? (*/}
                            {/*    <p className="informationText dropDownPadding">Please indicate your reasons for choosing the unit or course you will be applying for. Where relevant you may wish to include details of any current job role or educational courses and any other information you feel would support your application.</p>*/}
                            {/*    ) : (*/}
                            {/*    <p className="informationText dropDownPadding">Please indicate your reasons for choosing the programme you will be applying for, career aspirations and any other information, such as hobbies and interests, that you wish us to know about when considering your application. Maximum 400 words.</p>*/}
                            {/*    )*/}
                            {/*}*/}

                            <p className="informationText dropDownPadding">Please indicate your reasons for choosing the unit or course you will be applying for. Where relevant you may wish to include details of any current job role or educational courses and any other information you feel would support your application.</p>

                            <div className = "formPanelNoMaxWidth">

                            <StandardInput
                                type="wordCount"
                                title="Your statement"
                                description=""
                                placeholder=""
                                helpText=""
                                fieldId={"personalStatement"}
                                required={false}
                                flexRow={false}
                                flexGrow={false}
                                disabled={hasApplied}
                                additionalClass=""
                                multiline={true}
                                rowCount={10}
                                width={4}
                                maxWords={maxWordCount}
                            />


                                </div>

                            <Gated display={hasApplied === false}>
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                            </Gated>

                        </Form>
                    )}
                </Formik>

            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <PersonalStatement navigation={navigate} {...props} />;
}

