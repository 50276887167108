import React from 'react';
import {Button, ButtonSet, Content, Grid, InlineNotification, Row} from "@carbon/react";
import { Form, Formik} from "formik";
import * as Yup from "yup";
import AuthService from "../../util/AuthService";
import {TextField, TooltipDelay, TooltipHost} from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';
import UserService from "../../util/UserService";
import {Link, useNavigate} from "react-router-dom";
import {
    Information, Warning,
} from "@carbon/icons-react";
import BaseComponent from "../../util/BaseComponent";



/**
 * Initial values for our form
 */
const initialValues = {
    title: "",
    forename: "",
    surname: "",
    email: "",
    passphrase: "",
    confirmPassphrase: "",
};

/**
 * Yup validation schema for our form
 */
const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Titles should be a minimum of 2 characters').max(20, 'Titles should be a maximum of 20 characters').required('Please ensure you enter a title'),
    email: Yup.string().max(254, 'Emails can not exceed 254 characters').email('Invalid email address').required('Please enter a valid email address'),
   // confirmEmail: Yup.string().trim().email('Invalid email address').required('Please enter a valid email address'),
    forename: Yup.string().min(1, 'Names should be a minimum of 1 characters').max(150, 'Names should be a maximum of 150 characters').required('Please ensure you enter a name'),
    surname: Yup.string().min(1, 'Names should be a minimum of 1 characters').max(150, 'Names should be a maximum of 150 characters').required('Please ensure you enter a name'),
    passphrase: Yup.string().min(8, "Passwords should be a minimum of 8 characters").required('Please enter a Password')
 //       .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,"Passwords should meet minimum complexity standards, min 8 chars, a symbol, mixed case, a number"),
 //       .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?=.{8,})/,"Password must meet minimum complexity standards, min 8 chars and contain at least a mix of cases, at least one numeral and at least one symbol"),
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must meet minimum complexity standards, min 8 chars and contain at least a mix of cases, at least one numeral and at least one symbol"),
    confirmPassphrase: Yup.string().trim().required('Please enter a password'),

});

const loginDetailsTooltip = {
        onRenderContent: () => (
            <div className = "tooltipContainer">
                <p>Our institutional policies require you to use a passphrase that has a minimum of eight characters, and contains a mix of UPPERCASE, lowercase, numerals and symbols</p>
                <p>Your passphrase can be much longer, a good approach is to follow the four unrelated random words approach. Select 4 memorable to you words as a base and manipulate them to incorporate some punctuation and numerals</p>
            </div>
        ),
    }




const validator = values => {

    let emailCheckResponse = {};
    let passwordCheckResponse = {};

    /*
    if (values.email.toLowerCase() !== values.confirmEmail.toLowerCase()) {
        emailCheckResponse = {
            confirmEmail: "Your email addresses don't match"
        }
    }
*/
    if (values.passphrase !== values.confirmPassphrase) {
        passwordCheckResponse = {
            confirmPassphrase: "Your Passwords must match"
        }
    }

    return {
        ...emailCheckResponse,
        ...passwordCheckResponse
    };
}



class Register extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            isSubmitting: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //Clear any errors currently in the session
 //       this.props.setNotification([]);

     //   this.displayError("error","parsedError.title", "parsedError.message",this.props.notification, this.props.setNotification )

    }


    register(values) {



        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isSubmitting: true
        })

        UserService.register(values)
            .then(
                (response) => {
                    //registration will return a JWT token as if the user has logged in upon success
                   //TODO: Additional validation to be added here
                    console.log("Logging in with: ",response.config);

                    let data = JSON.parse(response.config.data.trim(''))


                    AuthService.login(data.email, data.passphrase)
                        .then(
                            (response) => {
                                let navigate = this.props.navigate;

                                navigate("/apply/list");
                            })
                        .catch(
                            error => {

                                console.log(error);
                                let parsedError = this.parseError(error)
                                this.setState({
                                        displayNotification: true,
                                        notificationTitle: parsedError.title,
                                        notificationSubtitle: parsedError.message,
                                        notificationKind: "error",
                                    }
                                )

                            }
                        )
                        .then(
                            () => {
                                this.setState({
                                    isSubmitting: false
                                })
                            }
                        )
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    this.setState({
                            displayNotification: true,
                            notificationTitle: parsedError.title,
                            notificationSubtitle: parsedError.message,
                            notificationKind: "error",
                        }
                    )
                    })
            .then(
                () => {
                    this.setState({
                        isSubmitting: false
                    })
                }
            )
    }







    render() {

        const {isSubmitting } = this.state;
        const {displayNotification,notificationKind,notificationTitle,notificationSubtitle} = this.state;

        return (
           <>
                <div className="contentHeader">
                    <div className = "constrainContentWidth">

                    <h1>Create account</h1>
                    <p>Create an account with Health Sciences University.</p>
                    </div>
                </div>





                <Content>
                    <Grid>
                        <Row>
                            <div className="highlightedFormQuestionHints">
                                <p><Warning className="warningPosition"/>If you already have an account please click <Link to = "/" className = "purple">here to login.</Link></p>
                            </div>
                        </Row>

                        <Row>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validate={validator}
                                    onSubmit={(values) => {

                                        //avoid a double click
                                        if (!isSubmitting) {
                                            this.register(values);
                                        }
                                    }}
                                >
                                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (

                                        <Form>


                                            <h3 className="questionGroupTitle">Your Name</h3>
                                            <div className="formPanel">

                                                <div className="formRow">
                                                    <TextField
                                                        label="Title"
                                                        ariaLabel="How would you like to be addresses"
                                                        className="narrowInput"
                                                        autoComplete={true}
                                                        description=""
                                                        disabled={false}
                                                        errorMessage={touched.title && errors.title}
                                                        multiline={false}
                                                        onChange={(event, value) => {
                                                            setFieldValue("title", value);
                                                            //                                                       handleChange("username")
                                                        }}
                                                        //prefix = ""
                                                        //suffix = ""
                                                        placeholder="mr/mrs/ms/dr"
                                                    />
                                                </div>
                                                <div className="formRow">
                                                    <TextField
                                                        label="Forename"
                                                        ariaLabel="Please enter your forename"
                                                        className="mediumInput"
                                                        autoComplete={true}
                                                        description=""
                                                        disabled={false}
                                                        errorMessage={touched.forename && errors.forename}
                                                        multiline={false}
                                                        onChange={(event, value) => {
                                                            setFieldValue("forename", value);
                                                            //                                                       handleChange("username")
                                                        }}
                                                        //prefix = ""
                                                        //suffix = ""
                                                        placeholder="forename"
                                                    />
                                                </div>

                                                <div className="formRow">

                                                    <TextField
                                                        label="Surname"
                                                        ariaLabel="Please enter your surname"
                                                        className="mediumInput"
                                                        autoComplete={true}
                                                        description=""
                                                        disabled={false}
                                                        errorMessage={touched.surname && errors.surname}
                                                        multiline={false}
                                                        onChange={(event, value) => {
                                                            setFieldValue("surname", value);
                                                            //                                                       handleChange("username")
                                                        }}
                                                        //prefix = ""
                                                        //suffix = ""
                                                        placeholder="surname"
                                                    />

                                                </div>
                                            </div>

                                            <h3 className="questionGroupTitle">Contact details</h3>
                                            <div className="formPanel">

                                            <div className="formRow">
                                                <TextField
                                                    label="Email Address"
                                                    ariaLabel="Please enter your email address"
                                                    className="mediumInput"
                                                    autoComplete={true}
                                                    description="This will be used to contact you about you application"
                                                    disabled={false}
                                                    errorMessage={touched.email && errors.email}
                                                    multiline={false}
                                                    onChange={(event, value) => {
                                                        setFieldValue("email", value);
                                                        //                                                       handleChange("username")
                                                    }}
                                                    //prefix = ""
                                                    //suffix = ""
                                                    placeholder="email address"
                                                />


                                            </div>
                                            </div>
                                                <h3 className="questionGroupTitle">Login details

                                                    <TooltipHost
                                                        tooltipProps={loginDetailsTooltip}
                                                        delay={TooltipDelay.zero}
                                                        id={useId('Login details')}
                                                    >
                                                        <Information className = "informationIcon" size={16} />
                                                    </TooltipHost>
                                                </h3>
                                                <div className="formPanel">

                                                    <div className="formRow">


                                                        <TextField
                                                    label="Passphrase"
                                                    type="password"
                                                    ariaLabel="Please enter a passphrase"
                                                    className="mediumInput"
                                                    autoComplete={true}
                                                 //   description="choose a passphrase"
                                                    disabled={false}
                                                    errorMessage={touched.passphrase && errors.passphrase}
                                                    multiline={false}
                                                    onChange={(event, value) => {
                                                        setFieldValue("passphrase", value);
                                                        //                                                       handleChange("password")
                                                    }}
                                                    //prefix = ""
                                                    //suffix = ""
                                                    placeholder="memorable passphrase"
                                                    canRevealPassword
                                                    revealPasswordAriaLabel="Show passphrase"
                                                />
                                                    </div>
                                                    <div className = "formRow">

                                                        <TextField
                                                            label="Confirm passphrase"
                                                            type="password"
                                                            ariaLabel="Please enter your passphrase"
                                                            className="mediumInput"
                                                            autoComplete={true}
                                                    //        description="re-enter passphrase"
                                                            disabled={false}
                                                            errorMessage={touched.confirmPassphrase && errors.confirmPassphrase}
                                                            multiline={false}
                                                            onChange={(event, value) => {
                                                                setFieldValue("confirmPassphrase", value);
                                                                //                                                       handleChange("password")
                                                            }}
                                                            //prefix = ""
                                                            //suffix = ""
                                                            placeholder="confirm your passphrase"
                                                            canRevealPassword
                                                            revealPasswordAriaLabel="Show passphrase"
                                                            className = ""
                                                        />







                                            </div>
                                            </div>


                                            {displayNotification && (
                                                <InlineNotification
                                                    kind={notificationKind}
                                                    title={notificationTitle}
                                                    subtitle={notificationSubtitle}
                                                    onCloseButtonClick={() => {
                                                        this.setState({
                                                            displayNotification: false,
                                                            notificationTitle: "",
                                                            notificationSubtitle: ""
                                                        })
                                                    }}
                                                />
                                            )}

                                            <p className = "informationText dropDownPadding">
                                                Please note by submitting this application you are acknowledging that you have read and agree to abide by our <a className = "purple" href = "https://www.hsu.ac.uk/media/9061/student-privacy-notice.pdf" target = "_new">privacy and data retention policies</a>
                                            </p>


                                            <ButtonSet>
                                                <Button type="reset" kind="secondary"


                                                >Clear form</Button>
                                                <Button type="submit" kind = "primary">Create Account</Button>


                                            </ButtonSet>
                                        </Form>
                                    )}
                                </Formik>

                        </Row>
                    </Grid>

            </Content>
           </>
        );
    }
}


export default function (props) {

    const navigate = useNavigate();
//    const [notification, setNotification] = useRecoilState(notificationDefinition);
    return <Register navigate = {navigate}  {...props} />;
}

