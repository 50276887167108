import React  from 'react';

import BaseComponent, {disabilityArray} from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import {
     DataTable,
    FileUploader,
    Table, TableBody, TableCell,
    TableContainer, TableExpandedRow, TableExpandHeader, TableExpandRow,
    TableHead, TableHeader,
    TableRow
} from "@carbon/react";
import FileService from "../../../../util/FileService";
import StandardInput from "../../../../components/StandardInput";
import * as Yup from "yup";
import {DocumentDownload, Information, TrashCan} from "@carbon/icons-react";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import {Panel, PanelType} from "@fluentui/react";


const validationSchema = {
    documentDescription: Yup.string().nullable().max(512, "please shorten the description")
}


const fileListHeaders = [

    {key: "id", header: "id", isSortable: false, display: false},
    {key: "fileName", header: "Filename", isSortable: false, display: true},
    {key: "description", header: "Description", isSortable: false, display: true},
    {key: "download", header: "", isSortable: false, display: true},
    {key: "delete", header: "", isSortable: false, display: true},


];


class Reference extends BaseComponent {

    static contextType = BaseContext;
    constructor(props) {
        super(props);

        const initialValues = {
            documentDescription: "",
        }

        this.state = {
            initialValues: initialValues,
            navigateHome: false,

            isTransmitting: false,
            isReceiving: false,
            fileList: [],
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
            researchApplication: false,
            sidePanelVisible: false,
        }
    }


    getFile(fileIdentifier, originalFilename) {
        FileService.getFile(fileIdentifier, this.context.applicationFormId, originalFilename);
        toast.success(
            "Success: File retrieved"
            , {
                autoClose: 5000,
                className: "toastSuccess",
            });
    }


    deleteFile(fileId ) {

        if (!this.state.isTransmitting) {
            this.setState({
                isTransmitting: true,
            })

            FileService.deleteFile(fileId, this.context.applicationFormId)
                .then(
                    (response) => {

                        this.getFileList(this.context.applicationFormId);
                 //       this.getApplicationFormData(this.context.applicationFormId)

                        toast.success(
                            "Success: File removed"
                            , {
                                autoClose: 5000,
                                className: "toastSuccess",
                            });
                    })
                .catch(
                    (error) => {
                        let parsedError = this.parseError(error);
                        toast.error(
                            parsedError.message
                            , {
                                // autoClose: 5000,
                                className: "toastError",
                            });
                    })
                .then(
                    () => {
                        this.setState({
                            isTransmitting: false
                        })
                    }
                )
        }
    }







    handleUpload(values, resetForm) {

        let form = document.getElementsByName("fileUploader");
        let file = form[0].files[0];
        this.setState({
            isTransmitting: true,
        })

        FileService.uploadFile(file, this.context.applicationFormId, values.documentDescription)
            .then(
                (response) => {
                    resetForm()
                    this.getFileList(this.context.applicationFormId);

                    toast.success(
                        "Success: File stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }


    getFileList(currentForm) {

        if (!this.state.isReceiving) {
            this.setState({
                isReceiving: true,
            })

            FileService.getList(currentForm)
                .then(
                    (response) => {
                        let fileList = response.data;

                        this.setState({fileList: fileList});
                    })
                .catch(
                    (error) => {
                        let parsedError = this.parseError(error);
                        toast.error(
                            parsedError.message
                            , {
                                // autoClose: 5000,
                                className: "toastError",
                            });
                    })
                .then(
                    () => {
                        this.setState({
                            isReceiving: false
                        })
                    }
                )

        }
    }


    /** No need for most of the applicationForm, retrieve the filelist for this application **/
    componentDidMount() {

        let formData = this.context.formData;

        if (formData.applicationVariant !== undefined && formData.applicationVariant !== null && formData.applicationVariant === 2004) {
            this.setState({researchApplication: true})
        }


        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.getFileList(this.context.applicationFormId);
        }




    }






    render() {

        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome, fileList} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;
        const {researchApplication, sidePanelVisible} = this.state;


        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }


        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue, resetForm}) => (
                        <Form name="fileform1" id="fileform1">

                            <h3 className="questionGroupTitle">Supporting documents</h3>

                            {researchApplication ? (
                                <>
                                    <p className="informationText dropDownPadding">Please upload the following documentation in support of your application:</p>
                                    <ul id = "documentList">
                                        <li>Qualification transcripts / certificates (scanned copies can be submitted, but if your application is successful you will be required to present the originals at registration).</li>
                                        <li>Photograph of the ID page in your passport.</li>
                                        <li>A confirmation of funding letter, or guarantee form from your funder / employer (if your studies will be sponsored).</li>
                                        <li>Two suitable references (normally at least one should be an academic reference).</li>
                                        <li>CV</li>
                                        <li>A personal statement detailing your experience and why you are suitable for postgraduate research study. Your personal statement should be no more than 1,000 words.</li>
                                        <li><a className = "inlineLink" onClick={
                                            () => {
                                                this.setState({
                                                    sidePanelVisible: true,
                                                })
                                            }
                                        }>Research proposal
                                            <Information className="informationIcon" size={16}
                                                        />
                                        </a>
                                            <Panel
                                                headerText="Research proposals"
                                                isOpen={sidePanelVisible}
                                                isLightDismiss={true}
                                                onDismiss={() => {
                                                    this.setState({sidePanelVisible: false})
                                                }}
                                                closeButtonAriaLabel="close"
                                                type={PanelType.medium}
                                            >

                                                <p className="informationText withWhitespace">
                                                    The research proposal should be 2,000-4,000 words and include the following:
                                                </p>


                                                <div className="paragraphTitle">If you are proposing your own research project:</div>
                                                <ul id = "documentList">
                                                    <li>Your project title and key research questions.</li>
                                                    <li>An overview of the context into which your research will fit, including.</li>
                                                    <li>The aims and objectives of your proposed research.</li>
                                                    <li>An overview of current literature that supports your proposed project.</li>
                                                    <li>How it adds to, develops (or challenges) previous work by researchers in the field.</li>
                                                    <li>Why the research is important to the subject area.</li>
                                                    <li>How you think the research will make an original contribution to knowledge.</li>
                                                    <li>An outline of how the research will be achieved within the normal duration of a PhD.</li>
                                                    <li>Consideration you have given to research methods, what research method(s) you propose to use, and your rationale for why these are appropriate to the project.</li>
                                                    <li>Any consideration or discussion you have had with any prospective supervisors at Health Sciences University for the project (please note this is mandatory before proposing your own research project).</li>
                                                    <li>Consideration of the sources, resources and / or facilities required to successfully complete the research. If the project will require access to external organisations or specialist facilities, the proposal should include details of how you intend to secure access to these for the required duration.</li>
                                                    <li>An indicative bibliography demonstrating your critical grasp of current literature in the field and how you plan to contribute to it.</li>
                                                    <li>Potential research dissemination and potential long-term impacts of the project.</li>
                                                    <li>How your research interests are suitable to deliver the project successfully, and what makes you the ideal candidate for the project.</li>
                                                </ul>
                                                <div className="paragraphTitle">If you are applying for one of the listed research projects:</div>

                                                <ul id = "documentList">
                                                    <li>An outline that demonstrates your understanding of the research project, its goals, potential impact, and the approach you would take as the doctoral candidate to include.</li>
                                                    <li>Why the research is important to the subject area.</li>
                                                    <li>How you think the research will make an original contribution to knowledge.</li>
                                                    <li>An outline of how the research will be achieved within the normal duration of a PhD.</li>
                                                    <li>Consideration you have given to research methods, what research method(s) you propose to use, and your rationale for why these are appropriate to the project.</li>
                                                    <li>Consideration of the sources, resources and / or facilities required to successfully complete the research. If the project will require access to external organisations or specialist facilities, the proposal should include details of how you intend to secure access to these for the required duration.</li>
                                                    <li>An indicative bibliography demonstrating your critical grasp of current literature in the field and how you plan to contribute to it.</li>
                                                    <li>Potential research dissemination and potential long-term impacts of the project.</li>
                                                    <li>How your research interests are suitable to deliver the project successfully, and what makes you the ideal candidate for the project.</li>
                                                </ul>

                                            </Panel>

                                            of c.2,000 – 4,000 words which includes a proposed timeline of your research and bibliography.</li>
                                        <li>Evidence of English language proficiency if applicable.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                </>
                            ) : (
                                <p className="informationText dropDownPadding">Please upload any scanned copies of documents
                                    to support your application such as academic or professional qualifications here. This section is optional but will help us
                                    process your application faster.</p>
                            )}




                            <h3 className="questionGroupTitle">Provided files</h3>

                            <div className="formPanel noMaxWidth">


                            {fileList.length > 0 ? (

                            <DataTable
                                isSortable
                                rows={fileList}
                                headers={fileListHeaders}
                                overflowMenuOnHover={false}
                            >
                                {({
                                      rows,
                                      headers,
                                      getHeaderProps,
                                      getRowProps,
                                      getTableProps,
                                      getTableContainerProps,
                                  }) => (
                                    <TableContainer
                                        // title="Active applications"
                                        // description="List of active applications"

                                        {...getTableContainerProps()}>

                                        <Table  {...getTableProps()}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableExpandHeader/>
                                                    {headers.map((header, i) => (
                                                        <>

                                                            <TableHeader {...getHeaderProps({
                                                                header,
                                                                key: i,
                                                                isSortable: header.isSortable,
                                                                colSpan: header.colSpan
                                                            })}>
                                                                {header.header}
                                                            </TableHeader>


                                                        </>
                                                    ))}
                                                    <TableHeader>

                                                    </TableHeader>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody>

                                                {rows.map((row, k) => (

                                                    <>
                                                        <TableExpandRow key={row.id}   {...getRowProps({row})}>
                                                            {row.cells.map((cell, j) => (

                                                                <TableCell id={cell.id}>
                                                                    {this.formatApplicationCell(row, cell)}
                                                                </TableCell>
                                                            ))}

                                                        </TableExpandRow>
                                                        <TableExpandedRow {...getRowProps({row})}
                                                                          colSpan={headers.length + 3}>
                                                            <>
                                                                <p className="informationText">{row.cells[2].value}</p>
                                                            </>
                                                        </TableExpandedRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </DataTable>
                            ) : (

                                <p className="informationText dropDownPadding">No existing files found.</p>


                            )}
                            </div>


                            <h3 className="questionGroupTitle">Provide new file</h3>

                            <p className="informationText dropDownPadding">Please enter a brief description of the nature of the upload and then select the file you wish to upload</p>

                            <div className="formPanel">

                                <StandardInput
                                    type="text"
                                    title="Document description"
                                    description="Please briefly describe the purpose of the document you're uploading"
                                    placeholder=""
                                    helpText=""
                                    fieldId="documentDescription"
                                    required={false}
                                    flexRow={true}
                                    flexGrow={false}
                                    disabled={false}
                                    additionalClass=""
                                    multiline={true}
                                    rowCount={5}
                                    width={0}
                                />


                                <FileUploader
                                    accept={[
                                        '.pdf',
                                        '.docx',
                                        '.jpg',
                                        '.png'
                                    ]}
                                    buttonKind="primary"
                                    buttonLabel="Select file"
                                    iconDescription="Select file"
                                    labelDescription="We accept .pdf, .docX, .jpg, and .png files of 10mb or less"
                                    labelTitle="Choose file"
                                    multiple={false}
                                    size="lg"
                                    name="fileUploader"
                                    onClick={() => {
                                        if (isTransmitting) {
                                            return false;
                                        }
                                    }}
                                    onChange={() => {
                                        this.handleUpload(values, resetForm);
                                    }}
                                />


                            </div>

                        </Form>
                    )}
                </Formik>

            </>
        );
    }


    formatApplicationCell(row, cell) {

        let {fileList} = this.state;

        switch (cell.info.header) {
            case "id":
                return (<>
                    {cell.value}
                </>);
            case "fileName":
                return cell.value;
            case "description":
                if (cell.value.length > 30) {
                    return cell.value.substr(0,27) + "...";
                } else {
                    return cell.value;
                }
            case "download":
                return (
                    <>
                        <DocumentDownload className="activeIcon" onClick = {()=>{this.getFile(row.cells[0].value, row.cells[1].value)}}/>
                    </>
                )
            case "delete":
                return (
                    <>
                        <TrashCan classNAme = "activeIcon" onClick = {()=>{this.deleteFile(row.cells[0].value)}}/>
                    </>
                )
            default:
                return "unknown value";
        }
    }
}

export default function (props) {
    const navigate = useNavigate();
    return <Reference navigate={navigate} {...props} />;
}

