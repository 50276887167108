import {createTheme} from "@fluentui/react";

/**
 * Colour definitions for the fluent UI components
 *
 * @type {Theme}
 */

export const elementTheme = createTheme({

    defaultFontStyle: {
        fontFamily: '"IBM Plex Sans", "Helvetica Neue", Arial, sans-serif',
        fontWeight: 'regular'
    },

    palette: {
        themePrimary: '#772583',
        themeLighterAlt: '#f9f3fa',
        themeLighter: '#e7d0eb',
        themeLight: '#d3abda',
        themeTertiary: '#aa66b4',
        themeSecondary: '#853591',
        themeDarkAlt: '#6a2175',
        themeDark: '#591c63',
        themeDarker: '#421449',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#b0b0b0',
        neutralSecondary: '#979797',
        neutralPrimaryAlt: '#7d7d7d',
        neutralPrimary: '#171717',
        neutralDark: '#4a4a4a',
        black: '#303030',
        white: '#ffffff',
    },

    semanticColors: {
        errorText: '#CB6015',
        warningText: '#CB6015',
//        inputPlaceholderText: '#ffff00',
    },




});




