import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

class FileService {


    uploadFile(file, formId, description) {

        const url = API_URL + "/application/" + formId + "/upload";

        const formData = new FormData();
        formData.append("file", file);
        formData.append("description", description );


        return axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            },
        })
    };

    deleteFile(fileId, formId) {
        const url = API_URL + "/application/" + formId + "/file/" + fileId ;

        return axios.delete(url, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            },
        })
    };


    getList( formId) {
        const url = API_URL + "/application/" + formId + "/files";
        //    + "?"
        //    + "pageNo=" + page
        //    + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    getRequiredList( applicationId, shortForm) {

        let formType;
        if (shortForm===true) {
            formType = "short";
        } else {
            formType = "long";
        }

        const url = API_URL + "/files/" + applicationId + "/required/" + formType
        //    + "?"
        //    + "pageNo=" + page
        //    + "&pageSize=" + size


        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }
    updateDocumentType(applicationId, index, newType) {
        const url = API_URL + "/files/type/" + applicationId + "?" + "index=" + index

        return axios.put(url, {formType: newType}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        } );
    }

    getFile(fileIdentifier, formId, originalFilename) {
        const url = API_URL + "/application/" + formId + "/file/" + fileIdentifier;


        axios.get( url, {
                headers: {
                    'Authorization': 'Bearer ' + AuthService.getAuthToken()
                },
                responseType: 'blob'
            }
        ).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', originalFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            error => {
                alert("A problem has been encountered retrieving the requested file");

            }
        )
    }


}

export default new FileService();
