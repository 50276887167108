import React, {Component} from 'react';
import { Navigate} from "react-router-dom";
import AuthService from "../../util/AuthService";


export default class Logout extends Component {

    componentDidMount() {

        const user = AuthService.getCurrentUser();
        if (user) {
            AuthService.logout();
                  }
    }


    render() {

        return (
            <Navigate to = "/"/>
        )
    }

}
