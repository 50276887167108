import React, {useContext} from 'react';

import BaseComponent, {
    genderArray,
    disabilityArray,
    ethnicityArray,
    nationalityArray,
    countryArray,
    monthArray,
    qualificationArray
} from "../../../../util/BaseComponent";

import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonSet} from "@carbon/react";
import Gated from "../../../../components/Gated";
import ApplicationService from "../../../../util/ApplicationService";
import StandardInput from "../../../../components/StandardInput";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";

const validationSchema = Yup.object().shape({
    personTitle: Yup.string().required('Please enter a title').nullable(),
    personForename: Yup.string().required('Please enter a forename').nullable(),
    personSurname: Yup.string().required('Please enter a surname').nullable(),
    personPreviousName: Yup.string().nullable(),

    /**
     * TODO validation of DOBs
     */
    personDOB: Yup.string().required('Please enter your date of birth').nullable(),
    personGender: Yup.string().required('Please enter a gender').nullable(),
    personCountry: Yup.string().required('Please enter a country').nullable(),
    personAddressLine1: Yup.string().required('Please enter your address').nullable(),
    personAddressLine2: Yup.string().required('Please enter your address').nullable(),
    personAddressLine3: Yup.string().nullable(),
    personAddressLine4: Yup.string().nullable(),

 /*   personPostCode: Yup.string().when(['personCountry'], {
        is: (personCountry) => personCountry === "United Kingdom",
        then: Yup.string().required("Please enter your postcode").nullable()
    }),
*/
/*    personPostCode: Yup.string().when(['personCountry'], {
        is: 'United Kingdom',
        then: Yup.string().required("Please enter your postcode").nullable()
    }),
*/
       personPostCode: Yup.string().nullable().when(['personCountry'], {
           is: (value) => {
               if (value === 'United Kingdom') {
                   console.log("value checked true,", value)
                   return true
               } else {
                   console.log("value checked false,", value)
                   return false
               }
           },
           then: Yup.string().required("Please enter your postcode").nullable()
       }),


    personMobile: Yup.string().required('Please enter a contact number').nullable()
});

const emptyValues = {
    personTitle: '',
    personForename: '',
    personSurname: '',
    personPreviousName: '',
    personGender: '',
    personDOB: '',
    personCountry: '',
    personAddressLine1: '',
    personAddressLine2: '',
    personAddressLine3: '',
    personAddressLine4: '',
    personPostCode: '',
    personMobile: ''
}


class PersonalInfo1 extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {

            initialValues: emptyValues,
            navigateHome: false,


            loading: false,

            isTransmitting: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information

         //   this.getApplicationFormData(this.context.applicationFormId)

            this.processPersonalInfo1()
        }
    }



    componentDidUpdate(prevProps) {
        if (this.props.formData !== prevProps.formData) {
            this.processPersonalInfo1()
        }
    }

    /**
     * Pull in the current values we hold for this form, We're returning a DB object with the sensitive fields removed
     * so refer to DB named fields
     */
    processPersonalInfo1() {

                    let data = this.context.formData;

                    let initialValues = {

                        personTitle: data.title,
                        personForename: data.forename,
                        personSurname: data.surname,
                        personPreviousName: data.previousName,
                        personDOB: data.dob,
                        personGender: data.gender,

                        personCountry: data.correspondenceCountry,
                        personAddressLine1: data.correspondenceAddressLine1,
                        personAddressLine2: data.correspondenceAddressLine2,
                        personAddressLine3: data.correspondenceAddressLine3,
                        personAddressLine4: data.correspondenceAddressLine4,
                        personPostCode: data.correspondencePostCode,
                        personMobile: data.correspondenceTelNo,

                    }

                    this.setState({
                        initialValues: initialValues
                    })

    }


    /* We're submitting to an interim object */
    submitForm(values) {




        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

                ApplicationService.submitPersonalInfo1(values, this.context.applicationFormId)
                    .then(
                        (response) => {

                            toast.success(
                                "Success: Details Stored"
                                , {
                                    autoClose: 5000,
                                    className: "toastSuccess",
                                });


                            this.getApplicationFormData(this.context.applicationFormId)
                        })
                    .catch(
                        (error) => {
                            let parsedError = this.parseError(error);

                            toast.error(
                                parsedError.message
                                , {
                                   // autoClose: 5000,
                                    className: "toastError",
                                });

                        })
                    .then(
                        () => {
                            this.setState({
                                isTransmitting: false
                            })
                        }
                    )

    }


    render() {


        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;


        const loadingBase = this.state;


        const {hasApplied} = this.context;





        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        ///Its important we check the async form load has occured here
        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                            <h3 className="questionGroupTitle">About you</h3>


                            <div className="formPanel">

                                {/* multiple items on this row */}
                                <div className="formsFlex">
                                    <StandardInput
                                        type = "text"
                                        title = "Title"
                                        description = ""
                                        placeholder = "mr/miss/ms"
                                        helpText = ""
                                        fieldId = "personTitle"
                                        requiredVisual = {true}
                                        flexRow = {true}
                                        flexGrow = {false}
                                        disabled = {true}
                                        additionalClass = ""
                                        multiline = {false}
                                        rowCount = {1}
                                        width = {1}

                                    />

                                    <StandardInput
                                        type = "text"
                                        title = "Forename"
                                        description = ""
                                        placeholder = "first name"
                                        helpText = ""
                                        fieldId = "personForename"
                                        requiredVisual = {true}
                                        flexRow = {true}
                                        flexGrow = {true}
                                        disabled = {true}
                                        additionalClass = "paddingRightTemp"
                                        multiline = {false}
                                        rowCount = {1}
                                        width = {0}

                                    />

                                    <StandardInput
                                        type = "text"
                                        title = "Surname"
                                        description = ""
                                        placeholder = "surname"
                                        helpText = ""
                                        fieldId = "personSurname"
                                        requiredVisual = {true}
                                        flexRow = {true}
                                        flexGrow = {true}
                                        disabled = {true}
                                        additionalClass = ""
                                        multiline = {false}
                                        rowCount = {1}
                                        width = {0}

                                    />
                                </div>

                                <StandardInput
                                    type = "text"
                                    title = "Previous name"
                                    description = "Please enter any previous name you've used"
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personPreviousName"
                                    requiredVisual = {false}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "date"
                                    title = "Date of birth"
                                    description = ""
                                    placeholder = "dd/mm/yyyy"
                                    helpText = ""
                                    fieldId = "personDOB"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {2}
                                />

                                {/* old style DOB input
                                <div className="formsFlex">
                                    <Dropdown
                                        className="formsWidth1 formsFlexChildGrow"
                                        label="Date Of Birth"
                                        ariaLabel="Date Of Birth"
                                        autoComplete={true}
                                        description="Title"
                                        disabled={false}
                                        dropdownWidth={0}
                                        options={daysArray}
                                        useComboBoxAsMenuWidth={true}
                                        errorMessage={touched.personDOBDay && errors.personDOBDay}
                                        multiline={false}
                                        onChange={(event, value) => {
                                            setFieldValue("personDOBDay", value);
                                            handleChange("personDOBDay")
                                        }}
                                        placeholder="Day"
                                    />
                                    <Dropdown
                                        className="formsWidth1 formsFlexChildGrow"
                                        label="&nbsp;"
                                        ariaLabel="Date Of Birth Month"
                                        autoComplete={true}
                                        description="Date Of Birth Month"
                                        disabled={false}
                                        errorMessage={touched.personDOBMonth && errors.personDOBMonth}

                                        options={monthArray}

                                        onChange={(event, value) => {
                                            setFieldValue("personDOBMonth", value);
                                            handleChange("personDOBMonth")
                                        }}
                                        placeholder="Month"
                                    />
                                    <Dropdown
                                        className="formsWidth1 formsFlexChildGrow"
                                        label="&nbsp;"
                                        ariaLabel="Date Of Birth Year"

                                        options={yearsArray}

                                        description="your date of birth"
                                        disabled={false}
                                        errorMessage={touched.personDOBYear && errors.personDOBYear}
                                        onChange={(event, value) => {
                                            setFieldValue("personDOBYear", value);
                                            handleChange("personDOBYear")
                                        }}
                                        placeholder="Year"
                                    />
                                </div>
*/}

                                <StandardInput
                                    type = "radio"
                                    title = "Gender"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personGender"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                    options={genderArray}
                                />
                            </div>

                            <h3 className="questionGroupTitle">How can we contact you</h3>

                            <div className="formPanel">

                                <StandardInput
                                    type = "combo"
                                    title = "Country of residence"
                                    description = ""
                                    placeholder = "select country"
                                    helpText = ""
                                    fieldId = "personCountry"
                                    requiredVisual = {true}
                                    flexRow = {false}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {3}
                                    options={countryArray}
                                />


                                <StandardInput
                                    type = "text"
                                    title = "Address line 1"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personAddressLine1"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Address line 2"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personAddressLine2"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Address line 3"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personAddressLine3"
                                    requiredVisual = {false}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />

                                <StandardInput
                                    type = "text"
                                    title = "Address line 4"
                                    description = ""
                                    placeholder = ""
                                    helpText = ""
                                    fieldId = "personAddressLine4"
                                    requiredVisual = {false}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {0}
                                />



                                <Gated display={values.personCountry === "United Kingdom"}>
                                    <StandardInput
                                        type = "text"
                                        title = "Post Code"
                                        description = ""
                                        placeholder = ""
                                        helpText = ""
                                        fieldId = "personPostCode"
                                        requiredVisual = {true}
                                        flexRow = {true}
                                        flexGrow = {false}
                                        disabled = {hasApplied}
                                        additionalClass = ""
                                        multiline = {false}
                                        rowCount = {1}
                                        width = {2}
                                    />
                                </Gated>


                                <StandardInput
                                    type = "text"
                                    title = "Contact number"
                                    description = "Your personal contact number"
                                    placeholder = "+44 123456"
                                    helpText = ""
                                    fieldId = "personMobile"
                                    requiredVisual = {true}
                                    flexRow = {true}
                                    flexGrow = {false}
                                    disabled = {hasApplied}
                                    additionalClass = ""
                                    multiline = {false}
                                    rowCount = {1}
                                    width = {3}
                                />

                            </div>




                            <Gated display={hasApplied === false}>
                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary">Save page</Button>
                            </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    const formData = useContext(BaseContext).formData;

    return <PersonalInfo1 formData={formData} navigate={navigate} {...props} />;
}

