/** Component to selectively display its contents

 <StandardInput display=true>
 <div>This will display</div>
 </StandardInput>

 <StandardInput display=false>
 <div>This will not display</div>
 </StandardInput>

 StandardInput can also limit access by or combination with an auth role

 <StandardInput role="ROLE_BASIC">
 <div>This will display if the user has the ROLE_BASIC auth flag</div>
 </StandardInput>


 */
import React from 'react';
import AuthService from "../../util/AuthService";


export default function Gated(props) {
    //StandardInput can check for role and a boolean display attribute
    let roleOk = false;

    //Check if role is supplied, if yes validate it, if not we're ok to proceed with respect to role
    if (props.role != null) {
        //check if the user has the given role
        roleOk = AuthService.hasRole(props.role);
    } else {
        //no role supplied so we can continue as if their role matched
        roleOk = true;
    }

    //Check if display criteria is supplied, if yes check it returns true, if its not supplied
    // proceed as if the criteria returned true
    let displayOk = false;

    if (props.display != null) {
        displayOk = (props.display===true);
    } else {
        displayOk = true;
    }

    //If we haven;t flagged a negative on any of our checks return the body contents
    //else return an empty body
    if (roleOk && displayOk) {
        return (
            <>{props.children}</>
        )
    } else {
        return <></>;
    }
}
