import axios from "axios";
import jwt_decode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_TARGET;

class UserService {

  register(values) {
    const target_url = API_URL + "/auth/register";
    return axios.post(
        target_url,
        JSON.stringify(values),
        {headers: {'Content-Type': 'application/json'}}
    );
  }


}

export default new UserService();