import React from 'react';


import {useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import StandardInput from "../../../../components/StandardInput";
import BaseComponent, {countryArray} from "../../../../util/BaseComponent";
import BaseContext from "../../../../util/BaseContext";
import {DateTime, Info, Interval} from "luxon";
import {toast} from "react-toastify";
import Gated from "../../../../components/Gated";
import {Panel, PanelType} from "@fluentui/react";
import {Information} from "@carbon/icons-react";

/**
 * Validation for course overviews
 */
const baseValidationSchema = Yup.object().shape({
//    yearOfEntry: Yup.string().required("Please select your preferred year of entry"),
//    intake: Yup.string().required("Please select which month you would prefer to start").nullable(),
    intendedExitAward: Yup.string().required("Please select your intended exit award"),
    matriculationPoint: Yup.string().required("Please select your desired start date"),
});

const validationSchemaIntensity = Yup.object().shape({
//    yearOfEntry: Yup.string().required("Please select your preferred year of entry"),
//    intake: Yup.string().required("Please select which month you would prefer to start").nullable(),
    selectedIntensity: Yup.string().required("Please select your intended study intensity"),
});

const validationSchemaLocation= Yup.object().shape({
//    yearOfEntry: Yup.string().required("Please select your preferred year of entry"),
//    intake: Yup.string().required("Please select which month you would prefer to start").nullable(),
    selectedLocation: Yup.string().required("Please select your intended study location"),
});







/**
 *
 */
const initialValues = {
//    yearOfEntry: "",
//    intake: "",
    intendedExitAward: "",
    matriculationPoint: "",
}

/**
 * We need to display the text of some months and oddly that isn;t easily available
 */
const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];



/**
 * Handles the overview page in our applications process
 */
class Overview extends BaseComponent {
    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            initialValues: initialValues,
            navigateHome: false,

            isTransmitting: false,
            availableYears: [],
            availableIntakes: [],
            availableUnits: [],

            hasSelectableUnits: false,   //Do we want to make unit selection visible
            sidePanelVisible: false,
        }
    }


    componentDidMount() {
        //Parse our formDate and retrieve the items we're interested in

        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }


    /**
     * Parse our returned formData and set up our data structure for the UI
     */
    processFormData() {

        let formData = this.context.formData;

        let unitSelections = formData.unitSelections;
        let selectedUnits = [];

        for (let i = 0; i < unitSelections.length; i++) {
            selectedUnits.push(unitSelections[i].unitId + "")
        }




        //set up the availableYears
//        let availableYears = this.getAvailableYears(formData.courseDetails.availableYears);

        //set up the availableIntakes
//        let availableIntakes = this.getAvailableIntakes(formData.courseDetails.intakes);

        //set up the availableAwards
        let availableAwards = this.getAvailableAwards(formData.courseDetails.courseAwards);

        //set up available units
        let availableUnits = this.getAvailableUnits(formData.courseDetails.courseUnitLinks);

        let availableLocations = this.getAvailableLocations(formData.courseDetails.availableLocations);

        let availableIntensities = this.getAvailableIntensities(formData.courseDetails.availableIntensities);





        //We want to provide the applicant with a view of possible start dates not the separate drop downs stuff
        //we've used historically
        let availableMatriculationPoints = this.getMatriculationPoints(formData.courseDetails.availableYears, formData.courseDetails.intakes);
        let initialMatriculationPoint = '';


        if (formData.yearOfEntry !== undefined && formData.yearOfEntry !== null && formData.yearOfEntry !== '') {
            //We have an existing entry point that we need to prefill, we need to ensure that our availableMatriculationPoints
            //includes the value we're getting here as older applications will not have the correct entries in the options list

            let yearPart = parseInt(formData.yearOfEntry.substring(0,4));
            let calendarYear = yearPart;

            if (formData.intake < 9) {
                initialMatriculationPoint = (yearPart + 1).toString() + ":" + formData.intake;
                calendarYear = yearPart + 1;
            } else {
                initialMatriculationPoint = yearPart + ":" + formData.intake;
            }

            //We now have the matriculation point set but there may not be a corresponding entry in our drop down
            //for older courses

            let item = {
                key: initialMatriculationPoint,
                text: monthNames[formData.intake - 1] + " " + calendarYear
            }


            //For historic applications we need to ensure that the original item is available in the drop downs even
            //if its not a "current" offering

            let foundElement = availableMatriculationPoints.find((element) => element.text === item.text);
            if (foundElement === undefined) {
                availableMatriculationPoints.push(item);
            }
        }





        let initialValues = {
            ...this.state.initialValues,
            courseName: formData.courseDetails.courseName,
//            yearOfEntry: formData.yearOfEntry,
//            intake: formData.intake,
            intendedExitAward: formData.intendedExitAward,
//            selectedUnits: formData.selectedUnits,
            selectedUnits: selectedUnits,

            selectedIntensity: formData.selectedIntensity,
            selectedLocation: formData.selectedLocation,

            //TODO generate this from input
            matriculationPoint: initialMatriculationPoint,


        }


        this.setState({
            initialValues: initialValues,
//            availableYears: availableYears,
//            availableIntakes: availableIntakes,
            availableAwards: availableAwards,
            availableUnits: availableUnits,
            availableIntensities: availableIntensities,
            availableLocations: availableLocations,

            availableMatriculationPoints: availableMatriculationPoints,

        })

    }

    /**
     * Parses the coursedata sent through and generates a list of available years
     */
    getAvailableYears(sourceYears) {
        let availableYears = [];

        if (Array.isArray(sourceYears) && sourceYears.length > 0) {
            sourceYears.forEach(yearString => {
                let item = {
                    key: yearString,
                    text: yearString
                }
                availableYears.push(item);
            })
        } else {

            //Data not supplied in the couseInfo bundle generate some dates for testing
            let currentYear = DateTime.now().toFormat('yyyy');
            let futureYear = DateTime.now().plus({months: 1}).toFormat('yy');
            let suggestedYear = currentYear + "/" + futureYear;

            let item = {
                key: suggestedYear,
                text: suggestedYear
            }

            // let item = {
            //     key: "2023/2024",
            //     text: "2023/2024"
            // }


            availableYears.push(item);
        }
        return availableYears;
    }

    /**
     * Parses the coursedata sent through and generates a list of available intakes
     */
    getAvailableIntakes(sourceIntakes) {
        let availableIntakes = [];

        if (Array.isArray(sourceIntakes) && sourceIntakes.length > 0) {
            sourceIntakes.forEach(intake => {

                let monthString = Info.months('long')[intake.startMonth - 1];

                let item = {
                    key: intake.startMonth,
                    text: monthString
                }
                availableIntakes.push(item);
            })
        } else {
            //Use a default September intake
            let item = {
                key: 9,
                text: "September"
            }
            availableIntakes.push(item);
        }
        return availableIntakes;
    }

    /**
     * Parses the available awards and generates a list of those available
     */
    getAvailableAwards(sourceAwards) {

        let availableAwards = [];

        if (Array.isArray(sourceAwards) && sourceAwards.length > 0) {
            sourceAwards.forEach(awardDetails => {
                let item = {
                    key: awardDetails.award,
                    text: awardDetails.award
                }
                availableAwards.push(item);
            })
        } else {
            //  let suggestedYear = "suggestedYear"
            let item = {
                key: "Default",
                text: "Default"
            }
            availableAwards.push(item);
        }
        return availableAwards;
    }


    getAvailableUnits(sourceUnits) {
        let availableUnits = [];

        if (Array.isArray(sourceUnits) && sourceUnits.length > 0) {
            sourceUnits.forEach(unit => {
                let item = {
                    id: unit.id,
                    credits: unit.unitID.credits,
                    name: unit.unitID.officialUnitName,
                    description: unit.unitID.description,
                }
                availableUnits.push(item);
            })
        }

        return availableUnits;
    }




    getAvailableIntensities(intensities) {

        let availableIntensities = [];

        if (Array.isArray(intensities) && intensities.length > 0) {
            intensities.forEach(intensity => {
                let item = {
                    key: intensity,
                    text: intensity
                }
                availableIntensities.push(item);
            })
        } else {
            //No defaults here

        }
        return availableIntensities;
    }


    getAvailableLocations(locations) {

        let availableLocations = [];

        if (Array.isArray(locations) && locations.length > 0) {
            locations.forEach(location => {
                let item = {
                    key: location,
                    text: location
                }
                availableLocations.push(item);
            })
        } else {
            //No defaults here

        }
        return availableLocations;
    }



    /**
     * Generate a set of matriculation points
     *
     * //TODO this relies in the local calendar, change this to use a server derived date to ensure
     * //we don;t end up with weird submissions, or difficulty with negative values
     */
    getMatriculationPoints(availableYears, intakes) {
        //available years is the next three years according to the server, we'll assume that the local month is correct
        let matriculationPoints = [];

        let currentDate = DateTime.now();

        function getEndDay(startMonth) { // 1 based month
            switch(startMonth) {
                case 1:
                    return 31;
                case 2:
                    return 28;
                case 3:
                    return 31;
                case 4:
                    return 30;
                case 5:
                    return 31;
                case 6:
                    return 30;
                case 7:
                    return 31;
                case 8:
                    return 31;
                case 9:
                    return 30;
                case 10:
                    return 31;
                case 11:
                    return 30;
                case 12:
                    return 31;
            }
        }

        for (let i = 0; i < availableYears.length; i++) {
            for (let j = 0; j < intakes.length; j++) {
                /**
                 *  We want to add a possible matriculation point for each year/intake combo that's
                 *  no more than 1 month ago and not more than 18 months in the future
                 */
                let possibleDate = DateTime.utc(parseInt(availableYears[i]),intakes[j].startMonth, getEndDay(intakes[j].startMonth));
                let dateDelta = Interval.fromDateTimes(currentDate,possibleDate);

                if (dateDelta.length('months') < 18) {
                    let item = {
                        key: availableYears[i] + ":" + intakes[j].startMonth,
                        text: monthNames[intakes[j].startMonth -1] + " " + availableYears[i]
                    }
                    matriculationPoints.push(item);
                }
            }
        }

        return matriculationPoints;
    }


    //No longer required.
    // getAvailableUnits2(sourceUnits) {
    //     let availableUnits = [];
    //
    //     if (Array.isArray(sourceUnits) && sourceUnits.length > 0) {
    //         sourceUnits.forEach(unit => {
    //             let item = {
    //                 id: unit.id,
    //                 credits: unit.unitID.credits,
    //                 name: unit.unitID.officialUnitName,
    //                 description: unit.unitID.description,
    //             }
    //
    //             if (unit.id === 790) {
    //                 availableUnits.push(item);
    //             }
    //
    //             if (unit.id === 915) {
    //                 availableUnits.push(item);
    //             }
    //
    //             if (unit.id === 925) {
    //                 availableUnits.push(item);
    //             }
    //
    //             if (unit.id === 911) {
    //                 availableUnits.push(item);
    //             }
    //
    //         })
    //     }
    //
    //     return availableUnits;
    // }


    /* We're submitting to an interim object */
    submitForm(values) {


        //Validate that we have possible combinations selected, online is available part time, bournemouth/London are not

        let selectedLocation = values.selectedLocation;
        let selectedIntensity = values.selectedIntensity;



        if (selectedLocation !== undefined && selectedLocation !== null && selectedLocation !== "Online" && values.courseName === "MSc Sport and Exercise Psychology") {
            if (selectedIntensity !== "Full Time") {
                toast.error(
                    "Unfortunately we don't currently offer onsite teaching part time. Please select Full Time intensity"
                    , {
                        // autoClose: 5000,
                        className: "toastError",
                    });
                return;
            }
        }



        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitOverview(values, this.context.applicationFormId)
            .then(
                (response) => {
                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }

    render() {
        //Formik data, are we transmitting what are our defaults
        const {isTransmitting, initialValues, navigateHome, hasUnits, sidePanelVisible} = this.state;

        //Our drop down options
        const {
           // availableYears,
           // availableIntakes,
            availableAwards,
            availableUnits,
            availableMatriculationPoints,
            availableIntensities,
            availableLocations
        } = this.state;


        const popupStyles = {root: {display: 'inline-block'}}

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }


        //Sort out the client side validation schema

        let validationSchema = baseValidationSchema;

        if(availableLocations !== undefined && availableLocations.length > 0) {
            validationSchema = validationSchema.concat(validationSchemaLocation);
        }

        if(availableIntensities !== undefined && availableIntensities.length > 0) {
            validationSchema = validationSchema.concat(validationSchemaIntensity);
        }




        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                            <h3 className="questionGroupTitle">Course overview</h3>

                            {/*{values.courseName === "MSc Professional Practice" ? (*/}
                            {/*    <p className="informationText">You're applying for: Unit CPD7006 The Deteriorating patient</p>*/}
                            {/*) : (*/}
                            {/*    <p className="informationText">You're applying for: <strong>{values.courseName}</strong></p>*/}
                            {/*)}*/}

                            <p className="informationText">You're applying for: <strong>{values.courseName}</strong></p>
                            <p>&nbsp;</p>

                            <div className="formPanel">

                                {/*<div className="formsFlex">*/}

                                {/*    <StandardInput*/}
                                {/*        type="dropDown"*/}
                                {/*        title="Academic year"*/}
                                {/*        description="The academic year you wish to apply for"*/}
                                {/*        placeholder="select year"*/}
                                {/*        helpText=""*/}
                                {/*        fieldId="yearOfEntry"*/}
                                {/*        requiredVisual={true}*/}
                                {/*        flexRow={true}*/}
                                {/*        flexGrow={false}*/}
                                {/*        disabled={hasApplied}*/}
                                {/*        additionalClass=""*/}
                                {/*        multiline={false}*/}
                                {/*        rowCount={1}*/}
                                {/*        width={2}*/}
                                {/*        options={availableYears}*/}
                                {/*    />*/}


                                {/*    <StandardInput*/}
                                {/*        type="dropDown"*/}
                                {/*        title="Entry point"*/}
                                {/*        description="Your entry point during the year"*/}
                                {/*        placeholder="select entry point"*/}
                                {/*        helpText=""*/}
                                {/*        fieldId="intake"*/}
                                {/*        requiredVisual={true}*/}
                                {/*        flexRow={true}*/}
                                {/*        flexGrow={false}*/}
                                {/*        disabled={hasApplied}*/}
                                {/*        additionalClass=""*/}
                                {/*        multiline={false}*/}
                                {/*        rowCount={1}*/}
                                {/*        width={2}*/}
                                {/*        options={availableIntakes}*/}
                                {/*    />*/}
                                {/*</div>*/}


                                <Gated display={availableLocations != undefined && availableLocations.length > 0}>
                                    <StandardInput
                                        type="dropDown"
                                        title="Preferred study location"
                                        description="The campus location you would prefer"
                                        placeholder="select location"
                                        helpText=""
                                        fieldId="selectedLocation"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={3}
                                        options={availableLocations}
                                    />
                                </Gated>


                                <Gated display={availableIntensities != undefined && availableIntensities.length > 0}>
                                    <StandardInput
                                        type="dropDown"
                                        title="Preferred study intensity"
                                        description="Whether you'd like to apply for a part time or full time course"
                                        placeholder="select intensity"
                                        helpText=""
                                        fieldId="selectedIntensity"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={3}
                                        options={availableIntensities}
                                    />
                                </Gated>


                                <StandardInput
                                    type="dropDown"
                                    title="Desired entry point"
                                    description="The point at which you'd like to start your studies"
                                    placeholder="select entrypoint"
                                    helpText=""
                                    fieldId="matriculationPoint"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={availableMatriculationPoints}
                                />

                                <StandardInput
                                    type="dropDown"
                                    title="Intended exit award"
                                    description="The exit award you intend to aim for"
                                    placeholder="select award"
                                    helpText=""
                                    fieldId="intendedExitAward"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={availableAwards}
                                />







                            </div>


                            <Gated display={hasUnits}>

                                {availableUnits.length > 0 && (
                                    <>

                                        <h3 className="questionGroupTitle">Course units</h3>

                                        <p className="informationText">Please select any optional units you're
                                            interested in</p>

                                        <p>&nbsp;</p>

                                        <div className="formPanel">
                                            <table width="100%">
                                                {availableUnits.map((unit, index) => (
                                                    <>
                                                        <tr>
                                                            <td className="checkboxCell"><Field
                                                                className="unitCheckbox" type="checkbox"
                                                                name="selectedUnits" value={`${unit.id}`}/></td>
                                                            {/*<td className = "creditsCell">{unit.credits}</td>*/}
                                                            <td className="nameCell"><p>{unit.name}</p></td>
                                                            <td className="infoCell">

                                                                <Information className="informationIcon" size={16}
                                                                             onClick={
                                                                                 () => {
                                                                                     this.setState({
                                                                                         sidePanelVisible: true,
                                                                                         sidePanelUnitName: unit.name,
                                                                                         sidePanelCredits: unit.credits,
                                                                                         sidePanelDescription: unit.description
                                                                                     })
                                                                                 }
                                                                             }/>
                                                                <Panel
                                                                    headerText="Unit Information"
                                                                    isOpen={sidePanelVisible}
                                                                    isLightDismiss={true}
                                                                    onDismiss={() => {
                                                                        this.setState({sidePanelVisible: false})
                                                                    }}
                                                                    closeButtonAriaLabel="close"
                                                                    type={PanelType.medium}
                                                                >
                                                                    <div className="paragraphTitle">Unit name</div>
                                                                    <p className="informationText">{this.state.sidePanelUnitName}</p>
                                                                    <div className="paragraphTitle">Academic
                                                                        credit
                                                                    </div>
                                                                    <p className="informationText">{this.state.sidePanelCredits}</p>
                                                                    <div className="paragraphTitle">Unit
                                                                        description
                                                                    </div>
                                                                    <p className="informationText withWhitespace">{this.state.sidePanelDescription}</p>
                                                                </Panel>
                                                            </td>
                                                            {/*<p>{unit.description}</p>*/}
                                                        </tr>
                                                    </>
                                                ))}
                                            </table>
                                        </div>
                                    </>
                                )}
                            </Gated>


                            <Gated display={hasApplied === false}>
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }


}


export default function (props) {
    //We're using navigation to handle internal links
    const navigate = useNavigate();

    //Load in our form session data via hook and pass it to our class, pita doing it this way but nice to have the
    //class lifecycle methods and inheritance available
//    const {applicationFormId, targetCourse, formData, hasFormLoaded} = useContext(BaseContext);

    return <Overview
        navigate={navigate}
        //        applicationFormId={applicationFormId}
        //        targetCourse={targetCourse}
        //        hasFormLoaded={hasFormLoaded}

        {...props} />;
}

