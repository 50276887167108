import React  from 'react';
import {
    Button,
    ButtonSet,
    Column,
    Content,
    DataTable,
    Grid,
    Row,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableHeader, TableRow
} from "@carbon/react";

import {Dropdown, DropdownMenuItemType} from "@fluentui/react";
import BaseComponent, {defaultToastErrorProps, defaultToastWarningProps} from "../../util/BaseComponent"
import {useNavigate} from "react-router-dom";
import CourseService from "../../util/CourseService";
import ApplicationService from "../../util/ApplicationService";
import { DateTime } from 'luxon'
import BaseContext from "../../util/BaseContext";
import Gated from "../../components/Gated";
import {toast} from "react-toastify";
import ToastMessage from "../../components/ToastMessage";


const applicationHeaders = [

    {key: "id", header: "id", isSortable: false, display: false},
    {key: "courseName", header: "Course", isSortable: false, display: true},
    {key: "entryYear", header: "Academic year", isSortable: false, display: true},
    {key: "creationDate", header: "Date", isSortable: false, display: true},
    {key: "applicationStatus", header: "Actions", isSortable: false, display: true},
//    {key: "submitted", header: "Submitted", isSortable: false, display: true},

];

const dropdownStyles = {
    dropdown: {width: 500, marginTop: 10},
};

/**
 * Handles the display of the existing courses the applicant has applied for
 */
class CourseList extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        let targetCourse = sessionStorage.getItem("targetCourse")
        let targetTitle = sessionStorage.getItem("targetTitle")


        this.state = {
            applications: [],
            targetCourse: targetCourse,
            targetTitle: targetTitle,
            coursesLookup: [],
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {

        let {setShowCourseHeader} = this.context;

        //Reset the formdata
        setShowCourseHeader(false);

        ///Pull in the applicants current applications
        this.getCurrentApplications();

        //Pull in the current active application list
        this.generateCourseList("all");
    }

    generateCourseList(filter) {

        let retrievingCourses = this.state.retrievingCourses;

        //We're generating a new course list we can overwrite any current lists

        if (!retrievingCourses) {
            this.setState({retrievingCourses: true})

            CourseService.getFullCourseList()
                .then(
                    response => {
                        /*
                        Process the received response into a format suitable for our fluid components,
                        */

                        let courses = response.data;

                        let coursesLookup = [];

                        coursesLookup.push({
                            key: 'DegreeBreak',
                            text: 'Full courses',
                            itemType: DropdownMenuItemType.Header
                        })


                        for (let i = 0; i < courses.length; i++) {

                            if (courses[i].recruitByUnits) {
                                //Skip recruit by units courses these will be handled in the next section
                            } else {
                                //Have to use database course name here to allow for the per month stuff
                                //let courseName = courses[i].courseName;
                                let courseName = courses[i].databaseCourseName;

                                coursesLookup.push({
                                    key: courses[i].id,
                                    text: courseName
                                })
                            }
                        }

                        coursesLookup.push({
                            key: 'unitsBreak',
                            text: 'Modular courses',
                            itemType: DropdownMenuItemType.Header
                        })

                        for (let i = 0; i < courses.length; i++) {

                            if (courses[i].recruitByUnits) {
                                let courseName = courses[i].courseName;

                                // if (courseName === "MSc Professional Practice") {
                                //     courseName = "Unit CPD7006 The Deteriorating patient";
                                // }


                                coursesLookup.push({
                                    key: courses[i].id,
                                    text: courseName
                                })
                            } else {

                            }
                        }

                        this.setState({
                            coursesLookup: coursesLookup
                        })
                    }
                )
                .catch(
                    error => {
//                        let parsedError = this.parseError(error)
                        console.log(error);
                        let toastId = toast(
                            <ToastMessage title="Warning"
                                          message="Unable to retrieve course list. Please reload the web page and try again"/>
                            , defaultToastErrorProps);
                    }
                )
                .finally(
                    () => {

                        this.setState({
                            retrievingCourses: false,
                        })
                    }
                )
        } else {
            let toastId = toast(
                <ToastMessage title = "Warning" message = "Already retrieving list, please wait" />
                , defaultToastWarningProps);
        }

    }


    /**
     * Pull back details for a singular course
     *
     * @param courseId
     */
//     getTargetCourse(courseId) {
//         CourseService.getCourse(courseId)
//             .then(
//                 response => {
//                     /*
//                     Process the received response into a format suitable for our fluid components,
//                     */
//
//                     let targetCourse = response.data;
//                     this.setState( {
//                         targetCourse:targetCourse
//                     })
//
//                     //     console.log(courses);
//                 }
//             )
//             .catch(
//                 error => {
//                     let parsedError = this.parseError(error)
//
//                     // this.displayError("error",parsedError.title, parsedError.message,this.props.notification, this.props.setNotification )
//
//
//                     this.setState({
//                             displayNotification: true,
//                             notificationTitle: parsedError.title,
//                             notificationSubtitle: parsedError.message,
//                             notificationKind: "error",
//                         }
//                     )
//                 }
//             )
//             .finally(
//                 () => {
//                     //Update the state with our current knowledge, we're not in a request and we have a course List
//
//                     this.setState({
// //                        retrievingCourses: false,
//
//
//                     })
//                 }
//             )
//
//     }


    /**
     * Return the list of applications for this user
     *
     */
    getCurrentApplications() {


        ApplicationService.getExistingApplicationsList()
            .then(
                response => {
                    /*
                    Process the received response into a format suitable for our fluid components,
                    */

                    let applications = response.data;
                    this.setState( {
                        applications:applications
                    })

                    //     console.log(courses);
                }
            )
            .catch(
                error => {
                    let parsedError = this.parseError(error)

                    // this.displayError("error",parsedError.title, parsedError.message,this.props.notification, this.props.setNotification )


                    this.setState({
                            displayNotification: true,
                            notificationTitle: parsedError.title,
                            notificationSubtitle: parsedError.message,
                            notificationKind: "error",
                        }
                    )
                }
            )
            .finally(
                () => {
                    //Update the state with our current knowledge, we're not in a request and we have a course List

                    this.setState({
//                        retrievingCourses: false,


                    })
                }
            )
    }

    /**
     * Call the server and start a new application for this user
     *
     * @param courseId
     */
    startApplication(courseId, courseTitle) {

//        let courseId = this.state.applyToCourseId;

                ApplicationService.startApplication(courseId)
                    .then(
                        response => {
                            //should return an applicationId we want to route to that
                            let application = response.data;

                            //We want the formId
                            let appId = application.id;

                            this.context.setApplicationFormId(appId);
                            this.getApplicationFormData(appId)
                            this.context.setShowCourseHeader(true);

                            this.props.navigate("/apply/form/personalInfo1");

                        }
                    )
                    .catch(
                        error => {
                            let parsedError = this.parseError(error)
                           this.setState({
                                    displayNotification: true,
                                    notificationTitle: parsedError.title,
                                    notificationSubtitle: parsedError.message,
                                    notificationKind: "error",
                                }
                            )
                        }
                    )
                    .finally(
                        () => {
                            this.setState({
                            })
                        }
                    )

    }


    render() {

        const {applications, targetCourse, targetTitle, coursesLookup} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;


        if (this.context.showCourseHeader ) {
                this.context.setShowCourseHeader(false);
        }

        let applyToCourseId =  parseInt(targetCourse);
        let applyToCourseTitle = targetTitle;


        return (
            <>
                <Content>
                    <Grid>
                        <Row>

                            <Column className = "applyList fullWidth">

                                <Gated display = {applications.length > 0} >

                                <h4>Applications in progress</h4>

                                <DataTable
                                    isSortable
                                    rows={applications}
                                    headers={applicationHeaders}
                                    overflowMenuOnHover={false}
                                >
                                    {({
                                          rows,
                                          headers,
                                          getHeaderProps,
                                          getRowProps,
                                          getTableProps,
                                          getTableContainerProps,
                                      }) => (
                                        <TableContainer
                                            // title="Active applications"
                                            // description="List of active applications"

                                            //className = "limitListWidth"
                                            {...getTableContainerProps()}>

                                            <Table  {...getTableProps()}>
                                                <TableHead>
                                                    <TableRow>
                                                        {/*         <TableExpandHeader/> */}
                                                        {headers.map((header, i) => (
                                                            <>
                                                            {header.display && (
                                                                <TableHeader {...getHeaderProps({
                                                                    header,
                                                                    key: String(i)  ,
                                                                    isSortable: header.isSortable,
                                                                    colSpan: header.colSpan
                                                                })  }>
                                                                    {header.header}
                                                                </TableHeader>
                                                            )}
                                                            </>
                                                        ))}
                                                        <TableHeader>

                                                        </TableHeader>

                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>

                                                    {rows.map((row,k) => (
                                                        <>


                                                            <>
                                                            <TableRow    {...getRowProps({row })}>
                                                                {row.cells.map((cell, j) => (
                                                                    <>
                                                                        {this.formatApplicationCell(row,cell)}
                                                                    </>
                                                                ))}
                                                            </TableRow>


                                                            {/*
                                                            <TableExpandedRow {...getRowProps({row})} colSpan={headers.length + 3} >
                                                            </TableExpandedRow>
                                                            */}

                                                        </>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </DataTable>


                                </Gated>


                            </Column>
                        </Row>


                        <Row>
                            <Column className = "applyList fullWidth" >

                                <p>&nbsp;</p>
                                <h4>New application</h4>

                                <p>&nbsp;</p>

                                <div className="contentInstruction">Use the dropdown to select a course and then use the apply button to start your application form</div>
                                <Dropdown options={coursesLookup}
                                          styles={dropdownStyles}
                                          defaultSelectedKey={parseInt(targetCourse)}
                                          onChange={(e, value) => {
  //                                            alert(value.key);
//                                                this.setState({applyToCourseId: value.key})
                                              applyToCourseId = value.key
                                              applyToCourseTitle = value.title
                                              //    setFieldValue(`learningDomainsHalfStudent.${sectionCount * 9 + subSectionCount * 3 + questionCount}`, value.key)
                                              //    handleChange(`learningDomainsHalfStudent.${sectionCount * 9 + subSectionCount * 3 + questionCount}`)
                                          }}

                                />
                                <p>&nbsp;</p>

                                <ButtonSet>
                                    <Button type="button" kind = "primary" onClick = {()=>{
                                        this.startApplication(applyToCourseId, applyToCourseTitle )
                                    }} >Start Application</Button>
                                </ButtonSet>

                            </Column>
                        </Row>
                    </Grid>
                </Content>
            </>
        );
    }


    formatApplicationCell(row,cell) {

        switch (cell.info.header) {
            case "id":
               // let url = "/apply/" + cell.value
               // return (<a href = {url}>{cell.value}</a> );
                break

                // return (
                //     <TableCell>
                //         <p onClick = {()=>{this.continueApplication(row.cells[0].value )}}>
                //             {cell.value}
                //         </p>
                //     </TableCell>
                //);
            case "courseName":
                return (
                    <TableCell>
                        {cell.value}
                    </TableCell>
                );
            case "entryYear":
                return (
                    <TableCell>
                        {cell.value}
                    </TableCell>
                );
            case "creationDate":
                //Return an ISO formatted date without the time parts
                return (
                    <TableCell>
                        {DateTime.fromISO(cell.value).toISODate()}
                    </TableCell>
                );
            case "applicationStatus":
                if (cell.value === "Incomplete") {
                    return (
                        <TableCell>
                            <ButtonSet className = "buttonMargin">
                                <Button type = "button" kind = "primary" onClick = {() => {
                                    this.continueApplication(row.cells[0].value )
                                }
                                }>Continue</Button>
                            </ButtonSet>
                        </TableCell>
                    )

                } else if (cell.value === "Submitted") {
                    return (
                        <TableCell>
                            <ButtonSet className = "buttonMargin">
                                <Button type = "button" kind = "primary" onClick = {() => {
                                    this.continueApplication(row.cells[0].value )
                                }}>Modify</Button>
                            </ButtonSet>
                        </TableCell>
                    )
                } else {
                    return (
                        <TableCell>
                            Unknown
                        </TableCell>
                    );
                }
            case "submitted":
                if (cell.value === true) {
                    return (
                        <TableCell>
                            Yes
                        </TableCell>
                    )
                } else {
                    return (
                        <TableCell>
                            No
                        </TableCell>
                    )
                }
            default:
                return (<TableCell>unknown value</TableCell>)
        }
    }



    continueApplication(appId) {
        this.context.setApplicationFormId(appId);

        this.getApplicationFormData(appId)

        this.context.setShowCourseHeader(true);

        this.props.navigate("/apply/form/personalInfo1")
    }

}

export default function (props) {
    const navigate = useNavigate();
    return <CourseList navigate = {navigate} {...props} />;
}