import React from 'react';

import BaseComponent, {
    englishQualificationOptions,
} from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {FieldArray, Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import * as Yup from "yup";
import {toast} from "react-toastify";


const validationSchema = Yup.object().shape({

    languageTests: Yup.array().when(['nativeSpeaker'], {
        is: false,
        then: Yup.array().of(
            Yup.object().shape({
                type: Yup.string().required("Please select the qualification type"),

                trfnumber: Yup.string().when(['type'], {
                    is: (value) => {
                        if (value === 'IELTS' || value === 'TOEFL') {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    then: Yup.string().required("Please enter your IELTS/TOEFL form number")
                }   ),

                qualification: Yup.string().when(['type'], {
                    is: (value) => {
                        if (value === 'Other') {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    then: Yup.string().required("Please enter the name of the qualification")
                }   ),

                date: Yup.string().nullable().when(['type'], {
                    is: (value) => {
                        if (value === 'Other') {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    then: Yup.string().nullable().required("Please enter the date you attained this qualification")
                }   ),

                details: Yup.string().nullable().when(['type'], {
                    is: (value) => {
                        if (value === 'Other') {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    then: Yup.string().nullable().required("Please provide a brief description of the nature of this qualification")
                }   ),

            })
        )
    } ),
});



const initialValues = {
    nativeSpeaker: false,

    languageTests: [
        {
            type: "",
            qualification: "",
            trfnumber: "",
            date: "",
            details: "",
        },
    ],
}

class LanguageQualifications extends BaseComponent {

    static contextType = BaseContext;
    constructor(props) {
        super(props);

        this.state = {
            initialValues: initialValues,
            navigateHome: false,

            isTransmitting: false,

            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }


    processFormData() {

        let data = this.context.formData;

        let nativeSpeaker = data.nativeSpeaker;
        if (nativeSpeaker === null || nativeSpeaker === 'undefined') {
            nativeSpeaker = false;
        }

                   let initialValues = {
                        nativeSpeaker: nativeSpeaker,
                        languageTests: data.languageTests,
                    }


                    //Check if we've received sufficient languageTest objects or do we need to create a base one

                    if (data.languageTests.length < 1) {
                        initialValues.languageTests = [{
                                type: "",
                                qualification: "",
                                trfnumber: "",
                                date: "",
                                details: "",
                        }]
                    }

                    this.setState({
                        initialValues: initialValues
                    })


    }


    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitLanguages(values,this.context.applicationFormId)
            .then(
                (response) => {

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });


                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);

                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });

                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )

    }


    render() {

        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {


                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>





                            <h3 className="questionGroupTitle">Language qualifications</h3>
                            <p class = "informationText dropDownPadding">Please enter the details of any english language tests you have taken in the past 2 years
                                or indicate that you speak english natively</p>

                            <div className="formPanel">
                                <StandardInput
                                    type="checkbox"
                                    title="I speak English natively"
                                    description="description"
                                    placeholder=""
                                    helpText=""
                                    fieldId="nativeSpeaker"
                                    required={false}
                                    flexRow={true}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={0}
                                />
                            </div>


                            <p className="informationText dropDownPadding">or indicate your English qualifications</p>

                                <Gated display={!(values.nativeSpeaker === true) }>


                                    <FieldArray name={"languageTests"}>
                                        {({push, remove}) => (
                                            <div>
                                                {
                                                    values.languageTests.length > 0 &&
                                                    values.languageTests.map((languageTest, index) => (
                                                        <div className = "formPanel">
                                                            <StandardInput
                                                                type="dropDown"
                                                                title="English Qualification"
                                                                description=""
                                                                placeholder="select qualification"
                                                                helpText=""
                                                                fieldId={`languageTests[${index}].type`}
                                                                required={true}
                                                                flexRow={false}
                                                                flexGrow={false}
                                                                disabled={hasApplied}
                                                                additionalClass=""
                                                                multiline={false}
                                                                rowCount={1}
                                                                width={3}
                                                                options={englishQualificationOptions}
                                                            />

                                                        <Gated display={values.languageTests[index].type !== "Other"}>
                                                            <StandardInput
                                                                type="text"
                                                                title="IELTS/TOEFL form number (TRF number)"
                                                                description=""
                                                                placeholder=""
                                                                helpText=""
                                                                fieldId={`languageTests[${index}].trfnumber`}
                                                                requiredVisual={true}
                                                                flexRow={false}
                                                                flexGrow={false}
                                                                disabled={hasApplied}
                                                                additionalClass=""
                                                                multiline={false}
                                                                rowCount={1}
                                                                width={0}
                                                            />
                                                        </Gated>

                                                        <Gated display={values.languageTests[index].type === "Other"}>
                                                            <StandardInput
                                                                type="text"
                                                                title="Qualification name"
                                                                description=""
                                                                placeholder=""
                                                                helpText=""
                                                                fieldId={`languageTests[${index}].qualification`}
                                                                requiredVisual={true}
                                                                flexRow={false}
                                                                flexGrow={false}
                                                                disabled={hasApplied}
                                                                additionalClass=""
                                                                multiline={false}
                                                                rowCount={1}
                                                                width={0}
                                                            />

                                                            <StandardInput
                                                                type="date"
                                                                title="Date obtained"
                                                                description=""
                                                                placeholder="dd/mm/yyyy"
                                                                helpText=""
                                                                fieldId={`languageTests[${index}].date`}
                                                                requiredVisual={true}
                                                                flexRow={false}
                                                                flexGrow={false}
                                                                disabled={hasApplied}
                                                                additionalClass=""
                                                                multiline={false}
                                                                rowCount={1}
                                                                width={2}
                                                            />

                                                            <StandardInput
                                                                type="text"
                                                                title="Details of qualification"
                                                                description="Please provide further details about this qualification"
                                                                placeholder=""
                                                                helpText=""
                                                                fieldId={`languageTests[${index}].details`}
                                                                requiredVisual={true}
                                                                flexRow={false}
                                                                flexGrow={false}
                                                                disabled={hasApplied}
                                                                additionalClass=""
                                                                multiline={true}
                                                                rowCount={5}
                                                                width={0}
                                                            />


                                                        </Gated>


                                                            <ButtonSet className = "addRemoveButtons">
                                                                <button type="button" className={"addButton"}
                                                                        onClick={() => push({
                                                                            type: "",
                                                                            qualification: "",
                                                                            trfnumber: "",
                                                                            date: "",
                                                                            details: "",
                                                                            })}>&#10010;
                                                                </button>
                                                                {index > 0 && (
                                                                    <button type="button" className={"removeButton"}
                                                                            onClick={() => remove(index)}>&#10006;
                                                                    </button>
                                                                )}
                                                            </ButtonSet>



                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </FieldArray>

                                </Gated>

                            <Gated display={hasApplied === false}>
                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary" onClick={()=>{ console.log("button:",errors) }}


                                >Save page</Button>
                            </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>





            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <LanguageQualifications navigate={navigate} {...props} />;
}

