import React, {Component, useContext} from 'react';
import {atom, useRecoilState} from "recoil";
import {DropdownMenuItemType} from "@fluentui/react";
import ApplicationService from "./ApplicationService";
import BaseContext from "./BaseContext";
import {toast} from "react-toastify";

export const checkboxOptions = [
    { key: "reqUpperLimb", code: "1", text: "Upper limb"},
    { key: "reqLowerLimb", code: "2", text: "Lower limb"},
    { key: "reqHip", code: "3", text: "Hip"},
    { key: "reqSoftTissue", code: "4",  text: "Soft tissue"},
    { key: "reqAbdominal", code: "5", text: "Abdominal"},
    { key: "reqSuperficialStructures", code: "6", text: "Superficial structures"},
    { key: "reqFirstTrimester", code: "7", text: "1st Trimester"},
    { key: "reqSecondTrimester", code: "8", text: "2nd Trimester"},
    { key: "reqGynaecology", code: "10", text: "Gynaecology"},
    { key: "reqGuidedInjections", code: "11", text: "2nd Trimester"},
    { key: "reqNegotiatedSkill1", code: "12", text: "Negotiated skill 1 (Specify)"},
    { key: "reqNegotiatedSkill2", code: "13", text: "Negotiated skill 2 (Specify)"}
]

export const qualificationTypeArray = [
    {key: 'under-uk', text: "Undergraduate degree (UK)"},
    {key: 'post-uk', text: "Postgraduate degree (UK)"},
    {key: 'under-intl', text: "Undergraduate degree (None UK)"},
    {key: 'post-intl', text: "Postgraduate degree (None UK)"},
    {key: 'other', text: "Other"},
]

export const englishQualificationOptions = [
    {key: 'IELTS', text: "IELTS (International English language testing system"},
    {key: 'TOEFL', text: "TOEFL"},
    {key: 'Other', text: "Other"},
]

export const qualificationPossessedArray = [
    {key: 'Undergraduate degree', text: "Undergraduate degree"},
    {key: 'Master\'s degree', text: "Master\'s degree"},
    {key: 'PhD', text: "PhD"},
    {key: 'PgCert', text: 'PgCert'},
    {key: 'PgDip', text: 'PgDip'},
    {key: 'Other', text: "None of the above"},
]

export const intendedExitAward = [
    {key: 'PhD', text: "PhD"},
    {key: 'PG Dip', text: "PG Dip"},
    {key: 'PG Cert', text: "Pg Cert"},
    {key: 'MSc', text: "MSc"},
]

export const placementLocations = [
    {key: 'AECC', text: 'Health Sciences University( Additional Fee)'},
    {key: 'nonAECC', text: 'I have my own clinical placement'}
]

export const monthArray = [
    {key: 'January', text: "January"},
    {key: 'February', text: "February"},
    {key: 'March', text: "March"},
    {key: 'April', text: "April"},
    {key: 'May', text: "May"},
    {key: 'June', text: "June"},
    {key: 'July', text: "July"},
    {key: 'August', text: "August"},
    {key: 'September', text: "September"},
    {key: 'October', text: "October"},
    {key: 'November', text: "November"},
    {key: 'December', text: "December"}
]
export const countryArray = [
    {key: 'United Kingdom', text: 'United Kingdom'},
    {key: 'Afghanistan', text: 'Afghanistan'},
    {key: 'Åland Islands', text: 'Åland Islands'},
    {key: 'Albania', text: 'Albania'},
    {key: 'Algeria', text: 'Algeria'},
    {key: 'American Samoa', text: 'American Samoa'},
    {key: 'Andorra', text: 'Andorra'},
    {key: 'Angola', text: 'Angola'},
    {key: 'Anguilla', text: 'Anguilla'},
    {key: 'Antarctica', text: 'Antarctica'},
    {key: 'Antigua And Barbuda', text: 'Antigua And Barbuda'},
    {key: 'Argentina', text: 'Argentina'},
    {key: 'Armenia', text: 'Armenia'},
    {key: 'Aruba', text: 'Aruba'},
    {key: 'Australia', text: 'Australia'},
    {key: 'Austria', text: 'Austria'},
    {key: 'Azerbaijan', text: 'Azerbaijan'},
    {key: 'Bahamas', text: 'Bahamas'},
    {key: 'Bahrain', text: 'Bahrain'},
    {key: 'Bangladesh', text: 'Bangladesh'},
    {key: 'Barbados', text: 'Barbados'},
    {key: 'Belarus', text: 'Belarus'},
    {key: 'Belgium', text: 'Belgium'},
    {key: 'Belize', text: 'Belize'},
    {key: 'Benin', text: 'Benin'},
    {key: 'Bermuda', text: 'Bermuda'},
    {key: 'Bhutan', text: 'Bhutan'},
    {key: 'Bolivia, Plurinational State Of', text: 'Bolivia, Plurinational State Of'},
    {key: 'Bonaire, Sint Eustatius And Saba', text: 'Bonaire, Sint Eustatius And Saba'},
    {key: 'Bosnia And Herzegovina', text: 'Bosnia And Herzegovina'},
    {key: 'Botswana', text: 'Botswana'},
    {key: 'Bouvet Island', text: 'Bouvet Island'},
    {key: 'Brazil', text: 'Brazil'},
    {key: 'British Indian Ocean Territory', text: 'British Indian Ocean Territory'},
    {key: 'Brunei Darussalam', text: 'Brunei Darussalam'},
    {key: 'Bulgaria', text: 'Bulgaria'},
    {key: 'Burkina Faso', text: 'Burkina Faso'},
    {key: 'Burundi', text: 'Burundi'},
    {key: 'Cambodia', text: 'Cambodia'},
    {key: 'Cameroon', text: 'Cameroon'},
    {key: 'Canada', text: 'Canada'},
    {key: 'Cape Verde', text: 'Cape Verde'},
    {key: 'Cayman Islands', text: 'Cayman Islands'},
    {key: 'Central African Republic', text: 'Central African Republic'},
    {key: 'Chad', text: 'Chad'},
    {key: 'Chile', text: 'Chile'},
    {key: 'China', text: 'China'},
    {key: 'Christmas Island', text: 'Christmas Island'},
    {key: 'Cocos (keeling) Islands', text: 'Cocos (keeling) Islands'},
    {key: 'Colombia', text: 'Colombia'},
    {key: 'Comoros', text: 'Comoros'},
    {key: 'Congo', text: 'Congo'},
    {key: 'Congo, The Democratic Republic Of The', text: 'Congo, The Democratic Republic Of The'},
    {key: 'Cook Islands', text: 'Cook Islands'},
    {key: 'Costa Rica', text: 'Costa Rica'},
    {key: "Côte D'ivoire", text: "Côte D'ivoire"},
    {key: 'Croatia', text: 'Croatia'},
    {key: 'Cuba', text: 'Cuba'},
    {key: 'Curaçao', text: 'Curaçao'},
    {key: 'Cyprus', text: 'Cyprus'},
    {key: 'Czech Republic', text: 'Czech Republic'},
    {key: 'Denmark', text: 'Denmark'},
    {key: 'Djibouti', text: 'Djibouti'},
    {key: 'Dominica', text: 'Dominica'},
    {key: 'Dominican Republic', text: 'Dominican Republic'},
    {key: 'Ecuador', text: 'Ecuador'},
    {key: 'Egypt', text: 'Egypt'},
    {key: 'El Salvador', text: 'El Salvador'},
    {key: 'England', text: 'England'},
    {key: 'Equatorial Guinea', text: 'Equatorial Guinea'},
    {key: 'Eritrea', text: 'Eritrea'},
    {key: 'Estonia', text: 'Estonia'},
    {key: 'Ethiopia', text: 'Ethiopia'},
    {key: 'Falkland Islands (malvinas)', text: 'Falkland Islands (malvinas)'},
    {key: 'Faroe Islands', text: 'Faroe Islands'},
    {key: 'Fiji', text: 'Fiji'},
    {key: 'Finland', text: 'Finland'},
    {key: 'France', text: 'France'},
    {key: 'French Guiana', text: 'French Guiana'},
    {key: 'French Polynesia', text: 'French Polynesia'},
    {key: 'French Southern Territories', text: 'French Southern Territories'},
    {key: 'Gabon', text: 'Gabon'},
    {key: 'Gambia', text: 'Gambia'},
    {key: 'Georgia', text: 'Georgia'},
    {key: 'Germany', text: 'Germany'},
    {key: 'Ghana', text: 'Ghana'},
    {key: 'Gibraltar', text: 'Gibraltar'},
    {key: 'Greece', text: 'Greece'},
    {key: 'Greenland', text: 'Greenland'},
    {key: 'Grenada', text: 'Grenada'},
    {key: 'Guadeloupe', text: 'Guadeloupe'},
    {key: 'Guam', text: 'Guam'},
    {key: 'Guatemala', text: 'Guatemala'},
    {key: 'Guernsey', text: 'Guernsey'},
    {key: 'Guinea', text: 'Guinea'},
    {key: 'Guinea-bissau', text: 'Guinea-bissau'},
    {key: 'Guyana', text: 'Guyana'},
    {key: 'Haiti', text: 'Haiti'},
    {key: 'Heard Island And Mcdonald Islands', text: 'Heard Island And Mcdonald Islands'},
    {key: 'Holy See (vatican City State)', text: 'Holy See (vatican City State)'},
    {key: 'Honduras', text: 'Honduras'},
    {key: 'Hong Kong', text: 'Hong Kong'},
    {key: 'Hungary', text: 'Hungary'},
    {key: 'Iceland', text: 'Iceland'},
    {key: 'India', text: 'India'},
    {key: 'Indonesia', text: 'Indonesia'},
    {key: 'Iran, Islamic Republic Of', text: 'Iran, Islamic Republic Of'},
    {key: 'Iraq', text: 'Iraq'},
    {key: 'Ireland', text: 'Ireland'},
    {key: 'Isle Of Man', text: 'Isle Of Man'},
    {key: 'Israel', text: 'Israel'},
    {key: 'Italy', text: 'Italy'},
    {key: 'Jamaica', text: 'Jamaica'},
    {key: 'Japan', text: 'Japan'},
    {key: 'Jersey', text: 'Jersey'},
    {key: 'Jordan', text: 'Jordan'},
    {key: 'Kazakhstan', text: 'Kazakhstan'},
    {key: 'Kenya', text: 'Kenya'},
    {key: 'Kiribati', text: 'Kiribati'},
    {key: "Korea, Democratic People's Republic Of", text: "Korea, Democratic People's Republic Of"},
    {key: 'Korea, Republic Of', text: 'Korea, Republic Of'},
    {key: 'Kuwait', text: 'Kuwait'},
    {key: 'Kyrgyzstan', text: 'Kyrgyzstan'},
    {key: "Lao People's Democratic Republic", text: "Lao People's Democratic Republic"},
    {key: 'Latvia', text: 'Latvia'},
    {key: 'Lebanon', text: 'Lebanon'},
    {key: 'Lesotho', text: 'Lesotho'},
    {key: 'Liberia', text: 'Liberia'},
    {key: 'Libya', text: 'Libya'},
    {key: 'Liechtenstein', text: 'Liechtenstein'},
    {key: 'Lithuania', text: 'Lithuania'},
    {key: 'Luxembourg', text: 'Luxembourg'},
    {key: 'Macao', text: 'Macao'},
    {key: 'Macedonia, The Former Yugoslav Republic Of', text: 'Macedonia, The Former Yugoslav Republic Of'},
    {key: 'Madagascar', text: 'Madagascar'},
    {key: 'Malawi', text: 'Malawi'},
    {key: 'Malaysia', text: 'Malaysia'},
    {key: 'Maldives', text: 'Maldives'},
    {key: 'Mali', text: 'Mali'},
    {key: 'Malta', text: 'Malta'},
    {key: 'Marshall Islands', text: 'Marshall Islands'},
    {key: 'Martinique', text: 'Martinique'},
    {key: 'Mauritania', text: 'Mauritania'},
    {key: 'Mauritius', text: 'Mauritius'},
    {key: 'Mayotte', text: 'Mayotte'},
    {key: 'Mexico', text: 'Mexico'},
    {key: 'Micronesia, Federated States Of', text: 'Micronesia, Federated States Of'},
    {key: 'Moldova, Republic Of', text: 'Moldova, Republic Of'},
    {key: 'Monaco', text: 'Monaco'},
    {key: 'Mongolia', text: 'Mongolia'},
    {key: 'Montenegro', text: 'Montenegro'},
    {key: 'Montserrat', text: 'Montserrat'},
    {key: 'Morocco', text: 'Morocco'},
    {key: 'Mozambique', text: 'Mozambique'},
    {key: 'Myanmar', text: 'Myanmar'},
    {key: 'Namibia', text: 'Namibia'},
    {key: 'Nauru', text: 'Nauru'},
    {key: 'Nepal', text: 'Nepal'},
    {key: 'Netherlands', text: 'Netherlands'},
    {key: 'New Caledonia', text: 'New Caledonia'},
    {key: 'New Zealand', text: 'New Zealand'},
    {key: 'Nicaragua', text: 'Nicaragua'},
    {key: 'Niger', text: 'Niger'},
    {key: 'Nigeria', text: 'Nigeria'},
    {key: 'Niue', text: 'Niue'},
    {key: 'Norfolk Island', text: 'Norfolk Island'},
    {key: 'Northern Ireland', text: 'Northern Ireland'},
    {key: 'Northern Mariana Islands', text: 'Northern Mariana Islands'},
    {key: 'Norway', text: 'Norway'},
    {key: 'Oman', text: 'Oman'},
    {key: 'Pakistan', text: 'Pakistan'},
    {key: 'Palau', text: 'Palau'},
    {key: 'Palestine, State Of', text: 'Palestine, State Of'},
    {key: 'Panama', text: 'Panama'},
    {key: 'Papua New Guinea', text: 'Papua New Guinea'},
    {key: 'Paraguay', text: 'Paraguay'},
    {key: 'Peru', text: 'Peru'},
    {key: 'Philippines', text: 'Philippines'},
    {key: 'Pitcairn', text: 'Pitcairn'},
    {key: 'Poland', text: 'Poland'},
    {key: 'Portugal', text: 'Portugal'},
    {key: 'Puerto Rico', text: 'Puerto Rico'},
    {key: 'Qatar', text: 'Qatar'},
    {key: 'Réunion', text: 'Réunion'},
    {key: 'Romania', text: 'Romania'},
    {key: 'Russian Federation', text: 'Russian Federation'},
    {key: 'Rwanda', text: 'Rwanda'},
    {key: 'Saint Barthélemy', text: 'Saint Barthélemy'},
    {key: 'Saint Helena, Ascension And Tristan Da Cunha', text: 'Saint Helena, Ascension And Tristan Da Cunha'},
    {key: 'Saint Kitts And Nevis', text: 'Saint Kitts And Nevis'},
    {key: 'Saint Lucia', text: 'Saint Lucia'},
    {key: 'Saint Martin (french Part)', text: 'Saint Martin (french Part)'},
    {key: 'Saint Pierre And Miquelon', text: 'Saint Pierre And Miquelon'},
    {key: 'Saint Vincent And The Grenadines', text: 'Saint Vincent And The Grenadines'},
    {key: 'Samoa', text: 'Samoa'},
    {key: 'San Marino', text: 'San Marino'},
    {key: 'Sao Tome And Principe', text: 'Sao Tome And Principe'},
    {key: 'Saudi Arabia', text: 'Saudi Arabia'},
    {key: 'Scotland', text: 'Scotland'},
    {key: 'Senegal', text: 'Senegal'},
    {key: 'Serbia', text: 'Serbia'},
    {key: 'Seychelles', text: 'Seychelles'},
    {key: 'Sierra Leone', text: 'Sierra Leone'},
    {key: 'Singapore', text: 'Singapore'},
    {key: 'Sint Maarten (dutch Part)', text: 'Sint Maarten (dutch Part)'},
    {key: 'Slovakia', text: 'Slovakia'},
    {key: 'Slovenia', text: 'Slovenia'},
    {key: 'Solomon Islands', text: 'Solomon Islands'},
    {key: 'Somalia', text: 'Somalia'},
    {key: 'South Africa', text: 'South Africa'},
    {key: 'South Georgia And The South Sandwich Islands', text: 'South Georgia And The South Sandwich Islands'},
    {key: 'South Sudan', text: 'South Sudan'},
    {key: 'Spain', text: 'Spain'},
    {key: 'Sri Lanka', text: 'Sri Lanka'},
    {key: 'Sudan', text: 'Sudan'},
    {key: 'Suriname', text: 'Suriname'},
    {key: 'Svalbard And Jan Mayen', text: 'Svalbard And Jan Mayen'},
    {key: 'Swaziland', text: 'Swaziland'},
    {key: 'Sweden', text: 'Sweden'},
    {key: 'Switzerland', text: 'Switzerland'},
    {key: 'Syrian Arab Republic', text: 'Syrian Arab Republic'},
    {key: 'Taiwan, Province Of China', text: 'Taiwan, Province Of China'},
    {key: 'Tajikistan', text: 'Tajikistan'},
    {key: 'Tanzania, United Republic Of', text: 'Tanzania, United Republic Of'},
    {key: 'Thailand', text: 'Thailand'},
    {key: 'Timor-leste', text: 'Timor-leste'},
    {key: 'Togo', text: 'Togo'},
    {key: 'Tokelau', text: 'Tokelau'},
    {key: 'Tonga', text: 'Tonga'},
    {key: 'Trinidad And Tobago', text: 'Trinidad And Tobago'},
    {key: 'Tunisia', text: 'Tunisia'},
    {key: 'Turkey', text: 'Turkey'},
    {key: 'Turkmenistan', text: 'Turkmenistan'},
    {key: 'Turks And Caicos Islands', text: 'Turks And Caicos Islands'},
    {key: 'Tuvalu', text: 'Tuvalu'},
    {key: 'Uganda', text: 'Uganda'},
    {key: 'Ukraine', text: 'Ukraine'},
    {key: 'United Arab Emirates', text: 'United Arab Emirates'},
    {key: 'United States', text: 'United States'},
    {key: 'United States Minor Outlying Islands', text: 'United States Minor Outlying Islands'},
    {key: 'Uruguay', text: 'Uruguay'},
    {key: 'Uzbekistan', text: 'Uzbekistan'},
    {key: 'Vanuatu', text: 'Vanuatu'},
    {key: 'Venezuela, Bolivarian Republic Of', text: 'Venezuela, Bolivarian Republic Of'},
    {key: 'Viet Nam', text: 'Viet Nam'},
    {key: 'Virgin Islands, British', text: 'Virgin Islands, British'},
    {key: 'Virgin Islands, U.s.', text: 'Virgin Islands, U.s.'},
    {key: 'Wales', text: 'Wales'},
    {key: 'Wallis And Futuna', text: 'Wallis And Futuna'},
    {key: 'Western Sahara', text: 'Western Sahara'},
    {key: 'Yemen', text: 'Yemen'},
    {key: 'Zambia', text: 'Zambia'},
    {key: 'Zimbabwe', text: 'Zimbabwe'}
]
export const nationalityArray = [
    {key: '28', text: 'UK National'},
    {key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider},
    {key: '1', text: 'Afghan'},
    {key: '2', text: 'Albanian'},
    {key: '3', text: 'Algerian'},
    {key: '4', text: 'American'},
    {key: '234', text: 'American Samoan'},
    {key: '5', text: 'Andorran'},
    {key: '6', text: 'Angolan'},
    {key: '229', text: 'Anguilla'},
    {key: '7', text: 'Antigua and Barbuda'},
    {key: '8', text: 'Argentine'},
    {key: '9', text: 'Armenian'},
    {key: '10', text: 'Australian'},
    {key: '11', text: 'Austrian'},
    {key: '12', text: 'Azerbaijani'},
    {key: '13', text: 'Bahamian'},
    {key: '14', text: 'Bahraini'},
    {key: '15', text: 'Bangladeshi'},
    {key: '16', text: 'Barbadian'},
    {key: '19', text: 'Belarusian'},
    {key: '20', text: 'Belgian'},
    {key: '21', text: 'Belizean'},
    {key: '23', text: 'Beninese'},
    {key: '208', text: 'Bermuda'},
    {key: '24', text: 'Bhutanese'},
    {key: '25', text: 'Bolivian'},
    {key: '26', text: 'Bosnian'},
    {key: '18', text: 'Botswanan'},
    {key: '27', text: 'Brazilian'},
    {key: '31', text: 'British (Overseas)'},
    {key: '227', text: 'British Antarctic Territory'},
    {key: '231', text: 'British Indian Ocean Territory'},
    {key: '32', text: 'Bruneian'},
    {key: '33', text: 'Bulgarian'},
    {key: '34', text: 'Burkinan'},
    {key: '35', text: 'Burmese'},
    {key: '36', text: 'Burundian'},
    {key: '37', text: 'Cambodian'},
    {key: '38', text: 'Cameroonian'},
    {key: '39', text: 'Canadian'},
    {key: '40', text: 'Cape Verdean'},
    {key: '224', text: 'Cayman Islands'},
    {key: '41', text: 'Central African'},
    {key: '42', text: 'Chadian'},
    {key: '43', text: 'Chilean'},
    {key: '44', text: 'Chinese'},
    {key: '45', text: 'Colombian'},
    {key: '46', text: 'Comoran'},
    {key: '47', text: 'Congolese (DRC)'},
    {key: '209', text: 'Congolese (PR)'},
    {key: '48', text: 'Costa Rican'},
    {key: '49', text: 'Croatian'},
    {key: '50', text: 'Cuban'},
    {key: '51', text: 'Cypriot (European Union)'},
    {key: '203', text: 'Cypriot (Non-European Union)'},
    {key: '204', text: 'Cypriot (Not otherwise specified)'},
    {key: '52', text: 'Czech'},
    {key: '53', text: 'Danish'},
    {key: '54', text: 'Djiboutian'},
    {key: '55', text: 'Dominican'},
    {key: '211', text: 'Dominican Republic'},
    {key: '56', text: 'Dutch'},
    {key: '57', text: 'East Timorese'},
    {key: '58', text: 'Ecuadorean'},
    {key: '59', text: 'Egyptian'},
    {key: '60', text: 'Emirati'},
    {key: '61', text: 'Equatorial Guinean'},
    {key: '62', text: 'Eritrean'},
    {key: '63', text: 'Estonian'},
    {key: '64', text: 'Ethiopian'},
    {key: '210', text: 'Falkland Islands'},
    {key: '236', text: 'Faroese'},
    {key: '65', text: 'Fijian'},
    {key: '67', text: 'Finnish'},
    {key: '68', text: 'French'},
    {key: '69', text: 'Gabonese'},
    {key: '70', text: 'Gambian'},
    {key: '71', text: 'Georgian'},
    {key: '72', text: 'German'},
    {key: '73', text: 'Ghanaian'},
    {key: '212', text: 'Gibraltar'},
    {key: '74', text: 'Greek'},
    {key: '230', text: 'Greenlander'},
    {key: '75', text: 'Grenadian'},
    {key: '225', text: 'Guamanian'},
    {key: '76', text: 'Guatemalan'},
    {key: '205', text: 'Guernsey'},
    {key: '77', text: 'Guinea-Bissau'},
    {key: '78', text: 'Guinean'},
    {key: '79', text: 'Guyanese'},
    {key: '80', text: 'Haitian'},
    {key: '82', text: 'Honduran'},
    {key: '83', text: 'Hong Kong'},
    {key: '84', text: 'Hungarian'},
    {key: '85', text: 'Icelander'},
    {key: '87', text: 'Indian'},
    {key: '88', text: 'Indonesian'},
    {key: '89', text: 'Iranian'},
    {key: '90', text: 'Iraqi'},
    {key: '91', text: 'Irish'},
    {key: '92', text: 'Israeli'},
    {key: '93', text: 'Italian'},
    {key: '94', text: 'Ivorian'},
    {key: '95', text: 'Jamaican'},
    {key: '96', text: 'Japanese'},
    {key: '206', text: 'Jersey'},
    {key: '97', text: 'Jordanian'},
    {key: '98', text: 'Kazakh'},
    {key: '99', text: 'Kenyan'},
    {key: '86', text: 'Kiribati'},
    {key: '241', text: 'Kosovan'},
    {key: '101', text: 'Kuwaiti'},
    {key: '102', text: 'Kyrgyz'},
    {key: '103', text: 'Lao'},
    {key: '104', text: 'Latvian'},
    {key: '105', text: 'Lebanese'},
    {key: '213', text: 'Lesotho'},
    {key: '106', text: 'Liberian'},
    {key: '107', text: 'Libyan'},
    {key: '108', text: 'Lichtensteiner'},
    {key: '109', text: 'Lithuanian'},
    {key: '110', text: 'Luxembourger'},
    {key: '214', text: 'Macanese'},
    {key: '111', text: 'Macedonian'},
    {key: '112', text: 'Malagasy'},
    {key: '113', text: 'Malawian'},
    {key: '114', text: 'Malaysian'},
    {key: '115', text: 'Maldivian'},
    {key: '116', text: 'Malian'},
    {key: '117', text: 'Maltese'},
    {key: '207', text: 'Manx'},
    {key: '118', text: 'Marshall Islander'},
    {key: '215', text: 'Mauritanian'},
    {key: '119', text: 'Mauritian'},
    {key: '120', text: 'Mexican'},
    {key: '121', text: 'Micronesian'},
    {key: '122', text: 'Moldovan'},
    {key: '123', text: 'Monegasque'},
    {key: '124', text: 'Mongolian'},
    {key: '235', text: 'Montenegrin'},
    {key: '216', text: 'Montserrat'},
    {key: '125', text: 'Moroccan'},
    {key: '128', text: 'Mozambican'},
    {key: '129', text: 'Namibian'},
    {key: '130', text: 'Nauruan'},
    {key: '131', text: 'Nepalese'},
    {key: '132', text: 'New Zealander'},
    {key: '133', text: 'Nicaraguan'},
    {key: '135', text: 'Niger'},
    {key: '134', text: 'Nigerian'},
    {key: '244', text: 'No information provided (Home)'},
    {key: '243', text: 'No information provided(Overseas)'},
    {key: '136', text: 'North Korean'},
    {key: '238', text: 'Northern Mariana Islands'},
    {key: '138', text: 'Norwegian'},
    {key: '222', text: 'Not Known'},
    {key: '139', text: 'Omani'},
    {key: '140', text: 'Pakistani'},
    {key: '141', text: 'Palauan'},
    {key: '142', text: 'Palestinian'},
    {key: '143', text: 'Panamanian'},
    {key: '144', text: 'Papua New Guinean'},
    {key: '145', text: 'Paraguayan'},
    {key: '147', text: 'Peruvian'},
    {key: '66', text: 'Philippine'},
    {key: '228', text: 'Pitcairn Islands'},
    {key: '148', text: 'Polish'},
    {key: '149', text: 'Portuguese'},
    {key: '151', text: 'Qatari'},
    {key: '152', text: 'Romanian'},
    {key: '153', text: 'Russian'},
    {key: '154', text: 'Rwandan'},
    {key: '240', text: 'Sahrawi'},
    {key: '156', text: 'Salvadoran'},
    {key: '157', text: 'Samoan'},
    {key: '158', text: 'San Marino'},
    {key: '159', text: 'Sao Tome and Principe'},
    {key: '160', text: 'Saudi Arabian'},
    {key: '162', text: 'Senegalese'},
    {key: '163', text: 'Serbian'},
    {key: '164', text: 'Seychelles'},
    {key: '165', text: 'Sierra Leone'},
    {key: '166', text: 'Singaporean'},
    {key: '167', text: 'Slovak'},
    {key: '168', text: 'Slovenian'},
    {key: '169', text: 'Solomon Islands'},
    {key: '170', text: 'Somali'},
    {key: '171', text: 'South African'},
    {key: '232', text: 'South Georgia and South Sandwich Islands'},
    {key: '172', text: 'South Korean'},
    {key: '242', text: 'South Sudanese'},
    {key: '173', text: 'Spanish'},
    {key: '174', text: 'Sri Lankan'},
    {key: '218', text: 'St Helena'},
    {key: '219', text: 'St Kitts and Nevis'},
    {key: '155', text: 'St Lucia'},
    {key: '220', text: 'St Vincent'},
    {key: '223', text: 'Stateless'},
    {key: '175', text: 'Sudanese'},
    {key: '176', text: 'Surinamese'},
    {key: '177', text: 'Swazi'},
    {key: '178', text: 'Swedish'},
    {key: '179', text: 'Swiss'},
    {key: '180', text: 'Syrian'},
    {key: '181', text: 'Taiwanese'},
    {key: '182', text: 'Tajik'},
    {key: '183', text: 'Tanzanian'},
    {key: '184', text: 'Thai'},
    {key: '185', text: 'Togolese'},
    {key: '186', text: 'Tongan'},
    {key: '187', text: 'Trinidad and Tobago'},
    {key: '188', text: 'Tunisian'},
    {key: '189', text: 'Turkish'},
    {key: '233', text: 'Turkmen'},
    {key: '226', text: 'Turks and Caicos Islands'},
    {key: '190', text: 'Tuvaluan'},
    {key: '191', text: 'Ugandan'},
    {key: '28', text: 'UK national'},
    {key: '192', text: 'Ukrainian'},
    {key: '193', text: 'Uruguayan'},
    {key: '194', text: 'Uzbek'},
    {key: '217', text: 'Vanuatu'},
    {key: '239', text: 'Vatican City'},
    {key: '195', text: 'Venezuelan'},
    {key: '196', text: 'Vietnamese'},
    {key: '221', text: 'Virgin Islander (British)'},
    {key: '198', text: 'Yemeni'},
    {key: '200', text: 'Zambian'},
    {key: '201', text: 'Zimbabwean'},
]

export const dualNationalityArray = [
    {key: '1', text: 'Afghan'},
    {key: '2', text: 'Albanian'},
    {key: '3', text: 'Algerian'},
    {key: '4', text: 'American'},
    {key: '234', text: 'American Samoan'},
    {key: '5', text: 'Andorran'},
    {key: '6', text: 'Angolan'},
    {key: '229', text: 'Anguilla'},
    {key: '7', text: 'Antigua and Barbuda'},
    {key: '8', text: 'Argentine'},
    {key: '9', text: 'Armenian'},
    {key: '10', text: 'Australian'},
    {key: '11', text: 'Austrian'},
    {key: '12', text: 'Azerbaijani'},
    {key: '13', text: 'Bahamian'},
    {key: '14', text: 'Bahraini'},
    {key: '15', text: 'Bangladeshi'},
    {key: '16', text: 'Barbadian'},
    {key: '19', text: 'Belarusian'},
    {key: '20', text: 'Belgian'},
    {key: '21', text: 'Belizean'},
    {key: '23', text: 'Beninese'},
    {key: '208', text: 'Bermuda'},
    {key: '24', text: 'Bhutanese'},
    {key: '25', text: 'Bolivian'},
    {key: '26', text: 'Bosnian'},
    {key: '18', text: 'Botswanan'},
    {key: '27', text: 'Brazilian'},
    {key: '31', text: 'British (Overseas)'},
    {key: '227', text: 'British Antarctic Territory'},
    {key: '231', text: 'British Indian Ocean Territory'},
    {key: '32', text: 'Bruneian'},
    {key: '33', text: 'Bulgarian'},
    {key: '34', text: 'Burkinan'},
    {key: '35', text: 'Burmese'},
    {key: '36', text: 'Burundian'},
    {key: '37', text: 'Cambodian'},
    {key: '38', text: 'Cameroonian'},
    {key: '39', text: 'Canadian'},
    {key: '40', text: 'Cape Verdean'},
    {key: '224', text: 'Cayman Islands'},
    {key: '41', text: 'Central African'},
    {key: '42', text: 'Chadian'},
    {key: '43', text: 'Chilean'},
    {key: '44', text: 'Chinese'},
    {key: '45', text: 'Colombian'},
    {key: '46', text: 'Comoran'},
    {key: '47', text: 'Congolese (DRC)'},
    {key: '209', text: 'Congolese (PR)'},
    {key: '48', text: 'Costa Rican'},
    {key: '49', text: 'Croatian'},
    {key: '50', text: 'Cuban'},
    {key: '51', text: 'Cypriot (European Union)'},
    {key: '203', text: 'Cypriot (Non-European Union)'},
    {key: '204', text: 'Cypriot (Not otherwise specified)'},
    {key: '52', text: 'Czech'},
    {key: '53', text: 'Danish'},
    {key: '54', text: 'Djiboutian'},
    {key: '55', text: 'Dominican'},
    {key: '211', text: 'Dominican Republic'},
    {key: '56', text: 'Dutch'},
    {key: '57', text: 'East Timorese'},
    {key: '58', text: 'Ecuadorean'},
    {key: '59', text: 'Egyptian'},
    {key: '60', text: 'Emirati'},
    {key: '61', text: 'Equatorial Guinean'},
    {key: '62', text: 'Eritrean'},
    {key: '63', text: 'Estonian'},
    {key: '64', text: 'Ethiopian'},
    {key: '210', text: 'Falkland Islands'},
    {key: '236', text: 'Faroese'},
    {key: '65', text: 'Fijian'},
    {key: '67', text: 'Finnish'},
    {key: '68', text: 'French'},
    {key: '69', text: 'Gabonese'},
    {key: '70', text: 'Gambian'},
    {key: '71', text: 'Georgian'},
    {key: '72', text: 'German'},
    {key: '73', text: 'Ghanaian'},
    {key: '212', text: 'Gibraltar'},
    {key: '74', text: 'Greek'},
    {key: '230', text: 'Greenlander'},
    {key: '75', text: 'Grenadian'},
    {key: '225', text: 'Guamanian'},
    {key: '76', text: 'Guatemalan'},
    {key: '205', text: 'Guernsey'},
    {key: '77', text: 'Guinea-Bissau'},
    {key: '78', text: 'Guinean'},
    {key: '79', text: 'Guyanese'},
    {key: '80', text: 'Haitian'},
    {key: '82', text: 'Honduran'},
    {key: '83', text: 'Hong Kong'},
    {key: '84', text: 'Hungarian'},
    {key: '85', text: 'Icelander'},
    {key: '87', text: 'Indian'},
    {key: '88', text: 'Indonesian'},
    {key: '89', text: 'Iranian'},
    {key: '90', text: 'Iraqi'},
    {key: '91', text: 'Irish'},
    {key: '92', text: 'Israeli'},
    {key: '93', text: 'Italian'},
    {key: '94', text: 'Ivorian'},
    {key: '95', text: 'Jamaican'},
    {key: '96', text: 'Japanese'},
    {key: '206', text: 'Jersey'},
    {key: '97', text: 'Jordanian'},
    {key: '98', text: 'Kazakh'},
    {key: '99', text: 'Kenyan'},
    {key: '86', text: 'Kiribati'},
    {key: '241', text: 'Kosovan'},
    {key: '101', text: 'Kuwaiti'},
    {key: '102', text: 'Kyrgyz'},
    {key: '103', text: 'Lao'},
    {key: '104', text: 'Latvian'},
    {key: '105', text: 'Lebanese'},
    {key: '213', text: 'Lesotho'},
    {key: '106', text: 'Liberian'},
    {key: '107', text: 'Libyan'},
    {key: '108', text: 'Lichtensteiner'},
    {key: '109', text: 'Lithuanian'},
    {key: '110', text: 'Luxembourger'},
    {key: '214', text: 'Macanese'},
    {key: '111', text: 'Macedonian'},
    {key: '112', text: 'Malagasy'},
    {key: '113', text: 'Malawian'},
    {key: '114', text: 'Malaysian'},
    {key: '115', text: 'Maldivian'},
    {key: '116', text: 'Malian'},
    {key: '117', text: 'Maltese'},
    {key: '207', text: 'Manx'},
    {key: '118', text: 'Marshall Islander'},
    {key: '215', text: 'Mauritanian'},
    {key: '119', text: 'Mauritian'},
    {key: '120', text: 'Mexican'},
    {key: '121', text: 'Micronesian'},
    {key: '122', text: 'Moldovan'},
    {key: '123', text: 'Monegasque'},
    {key: '124', text: 'Mongolian'},
    {key: '235', text: 'Montenegrin'},
    {key: '216', text: 'Montserrat'},
    {key: '125', text: 'Moroccan'},
    {key: '128', text: 'Mozambican'},
    {key: '129', text: 'Namibian'},
    {key: '130', text: 'Nauruan'},
    {key: '131', text: 'Nepalese'},
    {key: '132', text: 'New Zealander'},
    {key: '133', text: 'Nicaraguan'},
    {key: '135', text: 'Niger'},
    {key: '134', text: 'Nigerian'},
    {key: '244', text: 'No information provided (Home)'},
    {key: '243', text: 'No information provided(Overseas)'},
    {key: '136', text: 'North Korean'},
    {key: '238', text: 'Northern Mariana Islands'},
    {key: '138', text: 'Norwegian'},
    {key: '222', text: 'Not Known'},
    {key: '139', text: 'Omani'},
    {key: '140', text: 'Pakistani'},
    {key: '141', text: 'Palauan'},
    {key: '142', text: 'Palestinian'},
    {key: '143', text: 'Panamanian'},
    {key: '144', text: 'Papua New Guinean'},
    {key: '145', text: 'Paraguayan'},
    {key: '147', text: 'Peruvian'},
    {key: '66', text: 'Philippine'},
    {key: '228', text: 'Pitcairn Islands'},
    {key: '148', text: 'Polish'},
    {key: '149', text: 'Portuguese'},
    {key: '151', text: 'Qatari'},
    {key: '152', text: 'Romanian'},
    {key: '153', text: 'Russian'},
    {key: '154', text: 'Rwandan'},
    {key: '240', text: 'Sahrawi'},
    {key: '156', text: 'Salvadoran'},
    {key: '157', text: 'Samoan'},
    {key: '158', text: 'San Marino'},
    {key: '159', text: 'Sao Tome and Principe'},
    {key: '160', text: 'Saudi Arabian'},
    {key: '162', text: 'Senegalese'},
    {key: '163', text: 'Serbian'},
    {key: '164', text: 'Seychelles'},
    {key: '165', text: 'Sierra Leone'},
    {key: '166', text: 'Singaporean'},
    {key: '167', text: 'Slovak'},
    {key: '168', text: 'Slovenian'},
    {key: '169', text: 'Solomon Islands'},
    {key: '170', text: 'Somali'},
    {key: '171', text: 'South African'},
    {key: '232', text: 'South Georgia and South Sandwich Islands'},
    {key: '172', text: 'South Korean'},
    {key: '242', text: 'South Sudanese'},
    {key: '173', text: 'Spanish'},
    {key: '174', text: 'Sri Lankan'},
    {key: '218', text: 'St Helena'},
    {key: '219', text: 'St Kitts and Nevis'},
    {key: '155', text: 'St Lucia'},
    {key: '220', text: 'St Vincent'},
    {key: '223', text: 'Stateless'},
    {key: '175', text: 'Sudanese'},
    {key: '176', text: 'Surinamese'},
    {key: '177', text: 'Swazi'},
    {key: '178', text: 'Swedish'},
    {key: '179', text: 'Swiss'},
    {key: '180', text: 'Syrian'},
    {key: '181', text: 'Taiwanese'},
    {key: '182', text: 'Tajik'},
    {key: '183', text: 'Tanzanian'},
    {key: '184', text: 'Thai'},
    {key: '185', text: 'Togolese'},
    {key: '186', text: 'Tongan'},
    {key: '187', text: 'Trinidad and Tobago'},
    {key: '188', text: 'Tunisian'},
    {key: '189', text: 'Turkish'},
    {key: '233', text: 'Turkmen'},
    {key: '226', text: 'Turks and Caicos Islands'},
    {key: '190', text: 'Tuvaluan'},
    {key: '191', text: 'Ugandan'},
    {key: '192', text: 'Ukrainian'},
    {key: '193', text: 'Uruguayan'},
    {key: '194', text: 'Uzbek'},
    {key: '217', text: 'Vanuatu'},
    {key: '239', text: 'Vatican City'},
    {key: '195', text: 'Venezuelan'},
    {key: '196', text: 'Vietnamese'},
    {key: '221', text: 'Virgin Islander (British)'},
    {key: '198', text: 'Yemeni'},
    {key: '200', text: 'Zambian'},
    {key: '201', text: 'Zimbabwean'},
]


export const ethnicityArray = [
    {key: '50', text: 'Arab'},
    {key: '33', text: 'Asian or Asian British - Bangladeshi'},
    {key: '31', text: 'Asian or Asian British - Indian'},
    {key: '32', text: 'Asian or Asian British - Pakistani'},
    {key: '22', text: 'Black or Black British - African'},
    {key: '21', text: 'Black or Black British - Caribbean'},
    {key: '34', text: 'Chinese'},
    {key: '15', text: 'Gypsy or Traveller'},
    {key: '43', text: 'Mixed - White and Asian'},
    {key: '42', text: 'Mixed - White and Black African'},
    {key: '41', text: 'Mixed - White and Black Caribbean'},
    {key: '39', text: 'Other Asian Background'},
    {key: '29', text: 'Other Black Background'},
    {key: '80', text: 'Other Ethnic Background'},
    {key: '49', text: 'Other Mixed Background'},
    {key: '98', text: 'Prefer not to say'},
    {key: '10', text: 'White'},

]
export const disabilityArray = [
    {key: '0', text: 'No known disability'},
    {key: '8', text: 'Two or more impairments and/or disabling medical conditions'},
    {key: '51', text: 'A specific learning difficulty such as dyslexia, dyspraxia or AD(H)D'},
    {key: '53', text: "A social/communication impairment such as Asperger's syndrome/other autistic spectrum disorder"},
    {
        key: '54',
        text: 'A long standing illness or health condition such as cancer, HIV, diabetes, chronic heart disease, or epilepsy'
    },
    {key: '55', text: 'A mental health condition, such as depression, schizophrenia or anxiety disorder'},
    {
        key: '56',
        text: 'A physical impairment or mobility issues, such as difficulty using arms or using a wheelchair or crutches'
    },
    {key: '57', text: 'Deaf or a serious hearing impairment'},
    {key: '58', text: 'Blind or a serious visual impairment uncorrected by glasses'},
    {key: '96', text: 'A disability, impairment or medical condition that is not listed above'},
    {key: '90', text: 'Disability not listed above'},
]


export const genderArray = [
    {key: 'F', text: 'Female'},
    {key: 'M', text: 'Male'},
    {key: 'O', text: 'Other'}
]


export const FCPProfessionalBodies = [
    {key: 'HCPC', text: 'Health & Care Professions Council'},
    {key: 'GCC', text: 'General Chiropractic Council'},
    {key: 'GMC', text: 'General Medical Council'},
    {key: 'GOsC', text: 'General Osteopathic Council'},
    {key: 'GPhC', text: 'General Pharmaceutical Council'},
    {key: 'NMC', text: 'Nursing & Midwifery Council'},
    {key: 'PSNI', text: 'Pharmaceutical Society of Northern Island'},
    {key: 'Social work England', text: 'Social Work England'},
    {key: 'Other', text: 'Other'}
]

export const fundingOptions = [
    {key: 'Self-funding', text: 'Self-funding'},
    {key: 'Employer funded through NHS', text: 'Employer funded through NHS'},
    {key: 'Non-NHS employer funded', text: 'Non-NHS employer funded'},
    {key: 'Other', text: 'Other'},
]

export const fundingOptionsResearch = [
    {key: 'Self-funding', text: 'Self-funding'},
    {key: 'Funded studentship', text: 'Applying for a funded studentship'},
    {key: 'Fee-waiver', text: 'Fee-waiver'},
    {key: 'Other', text: 'Other'},
]




export const defaultToastErrorProps = {
    type: toast.TYPE.ERROR,
    //autoClose: 5000,
    className: "toastError",
    containerId: "defaultContainer"
}

export const defaultToastWarningProps = {
    type: toast.TYPE.WARNING,
        autoClose: 5000,
    className: "toastWarn",
    containerId: "defaultContainer"
}

export const defaultToastSuccessProps = {
    type: toast.TYPE.SUCCESS,
    autoClose: 5000,
    className: "toastSuccess",
    containerId: "defaultContainer"
}

export const defaultToastInfoProps = {
    type: toast.TYPE.INFO,
    autoClose: 5000,
    className: "toastInfo",
    containerId: "defaultContainer"
}


/**
 * Default component from which all our display components inherit
 *
 * Contains common utility methods for error handling and API access
 */
export default class BaseComponent extends Component {

    static contextType = BaseContext;


    constructor(props) {
        super(props);

        this.state = {
            //are we in the process of updating or retrieving application data
            isCommunicating: false,
        }
    }


    componentDidMount() {
        //Clear any errors currently in the session
        //   this.props.setNotification([]);

    }


    /**
     * Errors originating from the application framework and 3rd party comonents differ from those originated by our
     * own messaging Parse these into a consistent format
     */
    parseError(error) {



        let parsedError = {};
        /*
                console.log("Parsed Error");
                console.log(error);
                console.log(error.response);
                console.log(error.response.data);
        */

        if (typeof error.response !== "undefined" && typeof error.response.data !== "undefined") {

            parsedError.httpCode = error.response.data.status;
            parsedError.internalCode = error.response.data.code;
            parsedError.type = error.response.data.type || "error";
            parsedError.title = error.response.data.error || error.response.data.title;
            parsedError.message = error.response.data.message;

            if (typeof error.response.data.errors !== "undefined") {
                parsedError.errors = [];
                //We have some validator errors add them to our errors array
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    parsedError.errors.push(error.response.data.errors[i])
                }
            }
        } else {
            parsedError.httpCode = 400;
            parsedError.title = "Connectivity Issues"
            parsedError.message = "Server could not be reached, check your internet connection or try again later."
        }
        return parsedError;
    }


    parseMessage(data) {
        let parsedMessage = {};
        /*
                console.log("Parsed Error");
                console.log(error);
                console.log(error.response);
                console.log(error.response.data);
        */

        if (typeof data !== "undefined") {

            //        parsedError.httpCode = error.response.data.status;

            parsedMessage.type = data.type || "error";
            parsedMessage.title = data.title || "Message";
            parsedMessage.message = data.message || "Message text";

        } else {
            parsedMessage.type = "info";
            parsedMessage.title = "Message"
            parsedMessage.message = "Successful event, no message was supplied"
        }
        return parsedMessage;
    }


    getApplicationFormData(formId) {

    //Retrieve our context variables, we're not necessarily using them all here but we'll have them to hand if we do
        const {
            applicationFormId,
            setApplicationFormId,
            targetCourse,
            setTargetCourse,
            formData,
            setFormData,
            hasFormLoaded,
            setHasFormLoaded,
            hasApplied,
            setHasApplied,
        } = this.context;

        const {isCommunicating} = this.state;

        if (!isCommunicating) {

            //As we're now replacing data let the UI areas that rely on this know
            setHasFormLoaded(false);

            ApplicationService.getForm(formId)
                .then(
                    (response) => {
                        let data = response.data;
                        setFormData(data);

                        //We're using the ApplicationStatus variable to indicate what our status is
                        //If we've submitted then we want to restrict editing to certain areas
                        if (data.applicationStatus === 'Submitted') {
                            setHasApplied(true);
                        } else {
                            setHasApplied(false);
                        }
                        setHasFormLoaded(true);
                    })
                .catch(
                    (error) => {
                        let parsedError = this.parseError(error);

                        let toastId = toast(
                            parsedError.title + ": " + parsedError.content
                            , {
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                className: "toastError",
                                containerId: "defaultContainer"
                            });
                    })
                .finally(
                    () => {
                        //TODO should set an additional flag here to show an alternate page if an error has been set
                        this.setState({isCommunicating: false})
                    })
        } else {
            //communication in progress
            let toastId = toast(
                "Action in progress, Please wait before trying again."
                , {
                    type: toast.TYPE.WARNING,
                    autoClose: 5000,
                    className: "toastWarn",
                    containerId: "defaultContainer"
                });
        }
    }
}