import React  from 'react';

import BaseComponent, {countryArray} from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {FieldArray, Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import * as Yup from "yup";



const employmentTemplate = {
    currentEmployment: false,
    fullTime: false,    //contract basis
    hoursPerWeek: "",
    employerName: "",
    positionHeld: "",
    dateStarted: "",
    dateEnded: "",
    employerAddressLine1:"",
    employerAddressLine2:"",
    employerAddressLine3:"",
    employerAddressLine4:"",
    employerPostCode:"",
    employerCountry:"",
    hasDBSCert:false,
    dbscertNo: ""
}



const defaultValues = {
    employments: [
        employmentTemplate
    ]
}


const validationSchema = Yup.object().shape({

    employments: Yup.array().of(

        Yup.object().shape({
            currentEmployment: Yup.boolean().nullable().required("Please indicate if this is your current employment"),
            fullTime: Yup.boolean().nullable().required("Please indicate the contractual basis of this employment"),

            employerName: Yup.string().max(150, 'Employer name should be no more than 150 characters').required("Please indicate the name of your employer"),
            positionHeld: Yup.string().max(50, 'position held should be no more than 50 characters').required("Please provide your DBs certificate number"),
            dateStarted: Yup.string().nullable().required("Start date needed"),

            dateEnded: Yup.string().nullable().when(['currentEmployment'], {
                is: false,
                then: Yup.string().nullable().required("End date needed")
            }),

            employerAddressLine1: Yup.string().max(150, 'Employer address should be no more than 250 characters long').required("Please provide the address of this employer"),
            employerAddressLine2: Yup.string().max(150, 'Employer address should be no more than 250 characters long').required("Please provide the address of this employer"),
            employerAddressLine3: Yup.string().nullable(),
            employerAddressLine4: Yup.string().nullable(),

            employerCountry: Yup.string().nullable().required("Please indicate which country this employment was in"),

            employerPostCode: Yup.string().nullable().when(['employerCountry'], {
                is: (value) => {
                    if (value === 'United Kingdom') {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().required("Please enter the postcode for this employer"),
            }),

            hasDBSCert: Yup.string().nullable(),

            dbscertNo: Yup.string().nullable().when(['hasDBSCert'], {
                is: (value) => {
                    if (value !== null && value === 'true') {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().max(11, 'Your certificate should be a maximum of 11 characters').required("Please provide your DBs certificate number"),
            }),

            hoursPerWeek: Yup.string().nullable().when(['fullTime'], {
                is: (value) => {
                    if (value === false) {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().required("Please let us know approximately how many hours per week you undertook").max(5,"Please check your reported hours we're not expecting content to be 5 characters or less")
            })
        })
    )
})


class EmploymentHistory extends BaseComponent {

    static contextType = BaseContext;
    constructor(props) {
        super(props);

        let initialValues = defaultValues;

        this.state = {
            initialValues: initialValues,
            navigateHome: false,

            isTransmitting: false,

        }
    }



    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }



    processFormData() {
                    let data = this.context.formData;

                    let initialValues = this.state.initialValues;


                    let employments = data.employments;

                    if (typeof(employments) !== "undefined") {

                        initialValues = {
                            employments: data.employments,
                        }

                        if (data.employments.length < 1) {
                            initialValues.employments = [
                                employmentTemplate
                            ]
                        }
                    }

                    this.setState({
                        initialValues: initialValues
                    })
    }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitEmployments(values,this.context.applicationFormId)
            .then(
                (response) => {

                    this.getApplicationFormData(this.context.applicationFormId)

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }



    render() {
        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        let targetCourse=this.context.formData.courseDetails.id;




        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>


                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>


                            <h3 className="questionGroupTitle">Employment history</h3>

                            <p className = "">Please provide your last 3 years of employment history</p>
                            <p>&nbsp;</p>


                            <FieldArray name={"employments"}>
                                {({push, remove}) => (
                                    <div>
                                        {
                                            values.employments.length > 0 &&
                                            values.employments.map((employment, index) => (
                                                <div className = "formPanel">

                            <StandardInput
                                type="toggle"
                                title="Is this your current employment"
                                description=""
                                placeholder=""
                                helpText=""
                                fieldId={`employments[${index}].currentEmployment`}
                                onText="Yes"
                                offText="No"
                                requiredVisual={false}
                                flexRow={false}
                                flexGrow={false}
                                disabled={hasApplied}
                                additionalClass=""
                                multiline={false}
                                rowCount={1}
                                width={0}
                            />

                                                    <StandardInput
                                                        type="toggle"
                                                        title="Contractual basis"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].fullTime`}
                                                        onText="Full Time"
                                                        offText="Part Time"
                                                        requiredVisual={false}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />



                                                        <Gated display={values.employments[index].fullTime === false}>
                                                    <StandardInput
                                                        type="text"
                                                        title="Contracted hours"
                                                        description="Please indicate roughly how many hours per week you undertook"
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].hoursPerWeek`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={1}
                                                    />
                                                        </Gated>

                                                    <StandardInput
                                                        type="text"
                                                        title="Name of employer"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].employerName`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />

                                                    <StandardInput
                                                        type="text"
                                                        title="Position held"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].positionHeld`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />

                                                    <div className="formsFlex">
                                                    <StandardInput
                                                        type="date"
                                                        title="Employment start date"
                                                        description=""
                                                        placeholder="dd/mm/yyyy"
                                                        helpText=""
                                                        fieldId={`employments[${index}].dateStarted`}
                                                        requiredVisual={true}
                                                        flexRow={true}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={2}
                                                    />


                                                    <Gated display={values.employments[index].currentEmployment === false}>
                                                    <StandardInput
                                                        type="date"
                                                        title="Employment end date"
                                                        description=""
                                                        placeholder="dd/mm/yyyy"
                                                        helpText=""
                                                        fieldId={`employments[${index}].dateEnded`}
                                                        requiredVisual={true}
                                                        flexRow={true}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={2}
                                                    />
                                                    </Gated>
                                                    </div>

                                                    <Gated display={values.employments[index].currentEmployment === true}>
                                                    <StandardInput
                                                        type="toggle"
                                                        title="Do you have a Disclosure and Barring (DBS) certificate"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].hasDBSCert`}
                                                        onText="Yes"
                                                        offText="No"
                                                        requiredVisual={false}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />
                                                    </Gated>

                                                    <Gated display={values.employments[index].hasDBSCert === true}>

                                                        <StandardInput
                                                            type="text"
                                                            title="DBS certificate number"
                                                            description=""
                                                            placeholder=""
                                                            helpText=""
                                                            fieldId={`employments[${index}].dbscertNo`}
                                                            requiredVisual={false}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={0}
                                                        />

                                                    </Gated>

                                                    <StandardInput
                                                        type = "dropDown"
                                                        title = "Employment country"
                                                        description = ""
                                                        placeholder = "select country"
                                                        helpText = ""
                                                        fieldId = {`employments[${index}].employerCountry`}
                                                        requiredVisual = {true}
                                                        flexRow = {false}
                                                        flexGrow = {false}
                                                        disabled = {hasApplied}
                                                        additionalClass = ""
                                                        multiline = {false}
                                                        rowCount = {1}
                                                        width = {3}
                                                        options={countryArray}
                                                    />


                                                    <Gated display = {values.employments[index].employerCountry !== undefined && values.employments[index].employerCountry !== null && values.employments[index].employerCountry !== ""} >


                                                    <StandardInput
                                                        type="text"
                                                        title="Employment address line 1"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].employerAddressLine1`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />
                                                    <StandardInput
                                                        type="text"
                                                        title="Employment address line 2"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].employerAddressLine2`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />
                                                    <StandardInput
                                                        type="text"
                                                        title="Employment address line 3"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].employerAddressLine3`}
                                                        requiredVisual={false}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />
                                                    <StandardInput
                                                        type="text"
                                                        title="Employment address line 4"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`employments[${index}].employerAddressLine4`}
                                                        requiredVisual={false}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />
                                                    </Gated>

                                                    <Gated display = {values.employments[index].employerCountry === "United Kingdom"} >
                                                        <StandardInput
                                                            type="text"
                                                            title="Employment address post code"
                                                            description=""
                                                            placeholder=""
                                                            helpText=""
                                                            fieldId={`employments[${index}].employerPostCode`}
                                                            requiredVisual={false}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={2}
                                                        />
                                                    </Gated>


                                                    {!hasApplied && (

                                                    <ButtonSet className = "addRemoveButtons">
                                                        <button type="button" className={"addButton"}
                                                                onClick={() => push(employmentTemplate)}>&#10010;
                                                        </button>
                                                        {index > 0 && (
                                                            <button type="button" className={"removeButton"}
                                                                    onClick={() => remove(index)}>&#10006;
                                                            </button>
                                                        )}
                                                    </ButtonSet>

                                                    )}

                                                </div>
                                            ))}
                                    </div>
                                )}
                            </FieldArray>

                            <Gated display={hasApplied === false}>

                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary" onClick = {()=>{console.log(errors)}}>Save page</Button>
                            </ButtonSet>

                            </Gated>

                        </Form>
                    )
                    }
                </Formik>
            </>
        )
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <EmploymentHistory navigate={navigate} {...props} />;
}

