/** Renders the appropriate control based on the props received



 */
import React, {useState} from 'react';

import {
    ChoiceGroup,
    DatePicker,
    Dropdown,
    TextField,
    ResponsiveMode,
    Checkbox,
    Toggle,
    SpinButton, ComboBox, Position
} from "@fluentui/react";
import {ErrorMessage, useFormikContext} from "formik";


/**
 * Take a alist of props and selectively renders typically a fluent UI component with the appropriate values prefilled
 *
 * @param props
 * @constructor
 */
export function StandardInput(props) {






    /*
    type = "text"
    type = "text"
    title = "Title"
    description = "Title"
    placeholder = "mr/miss/ms"
    helpText = ""
    fieldId = "personTitle"
    requiredVisual = {false}
    flexRow = {true}
    flexGrow = {true}
    disabled = {false}
    additionalClass =
    required =
    multiline = {false}
    */


    //We're inside a formik instance, get its properties from context


    const formikContext = useFormikContext();

    switch (props.type) {
        case "combo":
            return generateComboField(props);
        case "combomulti":
            return generateComboMultiselectField(props);
        case "text":
            return generateTextField(props);
        case "date":
            return generateDateField(props);
        case "radio":
            return generateRadioGroup(props);
        case "dropDown":
            return generateDropDown(props);
        case "checkbox":
            return generateCheckBox(props);
        case "toggle":
            return generateToggle(props);
        case "wordCount":
            return generateTextFieldWithWordCount(props)
        case "spinButton":
            return generateSpinButton(props)
        default:
            return generateTextField(props);

    }
}



function generateToggle(props) {
    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);


    let isChecked = "";
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        isChecked = eval("values" + "." + props.fieldId)
    } else {
        isChecked = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (
        <>
            <Toggle
                label={title}
                ariaLabel={props.title}
                className={className}
                //Description not currently supported by fluent
                //TODO manually display description
                description={props.description}
                onText={props.onText}
                offText={props.offText}

                //Error message not currently supported by fluent
                //TODO manually display errors
                errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value);
                    handleChange(props.fieldId)
                }}
                required={props.required}
                disabled={props.disabled}
                checked={isChecked}
            />

        </>
    )



}


function generateCheckBox(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (
        <>
            <div className={"dropDownPadding"}>
            <Checkbox
                label={title}
                ariaLabel={props.title}
                className={className}
                //Description not currently supported by fluent
                //TODO manually display description
                description={props.description}

                //Error message not currently supported by fluent
                //TODO manually display errors
                errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value);
                    handleChange(props.fieldId)
                }}

                required={props.required}
                disabled={props.disabled}
                checked={values[props.fieldId]}




            />
            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>
            </div>
        </>
    )

}



function generateRadioGroup(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    let value = values[props.fieldId];
    //choicegroup doesn't like receiving undefineds

    if (value === undefined || value === null) {
        value = '';
    }


    return (
        <>
            <ChoiceGroup
                className={className}
                options={props.options}
                label={title}
                ariaLabel={props.title}
                //Description not currently supported by fluent
                //TODO manually display description
                description={props.description}
           //     defaultSelectedKey={values[props.fieldId]}
                //Error message not currently supported by fluent
                errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value.key);
                    handleChange(props.fieldId)
                }}
           //     selectedKey={values[props.fieldId]}
                     selectedKey={value}

                required={props.required}
                disabled={props.disabled}
            />

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>
        </>
    )
}



function generateDropDown(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);


    /* quick and dirty approach to determining if we're an array value or top level */

    let defaultSelectedKey = ""
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        defaultSelectedKey = eval("values" + "." + props.fieldId)
    } else {
        defaultSelectedKey = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (

        <>
            <div className={"dropDownPadding"}>
            <Dropdown
                className={ (touched[props.fieldId] && errors[props.fieldId]) ? `${className} redBorder` : `${className}`}
                label={title}
                ariaLabel={props.title}
                autoComplete={"true"}
                description={props.description}
                required={props.required}
                disabled={props.disabled}
                responsiveMode={ResponsiveMode.large}
                dropdownWidth="auto"
         //       errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                options={props.options}
      //          calloutProps={{ doNotLayer: true }}

                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value.key);
                    handleChange(props.fieldId)
                }}
                placeholder={props.placeholder}
                selectedKey={defaultSelectedKey}
             //   defaultSelectedKey={"Norway"}

            />

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>
            </div>
        </>
    )

}


function generateComboField(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    /* quick and dirty approach to determining if we're an array value or top level */

    let defaultSelectedKey = ""
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        defaultSelectedKey = eval("values" + "." + props.fieldId)
    } else {
        defaultSelectedKey = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (

        <>
            <div className={"dropDownPadding"}>
            <ComboBox
                className={ (touched[props.fieldId] && errors[props.fieldId]) ? `${className} redBorder` : `${className}`}
                label={title}
                ariaLabel={props.title}
                //            autoComplete={'on'}
                //            allowFreeform={false}
                multiSelect={props.multiSelect}
                description={props.description}
                required={props.required}
                disabled={props.disabled}
                responsiveMode={ResponsiveMode.large}
                dropdownWidth="auto"
             //   errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                options={props.options}
                //          calloutProps={{ doNotLayer: true }}

                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value.key);
                    handleChange(props.fieldId)
                }}
                //          text={}
                //       placeholder={props.placeholder}
                //      selectedKey={defaultSelectedKey}
                //text={props.placeholder}
                selectedKey={defaultSelectedKey}

            />

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>

            </div>
        </>
    )

}


function generateComboMultiselectField(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    /* quick and dirty approach to determining if we're an array value or top level */

    let defaultSelectedKeys = []
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        defaultSelectedKeys = eval("values" + "." + props.fieldId)
    } else {
        defaultSelectedKeys = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }


    let selectedKeys = props.selectedKeys;
    let setSelectedKeys = props.setSelectedKeys;



    let visibleSelectedKeys = defaultSelectedKeys;

    return (

        <>
            <div className={"dropDownPadding"}>
                <ComboBox
                    className={ (touched[props.fieldId] && errors[props.fieldId]) ? `${className} redBorder` : `${className}`}
                    label={title}
                    ariaLabel={props.title}
                    //            autoComplete={'on'}
                    //            allowFreeform={false}
                    multiSelect={true}
                    description={props.description}
                    required={props.required}
                    allowFreeform={false}
                    disabled={props.disabled}
                    responsiveMode={ResponsiveMode.large}
                    dropdownWidth="auto"
                    //   errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                    options={props.options}
                    //          calloutProps={{ doNotLayer: true }}

                    onChange={  (event, option,number, value) => {



                        let selected = option.selected;


                        if (option) {
                            setSelectedKeys(selectedKeys =>
                                selected ? [...selectedKeys, option.key] : selectedKeys.filter(k => k !== option.key),
                        );
                        }
                        //                           let selectedKeys =  selected ? [...defaultSelectedKeys, option.key] : defaultSelectedKeys.filter( k => k !==option.key)
//                            setFieldValue(props.fieldId,
 //                               defaultSelectedKeys => selected ? [...defaultSelectedKeys, option.key] : defaultSelectedKeys.filter( k => k !==option.key),
 //                               );


                        //set keys in formik
                        visibleSelectedKeys = selectedKeys;
                        setFieldValue(props.fieldId, selectedKeys);
                        handleChange(props.fieldId)
                    }}
                    //          text={}
                    //       placeholder={props.placeholder}
                    //      selectedKey={defaultSelectedKey}
                    //text={props.placeholder}
                    selectedKey={visibleSelectedKeys}

                />

                <ErrorMessage component="div"
                              className="formtableRow errorMessage ms-TextField-errorMessage"
                              name={props.fieldId}/>

            </div>
        </>
    )

}





function generateTextField(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    let value = undefined
    if (props.fieldId.includes(".")) {

        //TODO stop  using eval for this
        value = eval("values" + "." + props.fieldId)

    } else {
        value = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (
        <>
            <div className={`dropDownPadding`}>

            <TextField
                className={ errors[props.fieldId] ? `${className} redBorder` : `${className}`}
                label={title}
                ariaLabel={props.title}
                autoComplete={"true"}
                description={props.description}
                required={props.required}
                disabled={props.disabled}
            //    errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                multiline={props.multiline}
                rows={props.rowCount}
                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value);
                    handleChange(props.fieldId)
                }}
                placeholder={props.placeholder}
                value={value}
            />

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>



            </div>
        </>
)
}


function generateTextFieldWithWordCount(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);
    className = className + " noMargin"

    let wordCount = props.wordCount;
    let setWordCount = props.setWordCount;

    let maxWords = props.maxWords;


    let value = undefined
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        value = eval("values" + "." + props.fieldId)
        setWordCount(value.trim().split(/[\s]+/).length);

    } else {
        value = values[props.fieldId]
        setWordCount(value.trim().split(/[\s]+/).length);
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }





    return (
        <>
            <TextField
                className={className}
                label={title}
                ariaLabel={props.title}
                autoComplete={"true"}
                description={props.description}
                required={props.required}
                disabled={props.disabled}
                errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                multiline={props.multiline}
                rows={props.rowCount}
                onChange={(event, value) => {
                    setFieldValue(props.fieldId, value);
                    handleChange(props.fieldId)
                }}
                placeholder={props.placeholder}
                value={value}
                onKeyUp={(event)=>{
                    setWordCount(event.target.value.trim().split(/[\s]+/).length);
                }}
            />
            <div className = "wordCount"><strong>{wordCount}</strong> / {maxWords} words</div>

        </>
    )
}

function generateSpinButton(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    let value = ""
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        value = eval("values" + "." + props.fieldId)
    } else {
        value = values[props.fieldId]
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    //TODO determine constrainedSpinButtonWidth programatically

    return (
        <>
            <div className = "constrainSpinButtonWidth">
                <SpinButton
                    className={className}
                    label={title}
                    labelPosition={Position.top}
                    ariaLabel={props.title}
                    //TODO Doesn;t exist for spin button
                    description={props.description}
                    required={props.required}
                    disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    incrementButtonAriaLabel="Increase value"
                    decrementButtonAriaLabel="Decrease value"
                    max={props.max}
                    min={props.min}
                    precision={props.precision}
                    step={props.step}

                    //TODO Doesn't exist for spin button but somewhen
                    errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                    //                errorMessage={"test error"}
                    onChange={(event, value) => {
                        setFieldValue(props.fieldId, value);
                        handleChange(props.fieldId)
                    }}
                    placeholder={props.placeholder}
                    value={value}
                />
            </div>

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>

        </>
    )
}


function generateDateField(props) {

    const {values, errors, touched, setFieldValue, handleChange} = props.formikContext;
    let className = getClassNames(props);

    let value = undefined;
    if (props.fieldId.includes(".")) {
        //we're passing in a nested value,TODO must be a better way to do this than with eval
        value = new Date(eval("values" + "." + props.fieldId))

        if (isNaN(value)) {
            //value = new Date();
            value = undefined;
        }

    } else {

        if (values[props.fieldId] == null) {
            value = undefined;
        } else {
            value = new Date(values[props.fieldId])
            if (isNaN(value)) {
//            value = new Date();
                value = undefined;
            }
        }
    }

    let title = props.title;
    if (props.requiredVisual != null && props.requiredVisual === true) {
        title = title + " *";
    }

    return (
        <>
            <div className={"dropDownPadding"}>
            <DatePicker
                className={ (touched[props.fieldId] && errors[props.fieldId]) ? `${className} redBorder` : `${className}`}
                label={title}
                ariaLabel={props.title}
                autoComplete={"true"}
                description={props.description}
                isRequired={props.required}
                disabled={props.disabled}
                errorMessage={touched[props.fieldId] && errors[props.fieldId]}
                multiline={props.multiline}
                rowCount={props.rowCount}
                allowTextInput={true}
                placeholder={props.placeholder}
                value={value}

                formatDate={(date) => {
                    return !date ? '' : formatDateUI(date);
                }}

                onSelectDate={(date) => {
                    setFieldValue(props.fieldId, formatDate(date));
                    handleChange(props.fieldId)
                }}
            />

            <ErrorMessage component="div"
                          className="formtableRow errorMessage ms-TextField-errorMessage"
                          name={props.fieldId}/>
            </div>

        </>
    )
}

const formatDateUI = (date) => {
    return (addZero(date.getDate()) + '/' + addZero(date.getMonth() + 1) + '/' + (date.getFullYear()) );
}

const formatDate = (date) => {
    return ((date.getFullYear()) + "-" + addZero(date.getMonth() + 1) + "-" + addZero(date.getDate()) );
}



function addZero(number) {
    if (number < 10) {
        return "0" + number
    } else {
        return number
    }
}

function getClassNames(props) {
    let className = "";

    //If we're a date

    if (props.flexRow === true) {
        //This should appear on a line with multiple elements

        if (props.flexGrow) {
            className = className + " formsFlexChildGrow"
        } else {
            className = className + " formsFlexChildFixed"
        }
    }

    //Set width

    //width 0 is effectively unset
    if (props.width === 1) {
        //Narrow for things like title
        className = className + " formsWidth1"
    } else if (props.width === 2) {
        //short for small entries like names
        className = className + " formsWidth2"
    } else if (props.width === 3) {
        //Full width
        className = className + " formsWidth3"
    } else if (props.width === 4) {
        //max width
        className = className + " formsWidth4"
    }

    //Add in any passed through classes

    className = className + " " + props.additionalClass;

    /*
    //Add in a spacer for dropDowns which doesn't seem tpo be present by default
    if (props.type ==="dropDown") {
        className = className + " dropDownPadding";
    }

    if (props.type ==="checkbox") {
        className = className + " dropDownPadding";
    }
*/

    return className;
}


export default function (props) {
    // const navigation = useNavigate();


    const [wordCount, setWordCount] = useState(0);

    const formikContext = useFormikContext();
    return <StandardInput wordCount={wordCount} setWordCount={setWordCount} formikContext={formikContext} {...props} />;
}