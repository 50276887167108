import React from 'react';
import {Button, ButtonSet, Column, Content, Grid, InlineNotification, Row} from "@carbon/react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import AuthService from "../../util/AuthService";
import {Stack, TextField} from "@fluentui/react";
import BaseComponent, {defaultToastErrorProps, defaultToastWarningProps} from "../../util/BaseComponent"
import {useNavigate} from "react-router-dom";
import {Warning} from "@carbon/icons-react";
import {toast} from "react-toastify";
import ToastMessage from "../../components/ToastMessage";


const initialValues = {
    username: "",
    password: ""
};

const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required('You\'ll need to enter your username'),
    password: Yup.string().trim().required('Please enter your password.')
});




const stackProps = {childrenGap: "1rem"};






class Welcome extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            //Are we in the process of logging in
            loginInProgress: false,
        }
    }


    componentDidMount() {

    }


    login(values) {

        if (!this.state.loginInProgress) {

            this.setState({
                loginInProgress: true
            })

            AuthService.login(values.username, values.password)
                .then(
                    (response) => {
                        //Any success code is fine here
                        const navigate = this.props.navigate;
                        navigate("/apply/list")
                    })
                .catch(
                    error => {
                        let parsedError = this.parseError(error)

                        let toastId = toast(
                            <ToastMessage title = {parsedError.title} message = {parsedError.message} />
                            , defaultToastErrorProps);
                    }
                )
                .then(
                    () => {
                        this.setState({
                            loginInProgress: false
                        })
                    }
                )
        } else {
            let toastId = toast(
                <ToastMessage title = "Warning" message = "Login in progress, please wait" />
                , defaultToastWarningProps);
        }
    }


    render() {

        const {isSending, displayNotification, notificationKind, notificationTitle, notificationSubtitle} = this.state;

        return (
            <>
                <div className="contentHeader">
                    <div on className="constrainContentWidth">
                        <h1>HSU postgraduate and CPD applications system</h1>
                        <p>Welcome, please login or create an account below to continue your application.</p>
                    </div>
                </div>


                <Content className="welcomePage">
                    <Grid>
                        <Row className="paddedBottom">
                            <Column className="standardColumn width2">
                                <h4>Existing applicants</h4>
                                <div className="contentInstruction paddingLeft">
                                    {/* <p>Please login or <Link to = "/register">register</Link> to make your application. If you've forgotten your login details please click <Link to = "/forgotten">here</Link></p> */}
                                    <p>Please login to your account to continue or create a new application.</p>
                                    <p>&nbsp;</p>
                                </div>

                                <div className="contentInstruction">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            //avoid a double click
                                            if (!isSending) {
                                                this.login(values);
                                            }
                                        }}
                                    >
                                        {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                                            <Form>
                                                <div className="formPanel">
                                                    <Stack tokens={stackProps}>
                                                        <TextField
                                                            label="Username"
                                                            ariaLabel="Please enter your username"
                                                            autoComplete={true}
                                                            className="mediumInput"
                                                            //description="The username you selected when you registered previously"
                                                            disabled={false}
                                                            errorMessage={touched.username && errors.username}
                                                            multiline={false}
                                                            onChange={(event, value) => {
                                                                setFieldValue("username", value);
                                                                //                                                       handleChange("username")
                                                            }}
                                                            validateOnFocusOut={true}
                                                            //prefix = ""
                                                            //suffix = ""
                                                            placeholder="username"
                                                        />

                                                        <TextField
                                                            label="Passphrase"
                                                            type="password"
                                                            ariaLabel="Please enter your passphrase"
                                                            className="mediumInput"
                                                            autoComplete={true}
                                                          //  description="The password you selected when you registered previously"
                                                            disabled={false}
                                                            errorMessage={touched.password && errors.password}
                                                            multiline={false}
                                                            onChange={(event, value) => {
                                                                setFieldValue("password", value);
                                                                //                                                       handleChange("password")
                                                            }}
                                                            validateOnFocusOut={true}
                                                            //prefix = ""
                                                            //suffix = ""
                                                            placeholder="passphrase"
                                                            canRevealPassword={true}
                                                            revealPasswordAriaLable="Show password"
                                                        />

                                                    </Stack>
                                                </div>


                                                {displayNotification && (
                                                    <InlineNotification
                                                        kind={notificationKind}
                                                        title={notificationTitle}
                                                        subtitle={notificationSubtitle}
                                                        onCloseButtonClick={() => {
                                                            this.setState({
                                                                displayNotification: false,
                                                                notificationTitle: "",
                                                                notificationSubtitle: ""
                                                            })
                                                        }}
                                                    />
                                                )}


                                                <ButtonSet>
                                                    <Button type="reset" kind="secondary">Clear form</Button>
                                                    <Button type="submit" kind="primary">Login</Button>
                                                </ButtonSet>
                                            </Form>
                                        )}
                                    </Formik>


                                </div>


                            </Column>

                            <Column className="standardColumn width1">
                                <h4>New applicants</h4>

                                <div className="contentInstruction paddingLeft">
                                    <p>If this is your first postgraduate application with us please create an account before continuing.</p>
                                    <p>&nbsp;</p>
                                </div>

                                <ButtonSet className="paddingLeft">
                                    <Button type="button" kind="primary" href="/register">Register</Button>
                                </ButtonSet>

                            </Column>
                        </Row>


                        {/*<Row>*/}
                        {/*    <Column>*/}
                        {/*        <div className="contentInstruction">*/}
                        {/*            <Link to="/matrix">Course Matrix</Link>*/}
                        {/*        </div>*/}
                        {/*    </Column>*/}
                        {/*</Row>*/}


                        <Row className="paddedBottom">
                            <p>&nbsp;</p>
                            <div className="highlightedFormQuestionHints">
                                <p><Warning className="warningPosition"/>Applications to the "Master of Chiropractic"
                                    course must be made through UCAS.</p>
                            </div>
                        </Row>


                    </Grid>
                </Content>

            </>
        );
    }
}


export default function (props) {

    const navigate = useNavigate();
//    const [notification, setNotification] = useRecoilState(notificationDefinition);
//    return <Welcome navigate = {navigate} notification = {notification} setNotification = {setNotification} {...props} />;
    return <Welcome navigate={navigate} {...props} />;
}

