import axios from "axios";
import jwt_decode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_TARGET;

class AuthService {
  login(username, passphrase) {
    return axios
        .post(API_URL + "/auth/login", {
          username,
          passphrase
        })
        .then(response => {
          if (response.data.accessToken) {

            //Decode our JWT
            let jwt = jwt_decode(response.data.accessToken);

            sessionStorage.setItem("user", JSON.stringify(response.data));

            //put our roles in a second object for easy lookup
            sessionStorage.setItem("roles", JSON.stringify(jwt.roles));



          }

          return response.data;
        });
  }


  recover(username) {


    let target_url = API_URL + "/auth/recover";
    return axios.post(
        target_url,{
          username
          },
        {headers: {'Content-Type': 'application/json'}}
    );

  }


  resetPassphrase(token,passphrase) {



    let target_url = API_URL + "/auth/resetPassphrase";
    return axios.post(
        target_url,{
          token,
          passphrase
        },
        {headers: {'Content-Type': 'application/json'}}
    );

  }


  logout() {
    //Remove our session objects
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("roles");

  }

  register(values) {
    return axios.post(API_URL + "/register", JSON.stringify(values), {headers: {'Content-Type': 'application/json'}});
  }


  //Using a different endpoint to the register endpoint to make life a little less confusing on the server auth config
  updateProfile(values) {
    return axios.put(API_URL + "/profile", JSON.stringify(values), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAuthToken()
      }
    });
  }

  /*
  {currentUser.roles &&
    currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
    */


  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));

  }

  getRoles() {
    return JSON.parse(sessionStorage.getItem('roles'));
  }

  getAuthToken() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.accessToken) {
      return user.accessToken;
    } else {
      return
    }
  }





}

export default new AuthService();