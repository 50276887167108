import React  from 'react';

import BaseComponent, {countryArray, FCPProfessionalBodies} from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {ErrorMessage, FieldArray, Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({

    memberships: Yup.array().when(['professionalMembership'], {
        is: false,
        then: Yup.array().of(
            Yup.object().shape({
                dateFrom: Yup.string().required("The induction date to the body is required").nullable(),
                professionalBody: Yup.string().required("Please tell us the name of the body").nullable(),
                registrationNo: Yup.string().required("Please provide your membership/registration number").nullable(),
                dateTo: Yup.string().nullable(),
                other: Yup.string().nullable().when(['professionalBody'], {
                    is: (value) => {
                        if (value === 'Other') {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    then: Yup.string().nullable().required("Please describe your affiliation"),
                }),




            })
        )
    }),
}  )

const membershipDefaultSchema = {
    dateFrom: "",
    dateTo: "",
    professionalBody: "",
    registrationNo: "",
    other: "",

}

const defaultSchema = {

    professionalMembership: false,
    professionalInsurance: false,

    memberships: [
        membershipDefaultSchema,
    ],
}

class ProfessionalBodies extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);


        let initialValues = defaultSchema;

        this.state = {
            initialValues: initialValues,
            isTransmitting: false,
            navigateHome: false,
        }

    }


    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }



    processFormData() {
                    let data = this.context.formData;




        let memberOfNoProfessionalBodies = data.memberOfNoProfessionalBodies;
        if (memberOfNoProfessionalBodies === null || memberOfNoProfessionalBodies === 'undefined') {
            memberOfNoProfessionalBodies = false;
        }

        let professionalInsurance = data.professionalInsurance;
        if (professionalInsurance === null || professionalInsurance === 'undefined') {
            professionalInsurance = false;
        }



                    let initialValues = {
                        professionalMembership: memberOfNoProfessionalBodies,
                        professionalInsurance: professionalInsurance,
                        memberships: data.professionalBodies
                    }

                    if (data.professionalBodies.length < 1) {

                        initialValues.memberships = [membershipDefaultSchema]
                    }

                    this.setState({
                        initialValues: initialValues
                    })
    }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitMemberships(values,this.context.applicationFormId)
            .then(
                (response) => {

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });


                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);

                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });

                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }



    render() {

        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;

        const {hasApplied} = this.context;

        let targetCourse=this.context.formData.courseDetails.id;
        let formData = this.context.formData;


        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                <h3 className="questionGroupTitle">Professional bodies</h3>


                            <p className="informationText dropDownPadding">Please enter the details of any professional registrations or indicate you have none</p>



                            <div className="formPanel">
                                <StandardInput
                                    type="checkbox"
                                    title="Check here to indicate you aren't currently registered with any professional bodies"
                                    description=""
                                    placeholder=""
                                    helpText=""
                                    fieldId="professionalMembership"
                                    required={false}
                                    flexRow={true}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={0}
                                />
                            </div>

                            <Gated display={values.professionalMembership !== true }>
                                <p className="informationText dropDownPadding">or indicate your current registrations</p>


                                <FieldArray name={"memberships"}>
                                    {({push, remove}) => (
                                        <div>
                                            {
                                                values.memberships.length > 0 &&
                                                values.memberships.map((membership, index) => (
                                                    <div className = "formPanel">

                                                        {  (targetCourse === 125 || targetCourse === 134 || targetCourse === 906) ? (
<>
                                                                <StandardInput
                                                                    type="dropDown"
                                                                    title="Name of body"
                                                                    description=""
                                                                    placeholder=""
                                                                    helpText=""
                                                                    fieldId={`memberships[${index}].professionalBody`}
                                                                    requiredVisual={true}
                                                                    flexRow={false}
                                                                    flexGrow={false}
                                                                    disabled={hasApplied}
                                                                    additionalClass=""
                                                                    multiline={false}
                                                                    rowCount={1}
                                                                    width={0}
                                                                    options={FCPProfessionalBodies}

                                                                />

                                                            <Gated display = {values.memberships[index].professionalBody === "Other"}>
                                                                <StandardInput
                                                                    type="text"
                                                                    title="Please specify"
                                                                    description=""
                                                                    placeholder=""
                                                                    helpText=""
                                                                    fieldId={`memberships[${index}].other`}
                                                                    requiredVisual={true}
                                                                    flexRow={false}
                                                                    flexGrow={false}
                                                                    disabled={hasApplied}
                                                                    additionalClass=""
                                                                    multiline={false}
                                                                    rowCount={1}
                                                                    width={0}

                                                                />



                                                            </Gated>

</>

                                                        )  : (
                                                            <StandardInput
                                                            type="text"
                                                            title="Name of body"
                                                            description=""
                                                            placeholder=""
                                                            helpText=""
                                                            fieldId={`memberships[${index}].professionalBody`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={0}
                                                            />



                                                        ) }




                                                        <StandardInput
                                                            type="text"
                                                            title="Registration number"
                                                            description=""
                                                            placeholder=""
                                                            helpText=""
                                                            fieldId={`memberships[${index}].registrationNo`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={0}
                                                        />



                                                        <StandardInput
                                                            type="date"
                                                            title="Date joined"
                                                            description=""
                                                            placeholder="dd/mm/yyyy"
                                                            helpText=""
                                                            fieldId={`memberships[${index}].dateFrom`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={2}
                                                        />

                                                        <StandardInput
                                                            type="date"
                                                            title="Date to"
                                                            description=""
                                                            placeholder="dd/mm/yyyy"
                                                            helpText=""
                                                            fieldId={`memberships[${index}].dateTo`}
                                                            requiredVisual={false}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={2}
                                                        />


                                                        {!hasApplied && (

                                                        <ButtonSet className = "addRemoveButtons">
                                                            <button type="button" className={"addButton"}
                                                                    onClick={() => push(membershipDefaultSchema)}>&#10010;
                                                            </button>
                                                            {index > 0 && (
                                                                <button type="button" className={"removeButton"}
                                                                        onClick={() => remove(index)}>&#10006;
                                                                </button>
                                                            )}
                                                        </ButtonSet>
                                                            )}
                                                    </div>
                                                    ))}
                                        </div>
                                        )}
                                </FieldArray>
                            </Gated>



                            <Gated display={formData.courseDetails.id === 902 }>

                                <div className="formPanel">
                                    <StandardInput
                                        type="checkbox"
                                        title="Check here to indicate you have insurance to practice"
                                        description=""
                                        placeholder=""
                                        helpText=""
                                        fieldId="professionalInsurance"
                                        required={false}
                                        flexRow={true}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={0}
                                    />
                                </div>

                            </Gated>





                            <Gated display={!hasApplied} >
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                            </Gated>


                        </Form>
                    )}
                </Formik>


            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <ProfessionalBodies navigate={navigate} {...props} />;
}

