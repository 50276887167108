import React  from 'react';

import BaseComponent from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {Warning} from "@carbon/icons-react";
import {toast} from "react-toastify";

const initialValues = {submit: true}
const validationSchema = {}

class SubmitApplication extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);


        const initialValues = {}
        const validationSchema = {}

        this.state = {

            isTransmitting: false,
            navigateHome: false,

        }
    }




    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }

    processFormData() {
                    let data = this.context.formData;
     }

    submitForm(values) {
    //    alert("submitting application");


        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitApplication(values,this.context.applicationFormId)
            .then(
                (response) => {

                    this.getApplicationFormData(this.context.applicationFormId)

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });

                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
     }




    render() {


        let wordCount = 0;


        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }


        return (
            <>

                <Formik
                    initialValues={initialValues}
                   // validationSchema={validationSchema}
                    enableReinitialize={true}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (


                        <Form>
                            <h3 className="questionGroupTitle">Submit application</h3>


                            {!hasApplied ? (
                            <>
                            <p className="informationText dropDownPadding">Before submitting your application please ensure you have entered as much information into this application form as possible. This will help us process your application more quickly.</p>

                            <div className="highlightedFormQuestionHints">
                            <p><Warning className="warningPosition"/>Please note you can continue to update any documents shared with us once your application has been submitted but other aspects of your application will not be editable.</p>
                            </div>
                            <p className="informationText dropDownPadding">If any information we require is missing from the application, we will contact you via e-mail or telephone in order to obtain this.</p>


                            <p className = "informationText dropDownPadding">
                                Please note by submitting this application you are acknowledging that you have read and agree to abide by our <a className = "purple" href = "https://www.hsu.ac.uk/media/9061/student-privacy-notice.pdf" target = "_new">privacy and data retention policies</a>
                            </p>


                            <div className = "formPanelNoMaxWidth">








                            <ButtonSet>
                                <Button type="submit" kind="primary">Submit application</Button>
                            </ButtonSet>
                                </div>

                            </>
                                ) : (
                                    <>
                                        <p className="informationText dropDownPadding">
                                            Thank you, this application has been submitted for consideration. You can contact the applications team at admissions@aecc.ac.uk if you with to discuss the content.
                                        </p>

                                        <div className="highlightedFormQuestionHints">
                                            <p><Warning className="warningPosition"/>Please note you can continue to update any documents shared with us once your application has been submitted but other aspects of your application are not editable.</p>
                                        </div>

                                    </>

                                )}

                        </Form>
                    )}
                </Formik>

            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <SubmitApplication navigation={navigate} {...props} />;
}

