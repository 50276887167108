import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import AuthService from "./AuthService";



/* Time in minutes before we should instigate logout */
const LOGOUT_AFTER = 60;

/**
 * Triggers an event after a period of inactivity that redirects the client to the logout instigator
 * Set to LOGOUT_AFTER minutes
 *
 * //TODO: Add in a second stage of early warning , eg pop up a 5minute left countdown timer
 *
 */
const SessionManager = (props) => {

    /*
    We expect a component to be passed through to us that we'll return providing it meets any display restrictions
     */
    const { Component } = props;



    /*
    We need to be able to bounce people back to the login screen if they don't meet requirements, we're using
    react-router-dom 6s useNavigate to do that
     */
    const navigate = useNavigate()

    /*
     * Called after a period of inactivity
     */
    const handleLogout = (event) => {
        console.log("Logging out user due to idle timer ");
        //Push people to our logout page, it'll handle session deletion and redirecting to the login page
        navigate("/logout", {replace: true});
    }

    /*
     * Set up a timer to trigger our logout handler after LOGOUT_AFTER minutes of inactivity
     */
    const {getLastActiveTime, getTotalIdleTime} = useIdleTimer({
        timeout: 1000 * 60 * LOGOUT_AFTER,
        onIdle: handleLogout,
        debounce: 500,
    })



    /*
    Session Manager is also responsible for checking roles are correct before displaying a component.
    These are supplied by props and if present we validate our user matches or bounce them to logout
     */

    if (props.requiredRole && props.requiredRole.length > 0)  {
        if (AuthService.getRoles()  && AuthService.getRoles().includes(props.requiredRole) ) {
            return <Component />
        } else {
            return <Navigate to="/" replace={true}/>
        }
    } else {
        /* No role restrictions found we can pass through to the component */
        return <Component />
    }
}

export default SessionManager;