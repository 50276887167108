import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

/**
 * Utility methods for handling interaction with the courses api
 *
 */
class ApplicationService {

    /**
     * Retrieve this users applications
     */
    getExistingApplicationsList() {
        return axios.get(API_URL + "/applications", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()


            }
        })
    }


    /**
     * Start a new application for this user
     */
    startApplication(courseId) {

        let data = {
            courseId: courseId
        }

        return axios.post(API_URL + "/application/new/", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + AuthService.getAuthToken()
                }
            }
        )
    }


    /**
     * Pull in the prefilled forms for form 1
     */
    getForm(formId) {

        return axios.get(API_URL + "/application/" + formId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
            })
    }


    submitPersonalInfo1(values, formId) {
        //If country selected is not UK then delete postcode data

        let postCode = values.personPostCode;
        if (values.personCountry !== "United Kingdom") {
            postCode = "";
        }

        let data = {
            personPreviousName: values.personPreviousName,
            personDOB: values.personDOB,
            personGender: values.personGender,
            personCountry: values.personCountry,
            personAddressLine1: values.personAddressLine1,
            personAddressLine2: values.personAddressLine2,
            personAddressLine3: values.personAddressLine3,
            personAddressLine4: values.personAddressLine4,
            personPostCode: postCode,
            personMobile: values.personMobile,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage1", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }

    submitFundingType(values, formId) {
        //If country selected is not UK then delete postcode data

        let fundingTypeOther = values.fundingTypeOther;
        if (values.fundingType !== "Other") {
            fundingTypeOther = "";
        }

        let data = {
            fundingTypeOther: values.fundingTypeOther,
            fundingType: values.fundingType,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage9", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }


    submitPersonalInfo2(values, formId) {

        let data = {

            personNationality: values.personNationality,
            personDualNationality: values.personDualNationality,
            personResidenceCountry: values.personResidenceCountry,
            personDateUkEntry: values.personDateUkEntry,
            personDateUKResidency: values.personDateUKResidency,
            personHighestQualification: values.personHighestQualification,
            //          personExitAward: "",
            personEthnicOrigin: values.personEthnicOrigin,
            personAdditionalNeeds: values.personAdditionalNeeds,
            personAdditionalNeedsDetails: values.personAdditionalNeedsDetails
        }



        return axios.post(API_URL + "/application/" + formId + "/stage2", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }

    /**
     * Submit our language tests page, based upon the value of whether or not they've indicated
     * the languageTests we want to remove any legacy array items.
     *
     * We have a "virtual" type field that should be mapped onto the qualification field in case
     * of particular values.
     */
    submitLanguages(values, formId) {

        let languageTests = [];
        if (!values.nativeSpeaker) {
            languageTests = values.languageTests
        }

        //Iterate around each language test and massage the values inot the format we want
        for (let i = 0; i < languageTests.length; i++) {

            if (languageTests[i].type === "IELTS") {
                //This record should have a TRF number and nothing else
                languageTests[i].qualification = "IELTS";
                languageTests[i].date = null;
                languageTests[i].details = null;
            } else if (languageTests[i].type === "TOEFL") {
                languageTests[i].qualification = "TOEFL";
                languageTests[i].date = null;
                languageTests[i].details = null;
            } else {
                //Type is Other, we have a regular record with all fields filled in correctly
                //Blank out any supplied TRF number as its not relevant for this record and could have come
                //from a previous edit
                languageTests[i].triNumber = null;
            }
        }


        let data = {
            nativeSpeaker: values.nativeSpeaker,
            languageTests: languageTests,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage3", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }


    submitEducation(values, formId) {

        let data = {
            qualifications: values.qualifications,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage4", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }

    submitMemberships(values, formId) {

        let data = {
            professionalMembership: values.professionalMembership,
            professionalBodies: values.memberships,
            professionalInsurance: values.professionalInsurance
        }

        return axios.post(API_URL + "/application/" + formId + "/stage5", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }


    submitEmployments(values, formId) {

        let data = {
            employments: values.employments,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage6", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }


    submitPersonalStatement(values, formId) {

        let data = {
            personalStatement: values.personalStatement,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage7", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }


    submitOverview(values, formId) {
        /**
         * We now hav an intended matriculation point, we need to separate that out into
         * year of entry and intakes which was the former way of asking the question
         *
         */

        let matriculationPoint = values.matriculationPoint;
        let targetYear = matriculationPoint.split(':')[0];
        let intake = matriculationPoint.split(':')[1];



        //Figure out a suitable year of entry from the information supplied
        let yearOfEntry = this.genYearOfEntry(targetYear, intake);

        let data = {
            intendedExitAward: values.intendedExitAward,
            yearOfEntry: yearOfEntry,
            intake: intake,
            selectedUnits: values.selectedUnits,
            selectedIntensity: values.selectedIntensity,
            selectedLocation: values.selectedLocation
        }

        return axios.post(API_URL + "/application/" + formId + "/stage0", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }



    submitProject(values, formId) {
        /**
         * We now hav an intended matriculation point, we need to separate that out into
         * year of entry and intakes which was the former way of asking the question
         *
         */
        let data = {
            project_id: values.selectedProject,
            additional_description: values.selectedProjectDescription
        }

        return axios.post(API_URL + "/application/" + formId + "/stage13", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }








    /** Returns a suitable year of entry string for the selected year **/
    /**
     *  TODO:// This doesn't account for January starts being 24/24
     *
     * and how do we want that to be handled with the likes of CPD
     * **/

    genYearOfEntry(targetYear, intake) {
        let yearOfEntry = ''

        if (intake < 9) {
            yearOfEntry = (parseInt(targetYear) - 1).toString() + "/" + targetYear.substring(2);
        } else {
            yearOfEntry = targetYear + "/" +  (parseInt(targetYear) + 1).toString().substring(2);
        }
        return yearOfEntry;
    }



    submitApplication(values, formId) {




        return axios.get(API_URL + "/application/" + formId + "/submit",{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })
    }



    submitPlacement(values, formId) {

        let placementAtAECC = null;
        if (values.placementLocation === "AECC") {
            placementAtAECC = true;
        } else {
            placementAtAECC = false;
        }
        let data = {
            //AECC or alternate placementLocations
            placementAtAECC: placementAtAECC,
            reqUpperLimb: values.reqUpperLimb,
            reqLowerLimb: values.reqLowerLimb,
            reqHip: values.reqHip,
            reqSoftTissue: values.reqSoftTissue,
            reqAbdominal: values.reqAbdominal,
            reqSuperficialStructures: values.reqSuperficialStructures,
            reqFirstTrimester: values.reqFirstTrimester,
            reqSecondTrimester: values.reqSecondTrimester,
            reqGynaecology: values.reqGynaecology,
            reqGuidedInjections: values.reqGuidedInjections,
            reqNegotiatedSkill1: values.reqNegotiatedSkill1,
            reqNegotiatedSkill2: values.reqNegotiatedSkill2,
            negotiatedSkill1: values.negotiatedSkill1,
            negotiatedSkill2: values.negotiatedSkill2,
            peTitle: values.peTitle,
            peForename: values.peForename,
            peSurname: values.peSurname,
            peEmail: values.peEmail,
            peClinicName: values.peClinicName,
            peAddressLine1: values.peAddressLine1,
            peAddressLine2: values.peAddressLine2,
            peAddressLine3: values.peAddressLine3,
            peAddressLine4: values.peAddressLine4,
            pePostCode: values.pePostCode,
        }

        return axios.post(API_URL + "/application/" + formId + "/stage10", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })


    }


    submitReferee(values, formId) {

        let data = {
            reference: {
                title: values.refereeTitle,
                firstName: values.refereeForename,
                surname: values.refereeSurname,
                relationship: values.refereeRelationship,
                occupation: values.refereeOccupation,
                telNo: values.refereeContactNo,
                emailAddress: values.refereeEmail,
            },
        }


        return axios.post(API_URL + "/application/" + formId + "/stage8", data ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAuthToken()
            }
        })


    }


}

export default new ApplicationService();