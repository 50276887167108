import React from 'react';

import BaseComponent, {
    genderArray,
    disabilityArray,
    ethnicityArray,
    nationalityArray,
    countryArray,
    monthArray,
    qualificationArray,
    dualNationalityArray, qualificationPossessedArray, intendedExitAward
} from "../../../../util/BaseComponent";

import {useNavigate} from "react-router-dom";
import {TooltipDelay, TooltipHost} from "@fluentui/react";
import {Form, Formik} from "formik";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet, InlineNotification} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import StandardInput from "../../../../components/StandardInput";
import * as Yup from "yup";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import {useId} from "@fluentui/react-hooks";
import {Information} from "@carbon/icons-react";

const loginDetailsTooltip = {
    onRenderContent: () => (
        <div className = "tooltipContainer">
            <p>Dual nationality (sometimes called dual citizenship) is when someone holds British nationality and the nationality of another country at the same time.</p>
            <p> Please let us know if another country considers you a national.</p>
        </div>
    ),
}

const validationSchema = Yup.object().shape({
    personNationality: Yup.string().required('Please enter a nationality').nullable(),
    personDualNationality: Yup.string().nullable(),
    personResidenceCountry: Yup.string().required('Please enter your country of residence').nullable(),


    personDateUkEntry: Yup.string().nullable().when(['personNationality'], {
        is: (value) => {
            if (value !== '28') {
                return true
            } else {
                return false
            }
        },
        then: Yup.string().required("PLease enter your date of UK entry").nullable()
    }),

    personDateUKResidency: Yup.string().nullable().when(['personNationality'], {
        is: (value) => {
            if (value !== '28') {
                return true
            } else {
                return false
            }
        },
        then: Yup.string().required("Please indicate when you were granted permanent residence").nullable()
    }),



    personHighestQualification: Yup.string().required('Please enter your peak qualification level').nullable(),

//    personEthnicOrigin: Yup.string().required('Please enter your ethnic origin').nullable(),
    personAdditionalNeeds: Yup.string().required('Please indicate if you have any additional needs').nullable(),
    personAdditionalNeedsDetails: Yup.string().nullable(),
});

const initialValues = {
    personNationality: undefined,
    personDualNationality: undefined,
    personResidenceCountry: undefined,
    personDateUkEntry: undefined,
    personDateUKResidency: undefined,
    personHighestQualification: undefined,
    personExitAward: undefined,
//    personEthnicOrigin: undefined,
    personAdditionalNeeds: undefined,
    personAdditionalNeedsDetails: undefined
}




class PersonalInfo2 extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {

            initialValues: initialValues,
            navigateHome: false,

            isTransmitting: false,
            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }

    }

    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processPersonalInfo2()
        }
    }


    /**
     * Pull in the current values we hold for this form, We're returning a DB object with the sensitive fields removed
     * so refer to DB named fields
     */
    processPersonalInfo2() {

                    let data = this.context.formData;
                    let initialValues = {
                        personNationality: data.nationality1,
                        personDualNationality: data.nationality2,
                        personResidenceCountry: data.countryOfDomicileOrPermanentResidence,
                        personDateUkEntry: data.dateOfFirstEntryIntoUK,
                        personDateUKResidency: data.dateGrantedPermanentResidence,
                        personHighestQualification: data.highestLevelOfQualificationAtEntry,
              //          personExitAward: "",
                        personEthnicOrigin: data.ethnicOrigin,
                        personAdditionalNeeds: data.alncode,
                        personAdditionalNeedsDetails: data.alnfurtherDetails
                    }

                    this.setState({
                        initialValues: initialValues
                    })




    }


    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitPersonalInfo2(values,this.context.applicationFormId)
            .then(
                (response) => {

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });


                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);

                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });

                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }


    render() {
        const {daysArray, yearsArray, yearsArrayCurrent, initialValues, isTransmitting, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>


                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    //      validate={validator}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                            <h3 className="questionGroupTitle">About you</h3>

                            <div className="formPanel">

                                <StandardInput
                                    type="dropDown"
                                    title="Primary nationality"
                                    description=""
                                    placeholder="select nationality"
                                    helpText=""
                                    fieldId="personNationality"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={nationalityArray}
                                />

                                {/*<div className="highlightedFormQuestionHints">*/}
                                {/*    <p><Warning className="warningPosition"/>Dual nationality (sometimes called dual citizenship) is when someone holds British nationality and the nationality of another country at the same time.</p>*/}
                                {/*    <p> Please let us know if another country considers you a national.</p>*/}
                                {/*</div>*/}

                                <div className="formsFlex">
                                <StandardInput
                                    type="dropDown"
                                    title="Secondary nationality (if applicable)"
                                    description="If you're a dual national please let us know your other nationality"
                                    placeholder="select nationality"
                                    helpText=""
                                    fieldId="personDualNationality"
                                    requiredVisual={false}
                                    flexRow={true}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={dualNationalityArray}
                                />
                                <TooltipHost
                                    tooltipProps={loginDetailsTooltip}
                                    delay={TooltipDelay.zero}
                                    id={useId('Login details')}
                                >
                                    <Information className = "informationIcon" size={16} />
                                </TooltipHost>
                                </div>

                                <StandardInput
                                    type="dropDown"
                                    title="Country of permanent residence"
                                    description="Leave blank if you have a single nationality"
                                    placeholder="select country"
                                    helpText=""
                                    fieldId="personResidenceCountry"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={countryArray}
                                />

                                <Gated display={
                                    values.personNationality !== "28"
                                    && values.personNationality !== null   }>
                                    <StandardInput
                                        type="date"
                                        title="1st UK entry date"
                                        description=""
                                        placeholder="dd/mm/yyyy"
                                        helpText=""
                                        fieldId="personDateUkEntry"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={2}
                                    />


                                    <StandardInput
                                        type="date"
                                        title="Date granted permanent residence"
                                        description=""
                                        placeholder="dd/mm/yyyy"
                                        helpText=""
                                        fieldId="personDateUKResidency"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={2}
                                    />
                                </Gated>


                                {/**}

                                 <DatePicker
                                 label="Date of first entry to the uk (if not born in the uk)"
                                 allowTextInput={true}
                                 ariaLabel="Select a date. Input format is day slash month slash year."
                                 placeholder="dd/mm/yyyy"
                                 value={firstEntryDisplayDate}
                                 formatDate={(date) => {
                                            return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() );
                                        }}
                                 onSelectDate={(date) => {
                                             let formattedDate = !date ? '' : date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                                             setFieldValue("personDateUkEntry",  formattedDate );
                                            handleChange("personDateUkEntry")
                                            firstEntryDisplayDate = date;
                                        }}
                                 />
                                 **/}


                                <StandardInput
                                    type="dropDown"
                                    title="What's the highest level of qualification you'll possess at entry"
                                    description="//TODO superfluous question?"
                                    placeholder="select qualification"
                                    helpText=""
                                    fieldId="personHighestQualification"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={qualificationPossessedArray}
                                />
                                {/*
                                <StandardInput
                                    type="dropDown"
                                    title="What's your intended exit award"
                                    description=""
                                    placeholder="Select qualification"
                                    helpText=""
                                    fieldId="personExitAward"
                                    required={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disable={false}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={intendedExitAward}
                                />
*/}

                            </div>


                            {/*<h3 className="questionGroupTitle">Protected Characteristics</h3>*/}

                            <h3 className="questionGroupTitle">Additional needs</h3>

                            <p>Please let us know of any additional needs that may impact your learning, this information is used as per section 10 of the universities <a className = "purple" href = "https://www.hsu.ac.uk/wp-content/uploads/2024/03/Recruitment-Selection-and-Admissions-Taught-Courses-Policy-and-Procedure.pdf" target = "_new">recruitment, selection and admissions policy</a> .</p>
                            <p>&nbsp;</p>



                            <div className="formPanel">

                                {/*<StandardInput*/}
                                {/*    type="dropDown"*/}
                                {/*    title="Ethnic origin"*/}
                                {/*    description=""*/}
                                {/*    placeholder="select ethnicity"*/}
                                {/*    helpText=""*/}
                                {/*    fieldId="personEthnicOrigin"*/}
                                {/*    requiredVisual={true}*/}
                                {/*    flexRow={false}*/}
                                {/*    flexGrow={false}*/}
                                {/*    disabled={hasApplied}*/}
                                {/*    additionalClass=""*/}
                                {/*    multiline={false}*/}
                                {/*    rowCount={1}*/}
                                {/*    width={3}*/}
                                {/*    options={ethnicityArray}*/}
                                {/*/>*/}

                                <StandardInput
                                    type="dropDown"
                                    title="Additional needs"
                                    description=""
                                    placeholder="select needs"
                                    helpText=""
                                    fieldId="personAdditionalNeeds"
                                    requiredVisual={true}
                                    flexRow={false}
                                    flexGrow={false}
                                    disabled={hasApplied}
                                    additionalClass=""
                                    multiline={false}
                                    rowCount={1}
                                    width={3}
                                    options={disabilityArray}
                                />


                                <Gated display={(values.personAdditionalNeeds !== '0') && (values.personAdditionalNeeds !== null)}>


                                    <StandardInput
                                        type="text"
                                        title="Additional needs details"
                                        description="Please provide more information about your learning needs"
                                        placeholder=""
                                        helpText=""
                                        fieldId="personAdditionalNeedsDetails"
                                        requiredVisual={false}
                                        flexRow={true}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={true}
                                        rowCount={5}
                                        width={0}
                                    />

                                </Gated>
                            </div>

                            {displayNotification && (
                                <InlineNotification className="dropDownPadding"
                                    kind={notificationKind}
                                    title={notificationTitle}
                                    subtitle={notificationSubtitle}
                                    onCloseButtonClick={() => {
                                        this.setState({
                                            displayNotification: false,
                                            notificationTitle: "",
                                            notificationSubtitle: ""
                                        })
                                    }}
                                />
                            )}
                            <Gated display={hasApplied === false}>
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                            </Gated>
                        </Form>


                    )}
                </Formik>

            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <PersonalInfo2 navigate={navigate} {...props} />;
}

