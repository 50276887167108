import React  from 'react';
import BaseComponent from "../../util/BaseComponent"



/** Currently used as the basis for apply with not additional path info **/
class Start extends BaseComponent {

    constructor(props) {
        super(props);
    }


    componentDidMount() {

    }




    render() {
        return (
            <>
                <p>Default Page for /apply - not sure if we need this or not, perhaps redirect back to the host page</p>
            </>
        );
    }
}


export default function (props) {

//    const [notification, setNotification] = useRecoilState(notificationDefinition);
    return <Start {...props} />;
}

