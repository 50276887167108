import axios from "axios";
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_TARGET;

/**
 * Utility methods for handling interaction with the courses api
 *
 */
class CourseService {

  /**
   * Retrieve the list of all available courses
   * Unauthenticated Method
   */
  getFullCourseList() {
      return axios.get(API_URL + "/courses/recruiting", {
        headers: {
          'Content-Type': 'application/json'
        }
    })
  }


  // getCourse(courseId) {
  //   return axios.get(API_URL + "/courses/course/" + courseId , {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  // }
  //
  //
  // getCourseTemplate(formId) {
  //   return axios.get(API_URL + "/courses/template/" + formId, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  // }


}

export default new CourseService();