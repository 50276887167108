import React from 'react';


import {useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import StandardInput from "../../../../components/StandardInput";
import BaseComponent, {countryArray} from "../../../../util/BaseComponent";
import BaseContext from "../../../../util/BaseContext";
import {DateTime, Info, Interval} from "luxon";
import {toast} from "react-toastify";
import Gated from "../../../../components/Gated";
import {Panel, PanelType} from "@fluentui/react";
import {Information} from "@carbon/icons-react";

/**
 * Validation for course overviews
 */
const baseValidationSchema = Yup.object().shape({
    selectedProject: Yup.string().required("Please indicate your choice of research").nullable(),
    selectedProjectDescription: Yup.string().nullable().when(['selectedProject'], {
        is: (value) => {
            if (value == '0') {
                return true;
            } else {
                return false;
            }
        },
        then: Yup.string().nullable().required("Please enter your research proposal title")
    }),
});








/**
 *
 */
const initialValues = {
//    yearOfEntry: "",
//    intake: "",
    selectedProject: null,
    selectedProjectDescription: null
}

/**
 * Handles the reserahc project selection page in our applications process
 */
class Project extends BaseComponent {
    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            initialValues: initialValues,
            navigateHome: false,
            isTransmitting: false,
            availableProjects: [],
            sidePanelVisible: false,
        }
    }


    componentDidMount() {
        //Parse our formDate and retrieve the items we're interested in

        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }


    /**
     * Parse our returned formData and set up our data structure for the UI
     */
    processFormData() {

        let formData = this.context.formData;

        let availableProjects = this.getAvailableProjects(formData.courseDetails.availableProjects);



        let selectedProject = null;
        let selectedProjectDescription = null;

        if (formData.projectSelection !== undefined && formData.projectSelection !== null) {
            let projectSelection = formData.projectSelection;
            selectedProject = projectSelection.project_id;
            selectedProjectDescription = projectSelection.additional_description;
        }


        let initialValues = {
            ...this.state.initialValues,
            selectedProject: selectedProject,
            selectedProjectDescription: selectedProjectDescription,
        }


        this.setState({
            initialValues: initialValues,
            availableProjects: availableProjects,
        })

    }



    /**
     * Parses the available awards and generates a list of those available
     */
    getAvailableProjects(sourceProjects) {

        let availableProjects = [];

        if (Array.isArray(sourceProjects) && sourceProjects.length > 0) {
            sourceProjects.forEach(projectsDetails => {
                let item = {
                    key: projectsDetails.id,
                    text: projectsDetails.courseTitle,
                }
                availableProjects.push(item);
            })
        }
        return availableProjects;
    }




    /* We're submitting to an interim object */
    submitForm(values) {

        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitProject(values, this.context.applicationFormId)
            .then(
                (response) => {
                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                    this.getApplicationFormData(this.context.applicationFormId)
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
    }

    render() {
        //Formik data, are we transmitting what are our defaults
        const {isTransmitting, initialValues, navigateHome, hasUnits, sidePanelVisible} = this.state;

        const {
            availableProjects,
        } = this.state;


        const popupStyles = {root: {display: 'inline-block'}}

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }


        //Sort out the client side validation schema

        let validationSchema = baseValidationSchema;

        // if(availableLocations !== undefined && availableLocations.length > 0) {
        //     validationSchema = validationSchema.concat(validationSchemaLocation);
        // }
        //
        // if(availableIntensities !== undefined && availableIntensities.length > 0) {
        //     validationSchema = validationSchema.concat(validationSchemaIntensity);
        // }




        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>

                            <h3 className="questionGroupTitle">PhD research opportunities</h3>

                            {/*{values.courseName === "MSc Professional Practice" ? (*/}
                            {/*    <p className="informationText">You're applying for: Unit CPD7006 The Deteriorating patient</p>*/}
                            {/*) : (*/}
                            {/*    <p className="informationText">You're applying for: <strong>{values.courseName}</strong></p>*/}
                            {/*)}*/}

                            <p className="informationText">We have some research opportunities available or you can choose your own research topic.</p>
                            <p>&nbsp;</p>

                            <div className="formPanel">
                                    <StandardInput
                                        type="radio"
                                        title="Research opportunity"
                                        description="Please indicate your field of research"
                                        placeholder="Select opportunity"
                                        helpText=""
                                        fieldId="selectedProject"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={3}
                                        options={availableProjects}
                                    />


                                <Gated display={values.selectedProject !== undefined && values.selectedProject !== null && values.selectedProject === 0}>
                                    <p>&nbsp;</p>


                                    <StandardInput
                                        type="text"
                                        title="Custom research proposal title"
                                        description="Please identify your own area of research"
                                        placeholder="Research title"
                                        helpText=""
                                        fieldId="selectedProjectDescription"
                                        requiredVisual={true}
                                        flexRow={false}
                                        flexGrow={false}
                                        disabled={hasApplied}
                                        additionalClass=""
                                        multiline={false}
                                        rowCount={1}
                                        width={4}
                                    />
                                </Gated>


                                {/*<StandardInput*/}
                                {/*    type="dropDown"*/}
                                {/*    title="Desired entry point"*/}
                                {/*    description="The point at which you'd like to start your studies"*/}
                                {/*    placeholder="select entrypoint"*/}
                                {/*    helpText=""*/}
                                {/*    fieldId="matriculationPoint"*/}
                                {/*    requiredVisual={true}*/}
                                {/*    flexRow={false}*/}
                                {/*    flexGrow={false}*/}
                                {/*    disabled={hasApplied}*/}
                                {/*    additionalClass=""*/}
                                {/*    multiline={false}*/}
                                {/*    rowCount={1}*/}
                                {/*    width={3}*/}
                                {/*    options={availableMatriculationPoints}*/}
                                {/*/>*/}

                                {/*<StandardInput*/}
                                {/*    type="dropDown"*/}
                                {/*    title="Intended exit award"*/}
                                {/*    description="The exit award you intend to aim for"*/}
                                {/*    placeholder="select award"*/}
                                {/*    helpText=""*/}
                                {/*    fieldId="intendedExitAward"*/}
                                {/*    requiredVisual={true}*/}
                                {/*    flexRow={false}*/}
                                {/*    flexGrow={false}*/}
                                {/*    disabled={hasApplied}*/}
                                {/*    additionalClass=""*/}
                                {/*    multiline={false}*/}
                                {/*    rowCount={1}*/}
                                {/*    width={3}*/}
                                {/*    options={availableAwards}*/}
                                {/*/>*/}







                            </div>


                            {/*<Gated display={hasUnits}>*/}

                            {/*    {availableUnits.length > 0 && (*/}
                            {/*        <>*/}

                            {/*            <h3 className="questionGroupTitle">Course units</h3>*/}

                            {/*            <p className="informationText">Please select any optional units you're*/}
                            {/*                interested in</p>*/}

                            {/*            <p>&nbsp;</p>*/}

                            {/*            <div className="formPanel">*/}
                            {/*                <table width="100%">*/}
                            {/*                    {availableUnits.map((unit, index) => (*/}
                            {/*                        <>*/}
                            {/*                            <tr>*/}
                            {/*                                <td className="checkboxCell"><Field*/}
                            {/*                                    className="unitCheckbox" type="checkbox"*/}
                            {/*                                    name="selectedUnits" value={`${unit.id}`}/></td>*/}
                            {/*                                /!*<td className = "creditsCell">{unit.credits}</td>*!/*/}
                            {/*                                <td className="nameCell"><p>{unit.name}</p></td>*/}
                            {/*                                <td className="infoCell">*/}

                            {/*                                    <Information className="informationIcon" size={16}*/}
                            {/*                                                 onClick={*/}
                            {/*                                                     () => {*/}
                            {/*                                                         this.setState({*/}
                            {/*                                                             sidePanelVisible: true,*/}
                            {/*                                                             sidePanelUnitName: unit.name,*/}
                            {/*                                                             sidePanelCredits: unit.credits,*/}
                            {/*                                                             sidePanelDescription: unit.description*/}
                            {/*                                                         })*/}
                            {/*                                                     }*/}
                            {/*                                                 }/>*/}
                            {/*                                    <Panel*/}
                            {/*                                        headerText="Unit Information"*/}
                            {/*                                        isOpen={sidePanelVisible}*/}
                            {/*                                        isLightDismiss={true}*/}
                            {/*                                        onDismiss={() => {*/}
                            {/*                                            this.setState({sidePanelVisible: false})*/}
                            {/*                                        }}*/}
                            {/*                                        closeButtonAriaLabel="close"*/}
                            {/*                                        type={PanelType.medium}*/}
                            {/*                                    >*/}
                            {/*                                        <div className="paragraphTitle">Unit name</div>*/}
                            {/*                                        <p className="informationText">{this.state.sidePanelUnitName}</p>*/}
                            {/*                                        <div className="paragraphTitle">Academic*/}
                            {/*                                            credit*/}
                            {/*                                        </div>*/}
                            {/*                                        <p className="informationText">{this.state.sidePanelCredits}</p>*/}
                            {/*                                        <div className="paragraphTitle">Unit*/}
                            {/*                                            description*/}
                            {/*                                        </div>*/}
                            {/*                                        <p className="informationText withWhitespace">{this.state.sidePanelDescription}</p>*/}
                            {/*                                    </Panel>*/}
                            {/*                                </td>*/}
                            {/*                                /!*<p>{unit.description}</p>*!/*/}
                            {/*                            </tr>*/}
                            {/*                        </>*/}
                            {/*                    ))}*/}
                            {/*                </table>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {/*</Gated>*/}


                            <Gated display={hasApplied === false}>
                                <ButtonSet>
                                    <Button type="reset" kind="secondary"
                                    >Reset page</Button>
                                    <Button type="submit" kind="primary">Save page</Button>
                                </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }


}


export default function (props) {
    //We're using navigation to handle internal links
    const navigate = useNavigate();

    //Load in our form session data via hook and pass it to our class, pita doing it this way but nice to have the
    //class lifecycle methods and inheritance available
//    const {applicationFormId, targetCourse, formData, hasFormLoaded} = useContext(BaseContext);

    return <Project
        navigate={navigate}
        //        applicationFormId={applicationFormId}
        //        targetCourse={targetCourse}
        //        hasFormLoaded={hasFormLoaded}

        {...props} />;
}

