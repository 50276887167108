import React from 'react';
import {Button, ButtonSet, Column, Content, Grid} from "@carbon/react";
import { Form, Formik} from "formik";
import * as Yup from "yup";

import {useNavigate, useParams} from "react-router-dom";

import BaseComponent from "../../util/BaseComponent";
import StandardInput from "../../components/StandardInput";
import {Slide, toast, ToastContainer} from "react-toastify";
import CourseService from "../../util/CourseService";
import RefereeService from "../../util/RefereeService";
import ApplicationService from "../../util/ApplicationService";



/**
 * Initial values for our form
 */
const initialValues = {
    //relationship: "",
    //lengthKnown: "",
    //suitabilityRating: "",
    //workExperienceRating: "",
    //majorTalents: "",
    //furtherComments: "",
    referenceFreeText: "",
    //Contains the supplied token
    token: ""

};



const validationSchema = Yup.object().shape({
    referenceFreeText: Yup.string().test(
        'Max400Words',
        'Please try and keep your reference below 400 words',
        function(item) {
            if (item && item.split(/[\s]+/).length > 400) {
                return false;
            } else {
                return true;
            }
        }
    ).test(
        'stringlength',
        'Your response must be below 4000 characters',
        function(item) {
            if (item !== undefined && (item.length === 0 || item.length > 4000 )) {
                return false;
            } else {
                return true;
            }
        }
    ).required("Please try and keep your reference below 400 words")
});


class Referee extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            isTransmitting: false,
            tokenProvided: false,
            referenceRequest: {},
            hasSubmitted: false
        }
    }


    submitForm(values) {

        const {isTransmitting, hasSubmitted} = this.state;

        if (!hasSubmitted && !isTransmitting) {

            this.setState({
                //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
                isTransmitting: true
            })



            RefereeService.submitReference(values.referenceFreeText, this.props.token)
                .then(
                    (response) => {
                        toast.success(
                            "Success: Thank you for providing a reference"
                            , {
                              //  autoClose: 5000,
                                className: "toastSuccess",
                            });
                    })
                .catch(
                    (error) => {
                        let parsedError = this.parseError(error);
                        toast.error(
                            parsedError.message
                            , {
                                // autoClose: 5000,
                                className: "toastError",
                            });
                    })
                .then(
                    () => {
                        this.setState({
                            isTransmitting: false,
                            hasSubmitted: true
                        })
                    }
                )
        }

    }

    componentDidMount() {
        //Ask the server whether this token is valid and what state the application is in that corresponds to this token
        let token = this.props.token;

        if (token !== undefined && token !== null) {
            //Ask the server if this reference token is valid and retrieve relevant information
            this.setState({tokenProvided: true})
            this.checkReference(token);
        }
    }

    checkReference(token) {

        const {isTransmitting} = this.state;


        if (!isTransmitting) {

            RefereeService.getInvite(token)
                .then(
                    response => {
                        this.setState( {
                            referenceRequest:response.data
                        })
                    }
                )
                .catch(
                    error => {
                        toast.error(
                            "Problem retrieving application details."
                            , {
                                // autoClose: 5000,
                                className: "toastError",
                            });
                    }
                )
                .finally(
                    () => {
                        //Update the state with our current knowledge, we're not in a request and we have a course List
                        this.setState({
                            isTransmitting: false,
                        })
                    }
                )
            this.setState({loaded: true})
        }
    }


    render() {

        const {loaded, tokenProvided, referenceRequest} = this.state;
        const {isTransmitting } = this.state;
        const {hasSubmitted} = this.state;


        if (!loaded) {
            return (
                <>
                    <div className="contentHeader">
                        <div className = "constrainContentWidth">
                            <h1>Provide a reference: Retrieving details</h1>
                            <p>Please fill in your reference below. </p>
                        </div>
                    </div>

                    <ToastContainer
                        position="top-right"
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        theme="dark"
                        limit={4}
                        transition={Slide}
                        autoClose={false}
                    />
                </>
            );
        }

        if (tokenProvided && !referenceRequest.hasCompleted) {

            const learnerName = referenceRequest.learnerName;

            return (
                <>
                    <div className="contentHeader">
                        <div className = "constrainContentWidth">
                            <h1>Provide a reference for: {learnerName}</h1>
                            <p>Please fill in your reference below. </p>
                        </div>
                    </div>


                    <Content>
                        <Grid>
                            <row>
                            <Column className="standardColumnNoBorder">
                                <div className="contentInstruction paddedBottom">
                                    <p className = "dropDownPadding">The above named person is applying for admission into the course at Health Sciences University and it would be of great assistance in considering his/her application if you would kindly complete this reference form. Please note that under the Data Protection Act a student would have the right to obtain a copy of the reference you supplied. Thank you for your assistance.</p>
                                    <p className = "dropDownPadding">Please describe in what capacity you know the applicant that has nominated you to undertake their reference. We would recommend that you provide a character reference that details the qualities and attributes they have that make them suitable for the course of study that they have applied for.</p>
                                </div>
                            </Column>
                            <Column className="standardColumnNoBorder">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {

                                        //avoid a double click
                                        if (!isTransmitting) {
                                            this.submitForm(values);
                                        }
                                    }}
                                >
                                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (

                                        <Form>

                                            <div className = "formPanelNoMaxWidth">
                                                <StandardInput
                                                    type="wordCount"
                                                    title="Your statement"
                                                    description=""
                                                    placeholder=""
                                                    helpText=""
                                                    fieldId={"referenceFreeText"}
                                                    required={false}
                                                    flexRow={false}
                                                    flexGrow={false}
                                                    disabled={hasSubmitted}
                                                    additionalClass=""
                                                    multiline={true}
                                                    rowCount={10}
                                                    width={4}
                                                />
                                            </div>

                                            <ButtonSet>
                                                <Button type="reset" kind="secondary"
                                                >Clear form</Button>
                                                <Button type="submit" kind = "primary">Save reference</Button>
                                            </ButtonSet>
                                        </Form>


                                    )}

                                </Formik>
                            </Column>
                                </row>
                        </Grid>
                    </Content>
                </>
            );
        } else {
            return (<>

                <div className="contentHeader">
                <div className = "constrainContentWidth">
                    <h1>Provide a reference for: </h1>
                    <p>Please fill in your reference below. </p>
                </div>
            </div>


            <Content>
                <Grid>
                    <row>
                        <Column className="standardColumnNoBorder">
                            <div className="contentInstruction paddedBottom">
                                <p className = "dropDownPadding">We weren't able to recognise the token you provided. Please check you are clicking on the link provided and that you haven't previously provided a reference.</p>
                            </div>
                        </Column>
                    </row>
                </Grid>
            </Content>


                    </>


                        );
        }


    }
}


export default function (props) {

    const navigate = useNavigate();
    const {token} = useParams();

    return <Referee token = {token} navigate = {navigate}  {...props} />;
}

