import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import AuthService from "./AuthService";


/**
 * Translates our direct links into courseIds for the application process
 */
const DirectApplicationManager = (props) => {

    /*
    We expect a component to be passed through to us that we'll return providing it meets any display restrictions
     */
    const { Component } = props;

    /*
    We're probably reaching here with a parameter attached

     */
    const {courseId} = useParams();


    let courseInfo = [
        {
            slug: "PgCert_HSE",
            targetCourse: 134,
        },
        {
            slug: "PgCert_HSE_SEP",
            targetCourse: 151,
        },
        {
            slug: "PgCert_FCP",
            targetCourse: 125,
        },
        {
            slug: "CPD7006",
            targetCourse: 901,
        },
        {
            slug: "HHCPD7006",
            targetCourse: 925,
        },
        {
            slug: "MSKUNITS",
            targetCourse: 902,
        },
        {
            slug: "CAFAP",
            targetCourse: 903,
        },
        {
            slug: "MSCACPIDA",
            targetCourse: 135,
        },
        {
            slug: "CPDAHP4010",
            targetCourse: 904,
        },
        {
            slug: "CPD7022",
            targetCourse: 905,
        },
        {
            slug: "CPD7004",
            targetCourse: 906,
        },
        {
            slug: "CPD7013",
            targetCourse: 907,
        },
        {
            slug: "BIUNITS",
            targetCourse: 908,
        },
        {
            slug: "ACP7001",
            targetCourse: 909,
        },
        {
            slug: "CPD7002",
            targetCourse: 910,
        },
        {
            slug: "CPD7005",
            targetCourse: 911,
        },
        {
            slug: "CPD7009",
            targetCourse: 912,
        },
        {
            slug: "CPD7010",
            targetCourse: 913,
        },
        {
            slug: "CPD7011",
            targetCourse: 914,
        },
        {
            slug: "CPD7013",
            targetCourse: 915,
        },
        {
            slug: "CPD7020",
            targetCourse: 916,
        },
        {
            slug: "CPD7029",
            targetCourse: 917,
        },
        {
            slug: "AHP4002",
            targetCourse: 918,
        },
        {
            slug: "ACP7003",
            targetCourse: 919,
        },
        {
            slug: "BIM7002",
            targetCourse: 920,
        },
        {
            slug: "MSSEP",
            targetCourse: 2001,
        },
        {
            slug: "MSSRTL",
            targetCourse: 2002,
        },
        {
            slug: "RESEARCH",
            targetCourse: 2004,
        },
        {
            slug: "CPD7030",
            targetCourse: 921,
        },
        {
            slug: "CPD7012",
            targetCourse: 924,
        },
        {
            slug: "IDP7001",
            targetCourse: 922,
        },
        {
            slug: "IDP7002",
            targetCourse: 923,
        },
        {
            slug: "M_US_SEP",
            targetCourse: 2005,
        },
        {
            slug: "M_US_JAN",
            targetCourse: 2006,
        },
        {
            slug: "MSK_M_US_JAN",
            targetCourse: 2007,
        },
        {
            slug: "PgCert_IDP",
            targetCourse: 220,
        }
    ]



    if (courseId !== null ) {
        //We have a courseId to store

        let slugIndex = courseInfo.findIndex((element) => element.slug === courseId )

        if (slugIndex < 0) {
            //Not found but we may want to use the input for some reason
            sessionStorage.setItem("targetCourse",courseId);
        } else {
            //found and we have
            sessionStorage.setItem("targetCourse", courseInfo[slugIndex].targetCourse);
        }

//
//         if (courseId === "PgCert_HSE") {
//             sessionStorage.setItem("targetCourse", 134);
//         } else if (courseId === "PgCert_FCP") {
//             sessionStorage.setItem("targetCourse", 125);
//         } else if (courseId === "CPD7006") { //Deteriorating Patient
//             sessionStorage.setItem("targetCourse", 901);
// //            sessionStorage.setItem("targetUnit", "CPD7006");
//         } else if (courseId === "MSKUNITS") { //https://applications.aecc.ac.uk/apply/CPD7006
//             sessionStorage.setItem("targetCourse", 902);
//         } else if (courseId === "CAFAP") { //Claires MSc Advanced Clinical Practice Apprentiship
//             sessionStorage.setItem("targetCourse", 903);
//         } else if (courseId === "MSCACPIDA") {
//             sessionStorage.setItem("targetCourse", 135);
//         } else if (courseId === "CPDAHP4010") {
//             sessionStorage.setItem("targetCourse", 904);
//         } else if (courseId === "CPD7022") {
//             sessionStorage.setItem("targetCourse", 905);
// //            sessionStorage.setItem("targetUnit", "CPD7022");
//         } else if (courseId === "CPD7004") {
//             sessionStorage.setItem("targetCourse", 906);
// //            sessionStorage.setItem("targetUnit", "CPD7004");
//         } else if (courseId === 'CPD7013') {
//             sessionStorage.setItem("targetCourse", 907);
//         } else if (courseId === "BIUNITS") {
//             sessionStorage.setItem("targetCourse", 908);
//         } else {
//             sessionStorage.setItem("targetCourse",courseId);
//         }
    }

  //  const navigate = useNavigate()
    return <Navigate to="/" replace={true}/>

}

export default DirectApplicationManager;