import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {initializeIcons} from '@fluentui/react/lib/Icons';

import './styles/index.scss';
import App from './App';
import {RecoilRoot} from "recoil";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {ParamsProvider} from "./util/BaseContext";

initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <Router>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <ParamsProvider value={ {} }>
                        <App/>
                    </ParamsProvider>
                </DevSupport>
            </Router>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);


