import React from 'react';

import BaseComponent, {
 qualificationTypeArray
} from "../../../../util/BaseComponent";
import {useNavigate} from "react-router-dom";
import {FieldArray, Form, Formik} from "formik";
import StandardInput from "../../../../components/StandardInput";
import Gated from "../../../../components/Gated";
import {Button, ButtonSet} from "@carbon/react";
import ApplicationService from "../../../../util/ApplicationService";
import BaseContext from "../../../../util/BaseContext";
import {toast} from "react-toastify";
import * as Yup from "yup";


//const validationSchema = {};

const defaultValues = {

    qualifications: [
        {
            type: "",
            obtained: false,
            school: "",
            dateObtained: "",
            classification: "",
            titleQualification: "",
            qualificationName: "",
            subject: ""
        }
    ],
}




const validationSchema = Yup.object().shape({

    qualifications: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().nullable().required("Please select the type of this qualification"),
            titleQualification: Yup.string().nullable().required("Please enter the title of this qualification"),
            subject: Yup.string().nullable().required("What subject is this qualification in"),
            school: Yup.string().nullable().required("What's the name of the awarding institution?"),

            qualificationName: Yup.string().nullable().when(['type'], {
                is: (value) => {
                    if (value === 'other') {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().required("Please describe this qualification")
            }),

            dateObtained: Yup.string().nullable().when(['obtained'], {
                is: (value) => {
                    if (value === true) {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().required("Please indicate when you attained this qualification")
            }),

            classification: Yup.string().nullable().when(['obtained'], {
                is: (value) => {
                    if (value === true) {
                        return true;
                    } else {
                        return false;
                    }
                },
                then: Yup.string().nullable().required("Please indicate the grade you obtained")
            })


        })
    )
})











class Education extends BaseComponent {

    static contextType = BaseContext;
    constructor(props) {
        super(props);


        this.state = {

            initialValues: defaultValues,
            navigateHome: false,

            isTransmitting: false,

            displayNotification: false,
            notificationTitle: "",
            notificationSubtitle: "",
            notificationKind: "warn",
        }
    }


    componentDidMount() {
        //Check if we have an applicationId set
        if (this.context.applicationFormId == undefined || this.context.applicationFormId == null) {
            //we've lost our context somehow drop back to opening screen
            this.setState({navigateHome: true})
        } else {
            //Parse our form state for relevant information
            this.processFormData()
        }
    }


    processFormData(formId) {

                   let data = this.context.formData;
                    let initialValues = {
                        qualifications: data.qualifications,
                    }

                    if (data.qualifications.length < 1) {
  /*                      initialValues.qualifications = [{



                            type: "",
                            obtained: false,
                            school: "",
                            dateObtained: "",
                            classification: "",
                            titleQualification: "",
                            subject: ""




                        }] */
                        initialValues = defaultValues;
                    }

                    this.setState({
                        initialValues: initialValues
                    })
    }

    submitForm(values) {
        this.setState({
            //Indicate we're sending data so multiple submit attempts are blocked whilst one is on progress
            isTransmitting: true
        })

        ApplicationService.submitEducation(values,this.context.applicationFormId)
            .then(
                (response) => {

                    this.getApplicationFormData(this.context.applicationFormId)

                    toast.success(
                        "Success: Details Stored"
                        , {
                            autoClose: 5000,
                            className: "toastSuccess",
                        });
                })
            .catch(
                (error) => {
                    let parsedError = this.parseError(error);
                    toast.error(
                        parsedError.message
                        , {
                            // autoClose: 5000,
                            className: "toastError",
                        });
                })
            .then(
                () => {
                    this.setState({
                        isTransmitting: false
                    })
                }
            )
        }



    render() {
        const {daysArray, yearsArray, yearsArrayCurrent, isTransmitting, initialValues, navigateHome} = this.state;
        const {displayNotification, notificationTitle, notificationSubtitle, notificationKind} = this.state;

        const {hasApplied} = this.context;

        if (navigateHome) {
            this.props.navigate("/apply/list");
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        //avoid a double click
                        if (!isTransmitting) {
                            this.submitForm(values);
                        }
                    }}
                >
                    {({errors, touched, values, handleChange, submitForm, setFieldValue}) => (
                        <Form>
                            <h3 className="questionGroupTitle">Education / Qualifications</h3>

                            {values.qualifications.length < 1 ? (
                                <p className="informationText dropDownPadding">You currently have no education entries
                                    recorded on this application form. You can add entries below</p>
                            ) : (
                                <p className="informationText dropDownPadding">Details of your education and
                                    qualifications are listed below, to add additional items or remove existing ones
                                    using the options provided.
                                </p>
                            )}
                            <FieldArray name={"qualifications"}>
                                {({push, remove}) => (
                                    <div>
                                        {
                                            values.qualifications.length > 0 &&
                                            values.qualifications.map((qualification, index) => (
                                                <>
                                                <div className="formPanel">

                                                    <StandardInput
                                                        type="dropDown"
                                                        title="Type of qualification"
                                                        description=""
                                                        placeholder="select type"
                                                        helpText=""
                                                        fieldId={`qualifications[${index}].type`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={3}
                                                        options={qualificationTypeArray}
                                                    />

                                                    <Gated display={values.qualifications[index].type === "other"}>
                                                        <StandardInput
                                                            type="text"
                                                            title="Qualification description"
                                                            description="Please describe the nature of the qualification"
                                                            placeholder="qualification type"
                                                            helpText=""
                                                            fieldId={`qualifications[${index}].qualificationName`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={0}
                                                        />

                                                    </Gated>

                                                    <StandardInput
                                                        type="text"
                                                        title="Title of qualification"
                                                        description="e.g. BA, MSc etc"
                                                        placeholder="select type"
                                                        helpText=""
                                                        fieldId={`qualifications[${index}].titleQualification`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={2}
                                                    />

                                                    <StandardInput
                                                        type="text"
                                                        title="Subject of qualification"
                                                        description="e.g. Chiropractic"
                                                        placeholder="select type"
                                                        helpText=""
                                                        fieldId={`qualifications[${index}].subject`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />

                                                    <StandardInput
                                                        type="text"
                                                        title="Awarding institution"
                                                        description=""
                                                        placeholder="select type"
                                                        helpText=""
                                                        fieldId={`qualifications[${index}].school`}
                                                        requiredVisual={true}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />

                                                    <StandardInput
                                                        type="toggle"
                                                        title="Is this a pending qualification"
                                                        description=""
                                                        placeholder=""
                                                        helpText=""
                                                        fieldId={`qualifications[${index}].obtained`}
                                                        onText="No"
                                                        offText="Yes"
                                                        requiredVisual={false}
                                                        flexRow={false}
                                                        flexGrow={false}
                                                        disabled={hasApplied}
                                                        additionalClass=""
                                                        multiline={false}
                                                        rowCount={1}
                                                        width={0}
                                                    />


                                                    <Gated display={values.qualifications[index].obtained === true}>

                                                        <StandardInput
                                                            type="date"
                                                            title="Date obtained"
                                                            description=""
                                                            placeholder="dd/mm/yyyy"
                                                            helpText=""
                                                            fieldId={`qualifications[${index}].dateObtained`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={2}
                                                        />


                                                        <StandardInput
                                                            type="text"
                                                            title="Result"
                                                            description="e.g. Distinction, 2:1"
                                                            placeholder=""
                                                            helpText=""
                                                            fieldId={`qualifications[${index}].classification`}
                                                            requiredVisual={true}
                                                            flexRow={false}
                                                            flexGrow={false}
                                                            disabled={hasApplied}
                                                            additionalClass=""
                                                            multiline={false}
                                                            rowCount={1}
                                                            width={2}
                                                        />
                                                    </Gated>

                                                    {!hasApplied && (
                                                        <ButtonSet className = "addRemoveButtons">
                                                            <button type="button" className="addButton"
                                                                    onClick={() => push({
                                                                        type: "",
                                                                        obtained: false,
                                                                        school: "",
                                                                        dateObtained: "",
                                                                        classification: "",
                                                                        titleQualification: "",
                                                                        qualificationName: "",
                                                                        subject: ""
                                                                    })}>&#10010;
                                                            </button>
                                                            {index > 0 && (
                                                                <button type="button" className="removeButton"
                                                                        onClick={() => remove(index)}>&#10006;
                                                                </button>
                                                            )}

                                                        </ButtonSet>
                                                    )}
                                                </div>


                                              </>
                                            ))}
                                    </div>
                                )}

                            </FieldArray>



                            <Gated display={hasApplied === false}>
                            <ButtonSet>
                                <Button type="reset" kind="secondary"
                                >Reset page</Button>
                                <Button type="submit" kind="primary">Save page</Button>
                            </ButtonSet>
                            </Gated>
                        </Form>
                    )}
                </Formik>


            </>
        );
    }
}


export default function (props) {
    const navigate = useNavigate();
    return <Education navigate={navigate} {...props} />;
}