import React from 'react';

//We're using react-router-dom to handle navigation
import {Route, Routes} from 'react-router-dom';

//Global Top Bar
import DisplayHeader from "./components/DisplayHeader";

//Handle import of target pages
import Logout from "./content/Logout";
import Matrix from "./content/Matrix";
import Welcome from './content/Welcome';

//Sets up some default overrides for the fluent theme
import {ThemeProvider} from "@fluentui/react";
import {elementTheme} from "./util/theme.js";

//Imports our carbon component styles
import './styles/app.scss';

import Unknown from "./content/Unknown";
import Application from "./content/Application";
import SessionManager from "./util/SessionManager";
import CourseList from "./content/Application/CourseList";
import Course from "./content/Application/Course";
import DirectApplicationManager from "./util/DirectApplicationManager";
import Register from "./content/Register";
import PersonalInfo1 from "./content/Application/form/sub/PersonalInfo1";
import PersonalInfo2 from "./content/Application/form/sub/PersonalInfo2";
import LanguageQualifications from "./content/Application/form/sub/LanguageQualifications";
import Education from "./content/Application/form/sub/Education";
import ProfessionalBodies from "./content/Application/form/sub/ProfessionalBodies";
import EmploymentHistory from "./content/Application/form/sub/EmploymentHistory";
import PersonalStatement from "./content/Application/form/sub/PersonalStatement";
import Reference from "./content/Application/form/sub/Reference";
import ClinicalPlacement from "./content/Application/form/sub/ClinicalPlacement";
import SupportingDocuments from "./content/Application/form/sub/SupportingDocuments";
import Overview from "./content/Application/form/sub/Overview";
import MainTemplate from "./content/Application/form/MainTemplate";
import Start from "./content/Application/Start";
import SubmitApplication from "./content/Application/form/sub/SubmitApplication";
import Referee from "./content/Referee";
import Funding from "./content/Application/form/sub/Funding";
import {Slide, ToastContainer} from "react-toastify";
import Project from "./content/Application/form/sub/Project";

/**
 * Main Layout Definitions via react-router
 */
export default function App() {
    return (
        <div className="App">
            <DisplayHeader/>

            <ThemeProvider theme={elementTheme}>

                <Routes>
                    {/* Default Home Page displayed to unauthenticated user */}
                    <Route path="/" element={<Welcome/>}/>

                    {/* Test page for selecting courses */}
                    <Route path="/matrix" element={<Matrix/>}/>

                    {/* Application Process */}
                    <Route path="/apply"  element={<Application />}>
                        {/* Handles the incoming Links for specific courses from the website*/}
                        <Route path = ":courseId"
                               element = {
                                   <DirectApplicationManager Component={Course}  />
                               } />

                        {/* Application form navigation and the various modules we have available */}
                        <Route path = "/apply/form" element = {<MainTemplate/>} >
                            <Route path = "/apply/form/overview" element={<Overview/>} />
                            <Route path = "/apply/form/personalInfo1" element={<PersonalInfo1/>} />
                            <Route path = "/apply/form/personalInfo2" element={<PersonalInfo2/>} />
                            <Route path = "/apply/form/languageQualifications" element={<LanguageQualifications/>} />
                            <Route path = "/apply/form/education" element={<Education/>} />
                            <Route path = "/apply/form/professionalBodies" element={<ProfessionalBodies/>} />
                            <Route path = "/apply/form/employmentHistory" element={<EmploymentHistory/>} />
                            <Route path = "/apply/form/personalStatement" element={<PersonalStatement/>} />
                            <Route path = "/apply/form/reference" element={<Reference/>} />
                            <Route path = "/apply/form/clinicalPlacement" element={<ClinicalPlacement/>} />
                            <Route path = "/apply/form/supportingDocuments" element={<SupportingDocuments/>} />
                            <Route path = "/apply/form/submitApplication" element={<SubmitApplication/>} />
                            <Route path = "/apply/form/funding" element={<Funding/>} />
                            <Route path = "/apply/form/project" element={<Project/>} />


                        </Route>

                        {/* Main login page, lists the course history for the applicant*/}
                        <Route path = "/apply/list"
                               element = {
                                   <SessionManager Component={CourseList}  requiredRole="ROLE_APPLICANT" />
                               } />

                        <Route index element={<Start />}/>
                    </Route>

                    <Route path="/reference" element={ <Referee /> }>
                        <Route path = ":token"
                               element = {
                                   <Referee Component={Reference}  />
                               } />
                    </Route>

                    {/* Test page for selecting courses */}
                    <Route path="/register" element={<Register/>}/>

                    {/* Used to manually log out the user */}
                    <Route path="/logout" element={<Logout/>}/>

                    {/* 404 not found route */}
                    <Route path="*" element = {<Unknown/>}/>

                </Routes>

                <ToastContainer
                    containerId="defaultContainer"
                    position="top-right"
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={true}
                    theme="dark"
                    limit={4}
                    transition={Slide}
                    autoClose={false}

                />

            </ThemeProvider>

        </div>
    );
}


