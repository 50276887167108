import React, {useContext} from 'react';
import {Nav} from "@fluentui/react";
import BaseComponent from "../../../util/BaseComponent"
import { Outlet, useNavigate} from "react-router-dom";
import BaseContext from "../../../util/BaseContext";
import {Slide, ToastContainer} from "react-toastify";
import {CheckmarkFilled, CircleStroke} from "@carbon/icons-react";

class MainTemplate extends BaseComponent {

    static contextType = BaseContext;

    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        }
    }

    /**
     * This will be the first item loaded when an application is selected or created, pull in the form data and set
     * our state variables
     */
    componentDidMount() {
        //Pull in the applicationForm information here and set up our course layout accordingly
        this.getApplicationFormData(this.context.applicationFormId);




    }

    render() {
        const {formData, hasApplied, hasFormLoaded} = this.context;

        let allowSubmit = false;
        let hasSubmitted = "Incomplete";

        if (hasApplied) {
            hasSubmitted = "Complete"
        }

        /**
         * The question sets we want to ask vary by course, the server is supplying the relevant section panels
         * but we do need to turn that information into the gui stuff
         *
         */
            //TODO this is a legacy structure that's still being used as a wrapper around the new, we can refactor and
            //remove

        let navLinks = [{
            name: '',
            links: [],
        }];

        if (hasFormLoaded) {

            allowSubmit = formData.allowSubmit;


            for (let i = 0; i < formData.courseDetails.includedFormSections.length; i++) {
                let newNavLink = {};

                switch (formData.courseDetails.includedFormSections[i]) {
                    case 1:
                        newNavLink = {
                            name: 'Personal information 1', url: "", onClick: () => {
                                this.props.navigate("./personalInfo1")
                            }, key: 'key1', categoryStatus: formData.personalCategoryStatus
                        };
                        break;
                    case 2:
                        newNavLink = {
                            name: 'Personal information 2', url: "", onClick: () => {
                                this.props.navigate("./personalInfo2")
                            }, key: 'key2', categoryStatus: formData.personalCategoryStatus2
                        };
                        break;
                    case 3:
                        newNavLink = {
                            name: 'Course overview', url: "", onClick: () => {
                                this.props.navigate("./overview")
                            }, key: 'key0', categoryStatus: formData.overviewCategoryStatus
                        };
                        break;
                    case 4:
                        newNavLink = {
                            name: 'Funding', url: "", onClick: () => {
                                this.props.navigate("./funding")
                            }, key: 'key12', categoryStatus: formData.fundingCategoryStatus
                        };
                        break;
                    case 5:
                        newNavLink = {
                            name: 'Professional bodies', url: "", onClick: () => {
                                this.props.navigate("./professionalBodies")
                            }, key: 'key5', categoryStatus: formData.professionalCategoryStatus
                        };
                        break;
                    case 6:
                        newNavLink = {
                            name: 'Employment history', url: "", onClick: () => {
                                this.props.navigate("./employmentHistory")
                            }, key: 'key6', categoryStatus: formData.employmentCategoryStatus
                        };
                        break;
                    case 7:
                        newNavLink = {
                            name: 'Personal statement', url: "", onClick: () => {
                                this.props.navigate("./personalStatement")
                            }, key: 'key7', categoryStatus: formData.personalStatementStatus
                        };
                        break;
                    case 8:
                        newNavLink = {
                            name: 'Supporting documents', url: "", onClick: () => {
                                this.props.navigate("./supportingDocuments")
                            }, key: 'key9', categoryStatus: "unnecessary"
                        };
                        break;
                    case 9:
                        newNavLink = {
                            name: 'Submit application', url: "", disabled: !allowSubmit, onClick: () => {
                                this.props.navigate("./submitApplication")
                            }, key: 'key11', categoryStatus: hasSubmitted
                        };
                        break;
                    case 10:
                        newNavLink = {
                            name: 'Language qualifications', url: "", onClick: () => {
                                this.props.navigate("./languageQualifications")
                            }, key: 'key3', categoryStatus: formData.languageCategoryStatus
                        };
                        break;
                    case 11:
                        newNavLink = {
                            name: 'Education', url: "", onClick: () => {
                                this.props.navigate("./education")
                            }, key: 'key4', categoryStatus: formData.educationCategoryStatus
                        };
                        break;
                    case 12:
                        newNavLink = {
                            name: 'Reference', url: "", onClick: () => {
                                this.props.navigate("./reference")
                            }, key: 'key8', categoryStatus: formData.referenceCategoryStatus
                        };
                        break;
                    case 13:
                        newNavLink = {
                            name: 'Research Opportunities', url: "", onClick: () => {
                                this.props.navigate("./project")
                            }, key: 'key13', categoryStatus: formData.projectCategoryStatus
                        };
                        break;



                    default:
                        console.log("Found unknown form section request");
                        break;
                }
                navLinks[0].links.push(newNavLink);
            }
        }



        return (
            <>
                {!this.props.hasFormLoaded && (

                    <div className = "overlay">

                    </div>

                )}

                <div className = "constrainContentWidth">


                <div className = "formContainer ">
                    <div className = "panelLeft" >

                        <Nav
                            className = "navigationColumn"
                            onRenderGroupHeader={onRenderGroupHeader}
                            onRenderLink={onRenderLink}
                            ariaLabel="Naviagtion Links"
                            groups={navLinks}
                        />

                        {/** }

                        <ProgressIndicator vertical = {true} onChange = {()=>{alert("changing")}}>
                            <ProgressStep
                                current
                                label = "step 1"
                                description = "progress step"
                                secondaryLabel="something"
                                onCLick = {()=> {alert("clicked") }}
                            />
                            <ProgressStep

                                label = "step 2"
                                description = "progress step"
                                onCLick = {()=> {this.props.navigate("./personalInfo2") }}
                            />
                        </ProgressIndicator>

                        { **/ }

                    </div>

                    <div className = "panelRight">
                        <div className = "restrictWidth">
                            <>
                            <Outlet/>
                            </>
                        </div>
                    </div>

                    <ToastContainer
                        position="top-right"
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        theme="dark"
                        limit={4}
                        transition={Slide}
                        autoClose={false}
                    />

                </div>
                </div>

            </>
        );
    }
}


function onRenderGroupHeader(group) {
    return <h3>{group.name}</h3>;
}

function onRenderLink(link) {
    return (
     <>
         <div className = "linkContainer">
             <span>{link.name}</span>

             { link.categoryStatus === "Complete" ? (
             <span className="linkStatus green" >
                 <CheckmarkFilled size = {16} />
             </span>
             ) : (
                 <>
                 { link.categoryStatus === "unnecessary" ? (
                     <></>
                         ) : (
                             <span className="linkStatus " >
                                <CircleStroke size = {16} />
                             </span>
                     )}
             </>
             )}
         </div>


     </>
    );
}



export default function (props) {
    //We're using navigation to handle internal links
    const navigate = useNavigate();

    //Load in our form session data via hook and pass it to our class, pita doing it this way but nice to have the
    //class lifecycle methods and inheritance available
    const {applicationFormId, setApplicationFormId, targetCourse, setTargetCourse, formData, setFormData, hasFormLoaded, setHasFormLoaded} = useContext(BaseContext);

    return <MainTemplate
        navigate={navigate}
        applicationFormId={applicationFormId}
        setApplicationFormId={setApplicationFormId}
        targetCourse={targetCourse}
        setTargetCourse={setTargetCourse}
        formData={formData}
        hasFormLoaded={hasFormLoaded}
        setHasFormLoaded={setHasFormLoaded}
        setFormData={setFormData}
        {...props} />;
}