import React from "react";
import * as PropTypes from "prop-types";

export class ToastMessage extends React.Component {





    render() {

        let {title, message} = this.props;

        return (
            <>
                <div className = "toastTitle">{title}</div>
                <div className = "toastMessage">{message}</div>
            </>
        );
    }
}

ToastMessage.propTypes = {
    title: PropTypes.any,
    message: PropTypes.any
};